import Repository from "../Repository";

const resource = "/users";
export default {
    getAll() {
        return Repository.get(`${resource}`);
    },
    get(user_id) {
        return Repository.get(`${resource}/${user_id}`);
    },
    getNames() {
        return Repository.get(`${resource}/names`)
    },
    createUser(json) {
        return Repository.post(`${resource}`, json);
    },
    updateUser(id, json) {
        return Repository.put(`${resource}/${id}`, json);
    },
    deleteUser(id) {
        return Repository.delete(`${resource}/${id}`);
    },
    getCurrentUser() {
        return Repository.get(`${resource}/current`);
    },

    //AUTHORIZATION ROUTES
    login(json, param) {
        return Repository.post(`/login`, json, param);
    },
    logout() {
        return Repository.post(`/logout`);
    },
    register(json) {
        return Repository.post(`/register`, json);
    },
    forgotPassword(json) {
        return Repository.post(`/password/forgot`, json);
    },
    resetPassword(json) {
        return Repository.post(`/password/reset`, json);
    },
    resendVerifyEmail(json) {
        return Repository.post(`/email/resend`, json);
    },
    manuallyVerifyEmail(json) {
        return Repository.post(`/email/verify/manually`, json);
    },
    verifyEmail(json) {
        return Repository.post(`/email/verify`, json);
    },
    checkSSOId() { //check if sso_id is already in database
        return Repository.get(`/admin`);
    },
}