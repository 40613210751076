<template>
    <div>
        <!-- TOP BAR -->
        <v-app-bar flat clipped-left app color="white" :class="scrolled ? 'scroll' : ''" class="position:relative">
            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="showNav = !showNav"></v-app-bar-nav-icon>
            <v-img
                class="mx-4"
                :src="require('../assets/' + ($vuetify.breakpoint.mdAndUp ? 'adalliance_logo_row.svg' : 'logo.png'))"
                max-height="80"
                :max-width="$vuetify.breakpoint.mdAndUp ? 150 : 50"
                contain
                @click="homepage"
                style="cursor: pointer"
            ></v-img>
            <v-app-bar-title :style="{cursor: 'pointer'}" @click="homepage">
            </v-app-bar-title>
            <v-spacer></v-spacer>
            <div class="title" @click="homepage" style="position:relative;"
                 :style="{left: $vuetify.breakpoint.mdAndUp ? '-75px' : '0'}">Vendor Tool
            </div>
            <div v-if="localhost" class="d-flex" style="position:absolute; right:30vw">
                <v-switch
                    class="ml-10"
                    v-model="toggle"
                    :false-value="0"
                    :true-value="1"
                    color="red darken-3"
                    hide-details
                    label="readOnly"
                    @change="updateReadOnly"
                ></v-switch>
            </div>
            <v-spacer></v-spacer>
            <div v-if="($store.state.users.current !== null || ssoUser) && $vuetify.breakpoint.mdAndUp"
                 class="d-flex flex-row align-center"
                 style="height:100%">
                <div class="d-flex flex-row" style="height:100%">
                    <div class="d-flex navLink" :class="{active: $route.name === (isExternUser ? 'index' :'admin')}">
                        <v-icon :class="{active: $route.name === (isExternUser ? 'index' :'admin')}"
                                class="pl-2 navIcon" @click="goToRoute((isExternUser ? 'index' :'admin'))">
                            mdi-view-dashboard
                        </v-icon>
                        <router-link :to="{name: (isExternUser ? 'index' :'admin')}">Overview</router-link>
                    </div>
                    <div v-if="$store.state.users.current && $store.state.users.current.role === 'admin'"
                         class="navLink d-flex"
                         :class="{active: $route.name === 'admin_settings'}">
                        <v-icon :class="{active: $route.name === 'admin_settings'}" class="pl-2 navIcon"
                                @click="goToRoute('admin_settings')"
                        >mdi-cog
                        </v-icon>
                        <router-link :to="{name: 'admin_settings'}">Settings</router-link>
                    </div>
                    <!--<div v-if="!isExternUser" class="navLink" :class="{active: $route.name === 'docs'}">
                        <router-link :to="{name: 'docs'}">DOCS</router-link>
                    </div>-->
                </div>
                <TooltipIcon position="bottom" tooltip="Ausloggen" @click="logout">mdi-logout</TooltipIcon>
            </div>
        </v-app-bar>

        <!-- SIDE BAR -->
        <v-navigation-drawer app clipped v-model="showNav" v-if="$vuetify.breakpoint.smAndDown">
            <v-list nav>
                <v-list-item v-for="item in items" :key="item.title" :to="item.route"
                             :class="{navDrawerActive: $route.path === item.route}">
                    <v-list-item-icon>
                        <v-icon color="blue-grey">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="blue-grey--text text--darken-1">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <div class="pa-2">
                    <Button :color="ada.darkGrey" icon="mdi-logout" block @click="logout">
                        Logout
                    </Button>
                </div>
            </template>
        </v-navigation-drawer>

        <v-snackbar bottom right color="success"
                    v-model="logoutSnackbar"
        >
            Sie haben sich erfolgreich ausgeloggt.
            <template v-slot:action="{ attrs }">
                <v-btn icon
                       v-bind="attrs"
                       @click="logoutSnackbar = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import TooltipIcon from "./buttons/TooltipIcon";
import Button from "./buttons/Button";
import ada from '@/scss/variables.scss'

export default {
    name: "Navbar",
    components: {Button, TooltipIcon},
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    data() {
        return {
            ada,
            scrolled: false,
            showNav: false,
            logoutSnackbar: false,
            manualCollapseNave: false,
            toggle: false,
            get ssoUser() {
                return localStorage.getItem('ssoUser') || 0;
            },
        }
    },
    computed: {
        readOnly() {
            return this.$store.state.readOnly;
        },
        loading() {
            return this.$store.state.loading;
        },
        localhost() {
            return window.location.origin.indexOf('localhost') !== -1;
        },
        isExternUser() {
            return this.$store.state.users.current ? !(this.$store.state.users.current.role === 'admin' || this.$store.state.users.current.role === 'user') : !this.ssoUser;
        },
        items() {
            let ret = [
                {title: 'Home', icon: 'mdi-home', route: '/'},
            ];
            if (this.$store.state.users.current !== null || this.ssoUser) {
                ret.push({
                    title: 'Overview',
                    icon: 'mdi-view-dashboard',
                    route: '/' + (this.isExternUser ? 'index' : 'admin')
                })
                if (this.$store.state.users.current && this.$store.state.users.current.role === 'admin') {
                    ret.push({title: 'Settings', icon: 'mdi-cog', route: '/admin/settings'})
                }
            }
            return ret;
        },
        breakpoint() {
            return this.$vuetify.breakpoint.name;
        }
    },
    methods: {
        homepage() {
            this.$router.push('/')
        },
        logout() {
            if (localStorage.getItem('ssoUser')) {
                this.$store.dispatch('logout', true);
                // eslint-disable-next-line no-undef
                sso.logout();
                localStorage.removeItem('ssoUser');
                this.$router.push({name: 'logout'});
            } else {
                this.$store.dispatch('logout');
            }
        },
        updateReadOnly(value) {
            this.$store.commit('setReadOnly', value);
        },
        handleScroll() {
            this.scrolled = window.scrollY > 50;
        },
        goToRoute(name) {
            if (this.$route.name !== name) {
                this.$router.push({name: name});
            }
        }
    },
    watch: {
        readOnly() {
            this.toggle = this.readOnly;
        },
        breakpoint() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.showNav = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

/*::v-deep .v-toolbar__content {
    border-bottom: 2px solid transparent;
}*/

::v-deep .v-toolbar__content {
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep .scroll .v-toolbar__content {
    box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.3);
    //border-bottom: 2px solid $ada_purple;
}

.logo {
    width: 55px;
    height: 70%;
    object-fit: contain;
}

.navLink {
    position: relative;
    height: 100%;
    border-bottom: 3px solid transparent;
    border-top: 3px solid transparent;
    margin-right: 10px;
    user-select: none;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 20px 0 10px;
        color: $ada_darkGrey;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 15px;

        &:hover {
            color: $ada_pink
        }
    }

    &:hover .navIcon, &:hover a {
        color: $ada_pink;
        cursor: pointer;
    }

    .navIcon:after {
        display: none;
    }

    &.active {
        border-bottom: 3px solid $ada_pink;

        a {
            color: $ada_pink;
        }
    }
}

.active {
    color: $ada_pink;
}

::v-deep .theme--light.v-list-item--active:hover::before, ::v-deep .theme--light.v-list-item--active::before {
    opacity: 0;
}

::v-deep .theme--light:hover.navDrawerActive::before, ::v-deep .theme--light.navDrawerActive::before {
    opacity: 0.05;
}


.title {
    font-family: 'Montserrat', 'Roboto', Arial, sans-serif !important;
    font-size: 1.3em !important;
    font-weight: 600;
}

.title, .molten_logo {
    cursor: pointer;
}

.molten_logo {
    height: 35px;
    width: 35px;
}

</style>