<template>
    <div class="container">
        <div class="sectionHeadline text-h6 mb-4">Joint Controller Agreement (JCA)</div>
        <v-btn v-if="lang==='en'" :color="ada.darkGrey" text @click="toggleLanguage" class="languageButton ma-6">
            <v-img src="@/assets/germany.png" width="25" class="mr-2"></v-img>
            German
        </v-btn>
        <v-btn v-else :color="ada.darkGrey" text @click="toggleLanguage" class="languageButton ma-6">
            <v-img src="@/assets/united-kingdom.png" width="25" class="mr-2"></v-img>
            English
        </v-btn>
        <div v-if="lang==='en'">
            As you are certainly aware, in its decision on “Fashion ID” (judgment of 29.07.2019, ref.
            C-40/17), the European Court of Justice has taken a rather
            broad interpretation on the existence of joint responsibility
            within the meaning of the GDPR. <br><br>
            The principles established by the European Court of Justice can be applied to other situations in the
            internet, particularly in the area of digital advertising and in cases of data collection by third parties
            (vendors) on offers from publishers. <br><br>
            <a href="https://www.ad-alliance.de/cms/portfolio/digital/portfolio.html#/?id=4096" target="_blank">
                <Button>See publishers</Button>
            </a>
            <br><br>
            This is because even in a market organized by division of labor, such as the advertising industry, it
            is necessary in this context to reach the agreements required under the GDPR in accordance with the
            different roles of the parties involved. Since the circumstances are always comparable, we as Ad Alliance
            offer our partners the possibility to conclude joint control agreements within the meaning of
            Article 26 GDPR digitally via our vendor tool both for themselves and on behalf of third parties. Please
            follow the steps below. <br><br>
            <span v-if="isAgency">I agree to enter into this JCA also on behalf for other vendors as indicated under
                "vendor relations". I may add and remove vendors under "vendor relations"<br><br></span>

            If you have any questions, please contact us at consent@ad-alliance.de. <br>
            Click <span class="jcaLink" @click="download">here</span> to download and read the JCA or use the button
            below.
            <div class="mt-4">
                <Button :color="ada.blue" @click="download" icon="mdi-download">Download JCA</Button>
            </div>
        </div>
        <div v-else>
            Wie Ihnen bestimmt bekannt ist, hat der EuGH in seiner Entscheidung zu „Fashion ID“ EuGH, Urt. v.
            29.07.2019, Az. C-40/17 eine recht weite Auslegung zum
            Bestehen einer gemeinsamen Verantwortung im Sinne der DSGVO angenommen. <br><br>
            Die vom EuGH festgestellten Grundsätze lassen sich auf andere Internetsachverhalte übertragen, insbesondere
            im Bereich der digitalen Werbung und in Fällen von Erhebung von Daten durch Dritte (Vendoren) auf Angeboten
            von Publishern. <br><br>
            <a href="https://www.ad-alliance.de/cms/portfolio/digital/portfolio.html#/?id=4096" target="_blank">
                <Button>Publisher anzeigen</Button>
            </a>
            <br><br>
            Auch in einem arbeitsteilig organsierten Markt wie der Werbewirtschaft ist es dabei
            erforderlich, entsprechend der verschiedenen Rollen von Beteiligten die nach der DSGVO notwendigen
            Vereinbarungen zu schließen. Da die Sachverhalte stets vergleichbar sind, bieten wir als Ad Alliance unseren
            Partnern die Möglichkeit, Joint-Controller Arrangements i.S.v. Art. 26 DSGVO
            digital über unser Vendorentool für sich selbst sowie in Vertretung für Dritte abzuschließen.
            Bitte folgen Sie hierfür den Nachfolgenden Schritten. <br><br>
            <span v-if="isAgency">Ich erkläre mich damit einverstanden, diese JCA auch im Namen von Drittanbietern
                abzuschließen, wie unter "Vendor Relations" angegeben. Ich kann Anbieter unter "Vendor Relations"
                hinzufügen und entfernen.<br><br></span>
            Bei Rückfragen können Sie sich an consent@ad-alliance.de wenden. <br>
            Klicke <span class="jcaLink" @click="download">hier</span> um das JCA herunterzuladen und durchzulesen oder
            nutze den unten stehenden Button.
            <div class="mt-4">
                <Button :color="ada.blue" @click="download" icon="mdi-download">JCA herunterladen</Button>
            </div>
        </div>
        <v-form v-model="valid" ref="form">
            <v-checkbox v-model="data.jca" :true-value="1" :false-value="0" :color="ada.pink" @change="handleChange"
                        :disabled="$store.state.readOnly === 1" :hide-details="true" :rules="rules.jca"
            >
                <template #label>
                    <div class="d-flex flex-row align-center text-body-2" style="cursor: default" @click.stop.prevent>
                        <div v-if="lang === 'en'">
                            I have read the <span class="jcaLink" @click="download">JCA</span> and accept the terms
                            and conditions*
                        </div>
                        <div v-if="lang === 'de'">
                            Ich habe das <span class="jcaLink" @click="download">JCA</span>
                            gelesen und stimme den dort genannten Bedingungen zu*
                        </div>
                        <div class="ml-2">
                            <TooltipIcon position="right" :color="ada.grey"
                                         :tooltip="cancel_jca_tooltip[lang]">mdi-information
                            </TooltipIcon>
                        </div>
                    </div>
                </template>
            </v-checkbox>
            <v-checkbox v-model="data.other_jca" :true-value="1" :false-value="0" :color="ada.pink"
                        @change="handleChange" class="mt-1" :hide-details="isAgency"
                        :rules="rules.jca"
                        :disabled="$store.state.readOnly === 1"
                        :hint="authorized_hint[lang]"
                        :persistent-hint="role !== 'vendor' && $store.state.agency_vendor.selectedRole !== 'vendor'"
            >
                <template #label>
                    <div class=" text-body-2">
                        {{
                            lang === 'en' ? 'I already accepted a different JCA' : 'Ich bereits einem anderen JCA zugestimmt'
                        }}
                    </div>
                </template>
            </v-checkbox>
            <v-checkbox v-if="isAgency" v-model="data.processor_only" :true-value="1" :false-value="0"
                        :color="ada.pink"
                        :hint="lang === 'en' ? 'Please indicate all relevant vendors under “Vendor Relations“' : 'Bitte alle relevanten Vendoren unter “Vendor Relations“ angeben'"
                        :persistent-hint="data.processor_only === 1"
                        @change="handleChange" class="mt-1" :rules="rules.jca"
                        :disabled="$store.state.readOnly === 1"
            >
                <template #label>
                    <div class=" text-body-2">
                        {{
                            lang === 'en' ? 'I see myself as a processor' : 'Ich sehe mich als Prozessor'
                        }}
                    </div>
                </template>
            </v-checkbox>
        </v-form>

        <v-checkbox v-if="jca_through" v-model="has_jca_through" :color="ada.pink"
                    :label="'Has JCA through '+jca_through" :disabled="true"
        ></v-checkbox>

        <div class="disclaimer mt-4" v-html="disclaimer[lang]">
        </div>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
//import axios from 'axios'
import TooltipIcon from "../../../components/buttons/TooltipIcon";
import Button from "../../../components/buttons/Button";

export default {
    name: "JCA",
    components: {Button, TooltipIcon},
    props: ['tabIndex', 'previousIndex', 'tabs', 'data', 'jca_through'],
    data: () => ({
        ada,
        links: {
            fashionId: {
                en: 'https://curia.europa.eu/juris/liste.jsf?language=en&num=C-40/17',
                de: 'https://curia.europa.eu/juris/liste.jsf?language=de&num=C-40/17'
            },
            article26: {
                en: 'https://gdpr-info.eu/art-26-gdpr/',
                de: 'https://dsgvo-gesetz.de/art-26-dsgvo/'
            },
            jca: 'http://static.emsservice.de.s3-eu-west-1.amazonaws.com/sonstiges/DSS_MobileCheatSheetA3.pdf'
        },
        cancel_jca_tooltip: {
            en: '<div>' +
                '   <div> If you wish to withdraw your signature to the JCA, please<br> send an email stating the reason to' +
                '       following email address:<br><b>consent@ad-alliance.de</b>' +
                '   </div><br>' +
                '   <div>We will then contact you as soon as possible.</div>' +
                '</div>',
            de: '<div>' +
                '   <div> Falls Sie ihre Unterschrift vom JCA zurückziehen möchten,<br>senden Sie uns bitte eine E-Mail mit' +
                '       der Begründung an:<br><b>consent@ad-alliance.de</b>' +
                '   </div><br>' +
                '   <div>Wir werden uns so schnell wie möglich bei Ihnen melden.</div>' +
                '</div>'
        },
        disclaimer: {
            en: 'By accepting you\'re also agreeing to the storage of your ip address, the time of signing and your registered ' +
                'company name. Please contact us at <b>consent@ad-alliance.de</b> if you wish to sign the JCA differently.'
            ,
            de: 'Mit der Zustimmung akzeptieren Sie auch das Speichern Ihrer IP Adresse, registrierten Namen und dem\n' +
                '            Zeitpunkt der Unterschrift. Stimmen Sie dem nicht zu, bitten wir Sie\n' +
                '            <b>consent@ad-alliance.de</b> zu kontaktieren, um das JCA alternativ auf klassischem Wege zu unterschreiben.'
        },
        authorized_hint: {
            en: 'If you are authorized to represent certain vendors please provide this information later when your creating a relation to the vendor.',
            de: 'Wenn Sie dazu berechtigt sind Vendoren zu vertreten, dann geben Sie es bitte später beim Anlegen der Vendoren Relations an.',
        },
        valid: null,
        has_jca_through: true,
    }),
    mounted() {
        this.init()
        this.$emit('mounted', this);
    },
    computed: {
        hasChanges() {
            return this.$store.state.details.components.jca.hasChanges;
        },
        role() {
            return this.$store.state.users.current ? this.$store.state.users.current.role : null;
        },
        isAgency() {
            return this.role === 'agency' || this.role === 'agency_group' || this.$store.state.agency_vendor.selectedRole === 'agency';
        },
        lang() {
            return this.$store.state.details.lang;
        },
        rules() {
            return {
                jca: [() => {
                    if (this.data.jca === 0 && this.data.other_jca === 0 && this.data.processor_only === 0) {
                        return 'Please select at least one';
                    } else {
                        return true;
                    }
                }]
            }
        },
    },
    methods: {
        init() {
            if (this.data) {
                this.jca = this.data.jca;
            }
        },
        generateJCA() {
            this.$store.dispatch('details/generateJCA');
        },
        async download() {
            let data = await this.$store.dispatch('agency_vendor/downloadJCA', this.lang);
            const blob = new Blob([data], {type: 'application/json'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'AdAlliance_JCA.pdf';
            link.click()
            URL.revokeObjectURL(link.href)
        },
        toggleLanguage() {
            this.$store.commit('details/setLang', this.lang === 'en' ? 'de' : 'en');
        },
        handleChange() {
            if (this.$refs.form) this.$refs.form.resetValidation();
            let role = this.role === 'agency_group' ? 'agency' : this.role;
            let og_data = this.role === 'admin' || this.role === 'user' ?
                this.$store.state.agency_vendor.selectedData.details :
                this.$store.state.details.data[role].details;
            if (og_data === null) og_data = JSON.parse(JSON.stringify(this.$store.state.details.details_template));
            if (og_data.jca !== this.data.jca ||
                og_data.other_jca !== this.data.other_jca ||
                og_data.processor_only !== this.data.processor_only
            ) {
                if (!this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'jca', value: true});
            } else {
                if (this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'jca', value: false});
            }
        },
    },
    watch: {
        data() {
            this.init()
            if (!this.$store.state.details.isRegistration) {
                setTimeout(() => {
                    this.$emit('validate', 'JCA');
                }, 500);
            }
        },
        valid() {
            this.$emit('validate', 'JCA');
        },
    }
}
</script>

<style lang="scss" scoped>
.languageButton {
    position: absolute;
    top: 0;
    right: 0;
}

a {
    text-decoration: none;
    color: $ada_pink;
}

.jcaLink {
    cursor: pointer;
    color: $ada_pink;
    text-decoration: underline;
}

.disclaimer {
    font-size: 0.8em;
    line-height: 1.4em;

    &:before {
        content: '*';
        margin-right: 2px;
    }
}

</style>