<template>
    <div class="container">
        <div class="sectionHeadline text-h6">Processed data</div>
        <div class="mb-4"><i>{{ $store.state.details.combobox_hint }}</i></div>
        <v-form v-model="valid" ref="form" class="mb-4">
            <v-row>
                <v-col cols="12">
                    <Combobox multiple :rules="rules.data_processed" @change="handleChange"
                              label="What data is processed ?"
                              v-model="data_processed"
                              :disabled="$store.state.readOnly === 1 || !allowEdit"
                              :items="data_types_store.filter(x => x.is_special === 0 && x.is_template === 1)"></Combobox>
                </v-col>
                <v-col cols="12">
                    <Combobox multiple :rules="rules.special_data" @change="handleChange"
                              label="Do you process any special data categories ?"
                              v-model="special_data"
                              :disabled="$store.state.readOnly === 1 || !allowEdit"
                              :items="data_types_store.filter(x => x.is_special === 1 && x.is_template === 1)"></Combobox>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Combobox from "../../../components/Inputs/Combobox";

export default {
    name: "ProcessedData",
    props: ['tabIndex', 'previousIndex', 'tabs', 'data', 'allowEdit'],
    components: {Combobox},
    data: () => ({
        data_processed: [],
        special_data: [],
        data_types: [],
        valid: null,
        ignore_dp: false,
        ignore_sd: false,
        ada
    }),
    mounted() {
        if (this.data_types_store) {
            this.fetchDataTypes();
        } else {
            this.init()
        }
        this.$emit('mounted', this);
    },
    computed: {
        data_types_store() {
            return this.$store.state.details.data_types;
        },
        rules() {
            return {
                data_processed: [() => {
                    return this.data_processed.length === 0 ? 'Please select at least one' : true;
                }],
                special_data: [() => {
                    return this.special_data.length === 0 ? 'Please select at least one' : true;
                }]
            }
        },
        hasChanges() {
            return this.$store.state.details.components.data_types.hasChanges;
        },
        role() {
            return this.$store.state.users.current ? this.$store.state.users.current.role : null;
        },
        allReqFieldsNotFilled() {
            return (
                this.data.data_types.length === 0
            )
        }
    },
    methods: {
        init() {
            if (this.data) {
                this.data_processed = this.data.data_types.filter(x => x.is_special === 0);
                this.special_data = this.data.data_types.filter(x => x.is_special === 1);
                this.ignore_dp = false;
                this.ignore_sd = false;
                if ((this.$route.name === 'admin' || (this.role === 'vendor' && this.$store.state.details.data && this.$store.state.details.data.vendor && this.$store.state.details.data.vendor.is_complete === 1)) && this.data_processed.length !== 0 && this.special_data.length !== 0) this.valid = true;
            }
        },
        async fetchDataTypes() {
            if (!this.$store.state.details.fetching.dataTypes) {
                await this.$store.dispatch('details/fetchDataTypes').then(() => {
                    this.data_types_store.forEach((item) => {
                        this.data_types.push({
                            id: item.id,
                            name: item.name,
                            is_template: item.is_template,
                            is_special: item.is_special
                        });
                    })
                    this.init();
                });
            }
        },
        handleChange() {
            let role = this.role === 'agency_group' ? 'agency' : this.role;
            let og_data = this.role === 'admin' || this.role === 'user' ?
                this.$store.state.agency_vendor.selectedData.details :
                this.$store.state.details.data[role].details;
            if (og_data === null) og_data = JSON.parse(JSON.stringify(this.$store.state.details.details_template));
            let changed = false;
            let tmp_data_types = this.data_processed.concat(this.special_data);
            if (og_data.data_types.length !== 0 && og_data.data_types.length === tmp_data_types.length) {
                let tmpOG = JSON.parse(JSON.stringify(og_data.data_types));
                tmpOG.sort(function (a, b) {
                    return a.id - b.id
                });
                tmp_data_types.sort(function (a, b) {
                    return a.id - b.id
                });
                if (JSON.stringify(tmpOG) === JSON.stringify(tmp_data_types)) changed = true;
            }
            if (og_data.data_types.length !== tmp_data_types.length ||
                changed
            ) {
                if (!this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'data_types', value: true});
            } else {
                if (this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'data_types', value: false});
            }
        }
    },
    watch: {
        data_processed(v, old) {
            if (JSON.stringify(old) !== JSON.stringify(v)) {
                if (!this.ignore_dp) {
                    let newValues = JSON.parse(JSON.stringify(v));
                    for (let i = 0; i < newValues.length; i++) {
                        if (typeof newValues[i] === "string") {
                            newValues[i] = {
                                is_special: 0,
                                is_template: 0,
                                name: newValues[i]
                            }
                        }
                    }
                    this.data.data_types = newValues.concat(this.special_data);
                    if (JSON.stringify(v.sort()) !== JSON.stringify(newValues.sort())) {
                        this.ignore_dp = true;
                        this.data_processed = newValues;
                    }
                } else {
                    this.ignore_dp = false;
                }
            }
        },
        special_data(v, old) {
            if (JSON.stringify(old) !== JSON.stringify(v)) {
                if (!this.ignore_sd) {
                    let newValues = JSON.parse(JSON.stringify(v));
                    for (let i = 0; i < newValues.length; i++) {
                        if (typeof newValues[i] === "string") {
                            newValues[i] = {
                                is_special: 1,
                                is_template: 0,
                                name: newValues[i]
                            }
                        }
                    }
                    this.data.data_types = this.data_processed.concat(newValues);
                    if (JSON.stringify(v.sort()) !== JSON.stringify(newValues.sort())) {
                        this.ignore_sd = true;
                        this.special_data = newValues;
                    }
                } else {
                    this.ignore_sd = false;
                }
            }
        },
        data() {
            this.init();
            if (!this.$store.state.details.isRegistration) {
                setTimeout(() => {
                    this.$emit('validate', 'ProcessedData');
                }, 500);
            }
        },
        valid() {
            this.$emit('validate', 'ProcessedData');
        }
    }
}
</script>

<style scoped>

::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
    min-height: 60px;
}
</style>