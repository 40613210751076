<template>
    <v-dialog
        v-bind:value="value"
        :max-width="width"
        :persistent="persistent"
        @input="$emit('input', $event)"
        @click:outside="$emit('click:outside')"
        :transition="transition"
        :scrollable="scrollable"
    >
        <v-card
            :style="{'min-width': minWidth}">
            <v-card-title class="text-h6 d-flex justify-space-between">
                <span>{{ title }}</span>
                <v-btn v-if="showCloseButton" icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text v-scroll.self="onScroll" ref="scrollable">
                <slot></slot>
            </v-card-text>
            <v-card-actions v-if="!hideButtons" :class="scrolledToBottom ? 'scroll' : ''">
                <v-spacer v-if="!replaceButtons && !alignBtnsLeft"></v-spacer>
                <slot name="button"></slot>
                <div v-if="!replaceButtons">
                    <Button :text="true" @click="$emit('cancel')" :color="ada.darkGrey">{{ cancelText }}</Button>
                    <Button :text="true" :disabled="okDisabled" @click="$emit('ok')" :color="ada.pink"
                            v-bind:loading="loading">
                        {{ okText }}
                    </Button>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Button from "./buttons/Button";
import ada from "../scss/variables.scss"

export default {
    name: "Dialog",
    components: {Button},
    props: {
        value: {},
        width: {
            type: String,
            default: '450'
        },
        title: {
            type: String,
            default: 'Ad Control'
        },
        okDisabled: {
            type: Boolean,
            default: false
        },
        okText: {type: String, default: 'Save'},
        cancelText: {type: String, default: 'Cancel'},
        loading: Boolean,
        persistent: {type: Boolean, default: false},
        hideButtons: {
            type: Boolean,
            default: false
        },
        replaceButtons: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: false
        },
        transition: {
            type: String,
            default: 'scale-transition'
        },
        minWidth: {
            type: String,
            default: '450px'
        },
        showCloseButton: {
            type: Boolean,
            default: false
        },
        alignBtnsLeft: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            scrolledToBottom: false,
            scrolledToTop: false,
            listening: false,
            ada
        }
    },
    mounted() {
        this.onScroll();
    },
    computed: {
        errorCount() {
            return this.$store.state.errorCount;
        },
    },
    methods: {
        onScroll(el) {
            let element = el ? el.currentTarget : this.$refs.scrollable;
            if (element && element.scrollHeight > element.clientHeight) {
                this.scrolledToBottom = element.scrollHeight !== (element.clientHeight + element.scrollTop);
                this.scrolledToTop = element.scrollTop !== 0;
            }
        },
        close() {
            this.$emit('close');
            this.$emit('input', false);
        }
    },
    watch: {
        errorCount() {
            //this.loading = false;
            this.$emit('update:loading', false);
        }
    }
}
</script>

<style lang="scss" scoped>

.scroll {
    box-shadow: 1px 2px 18px rgb(0 0 0 / 35%);
    z-index: 1000;
}
</style>