<template>
    <v-combobox
        v-bind:value="value"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
        :disabled="disabled"
        :item-text="itemText"
        :items="items"
        :search-input.sync="search"
        :label="label"
        outlined
        hide-details="auto"
        :rules="rules"
        :chips="multiple"
        :deletable-chips="multiple"
        :multiple="multiple"
        validate-on-blur
    >
        <template v-slot:no-data>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        Press
                        <v-chip :color="ada.blue" class="white--text text-uppercase font-weight-bold mx-1" small label>
                            enter ⏎
                        </v-chip>
                        to confirm <b>{{ search }}</b>.
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
            <div v-if="multiple">
                <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="ada.lightGrey"
                    :input-value="selected"
                    label
                >
          <span :class="{'pr-2': $store.state.readOnly !== 1 && !disabled}">
            {{ typeof item === "string" ? item : item[itemText] }}
          </span>
                    <v-icon v-if="$store.state.readOnly !== 1 && !disabled"
                            small
                            @click="parent.selectItem(item)"
                    >
                        $delete
                    </v-icon>
                </v-chip>
            </div>
            <div v-else>
                {{ typeof item === "string" ? item : item[itemText] }}
            </div>
        </template>
    </v-combobox>
</template>

<script>
import ada from '@/scss/variables.scss'

export default {
    name: "Combobox",
    props: {
        value: {},
        items: {required: true},
        label: {type: String},
        multiple: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        itemText: {default: 'name'},
        rules: {type: [Array, Function]}
    },
    data: () => ({
        search: null,
        ada,
    }),
}
</script>

<style lang="scss" scoped>
::v-deep .mdi-close-circle::before {
    color: #a6afb5;
}
</style>