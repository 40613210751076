<template>
    <div>
        <div class="text-body-1 ma-4" style="font-size: 15px !important">The vendors that are listed here are going to
            be deleted in max. 30 days because they haven't completed their registration within the last 6 months.<br>
            The vendor will be
            receiving a last reminder mail when it's reaching the 2 weeks before deletion mark. <br>
            When the deletion day has arrived and the vendor still hasn't took any action, they will be deleted by
            9 PM (21:00 Uhr) of the same day.
        </div>
        <div v-if="isStageHost" class="text-body-1 ma-4 red--text" style="font-size: 15px !important">
            The Tasks are deactivated on stage. So this will not take any effect.
        </div>

        <Datatable :headers="headers" :items="items" title="Inactive Vendors"
                   :loading="$store.state.agency_vendor.fetching.inactive"
                   no-data-text="No Vendors to be deleted"
                   item-slot="item.status" item-slot2="item.user.warning_mail_sent">
            <!-- STATUS COL-->
            <template #item.status="item">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on" :color="getStatus(item).color">
                            mdi-circle
                        </v-icon>
                    </template>
                    <span>{{ getStatus(item).tooltip }}</span>
                </v-tooltip>
            </template>

            <!-- Warning Mail COL-->
            <template #item.user.warning_mail_sent="item">
                <v-icon :color="item.user && item.user.warning_mail_sent === 1 ? 'green' : 'red'"
                >
                    {{ item.user && item.user.warning_mail_sent === 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
        </Datatable>
    </div>
</template>

<script>
import Utils from '@/scripts/Utils';
import Datatable from "../../components/Tables/Datatable";

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export default {
    name: "InactiveVendorsTable",
    components: {Datatable},
    data: () => ({
        headers: [
            {text: 'Status', value: 'status', width: '100px'},
            {text: 'Vendor', value: 'name', width: '250px'},
            {text: 'Reminder Mail sent', value: 'user.warning_mail_sent', width: '170px', align: 'center'},
            {text: 'Days until deletion', value: 'interval', width: '200px'},
            {text: 'Delete at', value: 'deleteDate', width: '200px'},
            {text: 'Created at', value: 'created'}
        ]
    }),
    mounted() {
        this.fetchInactiveVendors();
    },
    computed: {
        inactiveVendors() {
            return this.$store.state.agency_vendor.inactiveVendors || [];
        },
        items() {
            let ret = [];
            let options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            };
            this.inactiveVendors.forEach((entry) => {
                    let tmp = entry;
                    let createdAtDate = new Date(tmp.created_at);
                    let deletionDate = new Date(tmp.created_at).addDays(180);
                    let diffTime = deletionDate - new Date();
                    tmp.status = this.getStatus(entry).prio;
                    tmp.deleteDate = deletionDate.toLocaleDateString('de-DE', options);
                    tmp.interval = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
                    tmp.created = createdAtDate.toLocaleDateString('de-DE', options);
                    ret.push(tmp);
                }
            )
            return ret;
        },
        isStageHost() {
            return document.location.host === 'dsgvo-stage.adalliance.io' || document.location.host.indexOf('localhost') !== -1
        }
    },
    methods: {
        async fetchInactiveVendors() {
            await this.$store.dispatch('agency_vendor/fetchInactiveVendors');
        },
        getStatus(item) {
            return Utils.getStatus(item);
        },
    },
}
</script>

<style scoped>

</style>