<template>
    <div>
        <v-data-table
            :loading="loading || $store.state.agency_vendor.fetching.vendors"
            :headers="headers"
            :items="items"
            :search="search"
            :custom-sort="customSort"
            sort-by="status"
            no-data-text="None"
            no-results-text="No Data"
            @click:row="$emit('click:edit', $event)"
        >
            <template v-slot:progress>
                <v-progress-linear indeterminate :color="ada.pink" class="d-flex" height="3"></v-progress-linear>
            </template>
            <template v-slot:top>
                <div class="d-flex align-center justify-space-between mx-4">
                    <div class="d-flex align-center flex-row">
                        <div class="pr-6 text-h5 font-weight-regular">Registered Vendors</div>
                        <v-text-field
                            v-model="search"
                            label="Search..."
                            append-icon="mdi-magnify"
                            class="mx-4 pt-5"
                            :color="ada.blue"
                            :style="{width: $vuetify.breakpoint.mdAndUp ? '300px' : '200px'}"
                        ></v-text-field>
                    </div>
                    <div>
                        <Button icon="mdi-microsoft-excel" color="#008001" @click="$emit('click:export')">
                            Export Excel
                        </Button>
                    </div>
                </div>
            </template>

            <!-- STATUS COL-->
            <template #item.status="{item}">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on" :color="getStatus(item).color">
                            mdi-circle
                        </v-icon>
                    </template>
                    <span>{{ getStatus(item).tooltip }}</span>
                </v-tooltip>
            </template>
            <!-- RELATED AGENCIES COL -->
            <template v-slot:item.agencies="{ item }">
                <div @click.stop="openAgenciesDialog(item)">{{ item.agencies ? item.agencies.length : '' }}</div>
            </template>
            <!-- JCA COL -->
            <template v-slot:item.has_jca="{ item }">
                <v-icon v-if="item.details" :color="item.has_jca === 1 ? 'green' : 'red'"
                >
                    {{ item.has_jca === 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
            <!-- ACTION COL-->
            <template v-slot:item.action="{ on, item }">
                <v-tooltip top v-for="(action, i) in item.action" :key="'action_'+i">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="actionIcon"
                            :data-type="action" :color="ada.grey"
                            @click.stop="$emit('click:'+action, item)">
                            {{ getAction(action).icon }}
                        </v-icon>
                    </template>
                    <span>{{ getAction(action).tooltip }}</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <Dialog v-model="showAgenciesDialog" :title="(selected ? selected.name : 'Agency') + ' relations'"
                show-close-button
                hide-buttons width="650px">
            <Datatable :headers="agency_headers" :items="selected ? selected.agencies : []"
                       title="Registered Relations" item-slot="item.details.jca">
                <template #item.details.jca="item">
                    <v-icon v-if="item.details" :color="item.details.jca === 1 ? 'green' : 'red'"
                    >
                        {{ item.details && item.details.jca === 1 ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
            </Datatable>
        </Dialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Utils from '@/scripts/Utils'
import Button from "../../components/buttons/Button";
import Dialog from "../../components/Dialog";
import Datatable from "../../components/Tables/Datatable";

export default {
    name: "VendorsTable",
    components: {Datatable, Dialog, Button},
    data: () => ({
        loading: false,

        search: null,
        selected: null,
        showAgenciesDialog: false,
        agency_headers: [
            {text: 'Agency', value: 'name'},
            {text: 'Country', value: 'details.country'},
            {text: 'JCA', value: 'details.jca'},
        ],
        ada
    }),
    mounted() {
        this.init();
    },
    computed: {
        vendors() {
            return this.$store.state.agency_vendor.vendors;
        },
        items() {
            let ret = [];
            if (this.vendors) {
                let options = {year: 'numeric', month: '2-digit', day: '2-digit'};
                this.vendors.forEach((vendor) => {
                    let tmp = vendor;
                    tmp.verified = tmp.verified_at !== null ? new Date(tmp.verified_at).toLocaleDateString('de-DE', options) : null;
                    tmp.pending = tmp.pending_at !== null ? new Date(tmp.pending_at).toLocaleDateString('de-DE', options) : null;
                    tmp.action = ['edit'];
                    tmp.status = this.getStatus(vendor).prio;

                    let jcaVendor = this.checkJCA(vendor);
                    tmp.has_jca = jcaVendor.has_jca;
                    tmp.jca_through = jcaVendor.jca_through;
                    ret.push(tmp);
                })
            }
            return ret;
        },
        headers() {
            return [
                {text: 'Status', value: 'status', width: '90px', align: 'center'},
                {text: 'Vendor', value: 'name', width: '300px'},
                {text: 'Country', value: 'details.country', width: '250px'},
                {text: 'TCF ID', value: 'details.tcf_id', width: '90px'},
                {text: 'Related Agencies', value: 'agencies', width: '150px', align: 'center'},
                {text: 'JCA', value: 'has_jca', width: '100px'},
                {text: 'Verify start', value: 'pending', width: '130px'},
                {text: 'Verified at', value: 'verified'},
                {text: 'Actions', value: 'action', width: '1%', align: 'center', sortable: false},
            ]
        },
    },
    methods: {
        init() {
            this.fetchVendors();
        },
        async fetchVendors() {
            this.loading = true;
            await this.$store.dispatch('agency_vendor/fetchVendors', {withRelations: true});
            this.loading = false;
        },
        getAction(action) {
            let ret = {
                tooltip: '',
                icon: ''
            };
            if (action === 'edit') {
                ret.tooltip = this.$store.state.readOnly !== 1 ? 'Edit' : 'Show details';
                ret.icon = this.$store.state.readOnly !== 1 ? 'mdi-pencil' : 'mdi-magnify-scan';
            } else if (action === 'delete') {
                ret.tooltip = 'Delete';
                ret.icon = 'mdi-delete';
            }
            return ret;
        },
        getStatus(item) {
            return Utils.getStatus(item);
        },
        actionClick(action, data) {
            if (action === 'edit') {
                this.openRelationDialog(data);
            } else if (action === 'delete') {
                this.openRelationDialog(data, true);
            }
        },
        openAgenciesDialog(vendor) {
            this.selected = vendor;
            this.showAgenciesDialog = true;
        },
        exportExcel() {
            this.$root.$emit('snackbar', 'TODO Excel Export')
        },
        checkJCA(vendor) {
            let ret = {has_jca: 0, jca_through: null};
            if (vendor.details) {
                if (vendor.details.jca === 0 && vendor.details.other_jca === 0) {
                    for (let i = 0; i < vendor.agencies.length; i++) {
                        let model = vendor.agencies[i].parent ? vendor.agencies[i].parent : vendor.agencies[i];

                        if (model && model.details &&
                            (model.details.jca === 1 || model.details.other_jca === 1) &&
                            model.pivot &&
                            model.pivot.authorized === 1) {
                            ret = {has_jca: 1, jca_through: vendor.agencies[i].name}
                        }
                    }
                } else {
                    ret = {
                        has_jca: vendor.details.jca !== 0 ? vendor.details.jca : vendor.details.other_jca,
                        jca_through: null
                    }
                }
            }
            return ret;
        },
        customSort(items, index, isDesc) {
            if (!index || !index.length || !isDesc || !isDesc.length) {
                return items
            }

            items.sort((a, b) => {
                a = this.getValue(a, index[0]);
                b = this.getValue(b, index[0]);
                if (index[0] === 'agencies') {
                    //number
                    if (!isDesc[0]) {
                        return (a.length - b.length);
                    } else {
                        return (b.length - a.length);
                    }
                } else if (!(isNaN(a))) {
                    //number
                    if (!isDesc[0]) {
                        return (a - b);
                    } else {
                        return (b - a);
                    }
                } else {
                    //string
                    a = a ? a : '';
                    b = b ? b : '';
                    if (!isDesc[0]) {
                        if (a === "") {
                            return 1;
                        } else if (b === "") {
                            return -1;
                        } else {
                            return a < b ? -1 : (a > b ? 1 : 0);
                        }
                    } else {
                        return (a > b) ? -1 : (a < b ? 1 : 0);
                    }
                }
            });
            return items;
        },
        getValue(item, key) {
            let ret = item;
            if (key) {
                let keys = key.split('.');
                let i = 0;
                keys.forEach((subKey) => {
                    if (i === 0 && typeof item[subKey] !== "undefined") {
                        ret = item[subKey];
                    } else if (i !== 0 && ret && typeof ret[subKey] !== "undefined") {
                        ret = ret[subKey];
                    } else {
                        ret = null
                    }
                    i++
                })
            }
            return ret && typeof ret === 'string' ? ret.toLowerCase() : ret;
        }
    },
}
</script>

<style scoped>


</style>