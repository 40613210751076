<template>
    <div class="d-flex flex-row justify-center align-center mb-6 mx-auto" style="max-width:800px">
        <div class="d-flex flex-column justify-center align-center mx-4">
            <TooltipIcon fab :color="is_completed ? 'green' : ada.blue"
                         :ripple="false" custom-style="cursor:default"
                         :tooltip="status === 'registration' ? 'Currenty in the registration process' : 'You\'ve successfully completed the registration'">
                {{
                    status === 'verifying' || status.indexOf('verified') !== -1 ? 'mdi-playlist-check' : 'mdi-playlist-edit'
                }}
            </TooltipIcon>
            <span>Registration</span>
        </div>
        <v-divider class="mb-4"></v-divider>
        <div class="d-flex flex-column justify-center align-center mx-4">
            <TooltipIcon fab
                         :color="status === 'verifying' ? ada.blue : (status.indexOf('verified') !== -1 ? 'green' : ada.grey)"
                         :ripple="false" custom-style="cursor:default"
                         :tooltip="status === 'verifying' ? 'We are currently in the process of verifying you.' : (status.indexOf('verified') !== -1 ? 'You\'ve been verified' : 'Verifying process not started')">
                {{ status.indexOf('verified') !== -1 ? 'mdi-progress-check' : 'mdi-progress-clock' }}
            </TooltipIcon>
            <span>Verifying Process</span>
        </div>
        <v-divider class="mb-4"></v-divider>
        <div class="d-flex flex-column justify-center align-center mx-4">
            <TooltipIcon fab :color="status.indexOf('verified') !== -1 ? 'green' : ada.grey" :ripple="false"
                         custom-style="cursor:default"
                         tooltip="You're a verified vendor and listed in our homepage">
                mdi-check
            </TooltipIcon>
            <span>Verified</span>
        </div>
        <v-divider v-if="status === 'conditional verified'" class="mb-4"></v-divider>
        <div v-if="status === 'conditional verified'" class="d-flex flex-column justify-center align-center mx-4">
            <TooltipIcon fab :color="ada.blue" :ripple="false"
                         custom-style="cursor:default"
                         tooltip="We are currently revising the new registered information. You're still verfied.">
                mdi-playlist-edit
            </TooltipIcon>
            <span>Checking new info</span>
        </div>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import TooltipIcon from "../../components/buttons/TooltipIcon";

export default {
    name: "StatusStepper",
    components: {TooltipIcon},
    props: {
        data: {type: Object}
    },
    data: () => ({
        ada,
    }),
    computed: {
        currentUser() {
            return this.$store.state.users.current;
        },
        role() {
            let ret = this.currentUser ? this.currentUser.role : null;
            return ret === 'agency_group' ? 'agency' : ret;
        },
        is_completed() {
            let data = this.role === 'admin' || this.role === 'user' ? this.data : this.data[this.role];
            return data ? data.is_complete === 1 : false;
        },
        status() {
            let ret = 'registration'
            let data = this.role === 'admin' || this.role === 'user' ? this.data : this.data[this.role];
            if (data) {
                if (data.verified_at && data.pending_at && data.pending_at > data.verified_at) {
                    ret = 'conditional verified';
                } else if (data.verified_at !== null) {
                    ret = 'verified';
                } else if (data.pending_at !== null) {
                    ret = 'verifying';
                }
            }
            return ret;
        }
    },
}
</script>

<style scoped>

</style>