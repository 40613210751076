<template>
    <div class="container">
        <div class="sectionHeadline text-h6 mb-4">Tracker domains</div>
        <v-btn v-if="lang==='en'" :color="ada.darkGrey" text @click="toggleLanguage" class="languageButton ma-6">
            <v-img src="@/assets/germany.png" width="25" class="mr-2"></v-img>
            German
        </v-btn>
        <v-btn v-else :color="ada.darkGrey" text @click="toggleLanguage" class="languageButton ma-6">
            <v-img src="@/assets/united-kingdom.png" width="25" class="mr-2"></v-img>
            English
        </v-btn>
        <v-form v-model="valid" ref="form">
            <v-row no-gutters>
                <v-col cols="12">
                    <Input label="OptOut URL" v-model="data.optout_url" :rules="rules.optout_url"
                           :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-web"
                           @blur="handleChange"></Input>
                </v-col>
                <v-col cols="12">
                    <Input label="Privacy Policy URL" v-model="data.privacy_url" :rules="rules.privacy_url"
                           :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-shield-lock"
                           @blur="handleChange" :hint="privacy_hint[lang]"></Input>
                </v-col>
                <v-col cols="12" v-if="$store.state.readOnly !== 1 && allowEdit" class="d-flex flex-row">
                    <Input label="Domain of Tracker / System" v-model="domain" class="pr-6" @enter="addDomain"
                           :error-messages="domainErr" :validate-on-blur="true"
                           :rules="rules.domains"></Input>
                    <TooltipButton :color="ada.blue" @click="addDomain" style="position:relative; top:10px"
                                   icon="mdi-plus" :disabled="domain && domain.length > 255"
                                   :tooltip="addTooltip[lang]">
                        {{ lang && lang === 'de' ? 'Hinzufügen' : 'Add' }}
                    </TooltipButton>
                </v-col>
            </v-row>
        </v-form>
        <v-divider class="my-4"></v-divider>
        <Datatable title="Domains" :headers="headers" :items="items" hide-search
                   no-data-text="None" :items-per-page="-1" :hide-default-footer="true"
                   @click:delete="deleteDomain" class="mb-4"></Datatable>
    </div>
</template>

<script>
import Input from "../../../components/Inputs/Input";
import ada from "@/scss/variables.scss";
import Datatable from "../../../components/Tables/Datatable";
import TooltipButton from "../../../components/buttons/TooltipButton";

export default {
    name: "Domains",
    props: ['data', 'tabIndex', 'previousIndex', 'tabs', 'allowEdit'],
    components: {TooltipButton, Datatable, Input},
    data: () => ({
        domain: null,
        valid: null,
        urlRegex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
        trackerDomainRegex: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
        domainErr: [],
        privacy_hint: {
            de: 'Bitte beachten Sie, dass eine Anforderung (von uns und auch der Datenschutzbehörden auf Basis des DSGVO) ist, dass eine Datenschutzerklärung auf Deutsch vorliegen muss.',
            en: 'One requirement (regarding European law, Article 12 (1) of the GDPR) is that a privacy policy must be available in German.'
        },
        addTooltip: {
            de: 'Vergessen Sie nicht auf \'SAVE\' zu drücken, <br> nachdem alle Domains hinzugefügt wurden sind',
            en: 'Make sure to also click on \'SAVE\' <br> to save your added domains after you\'re done'
        },
        ada
    }),
    mounted() {
        this.init();
        this.$emit('mounted', this);
    },
    computed: {
        lang() {
            return this.$store.state.details.lang;
        },
        items() {
            let ret = [];
            this.data.domains.forEach((domain) => {
                ret.push({
                    domain: domain.name,
                    action: ['delete']
                })
            })
            return ret;
        },
        headers() {
            let ret = [{text: 'Domain', value: 'domain'}];
            if (this.$store.state.readOnly !== 1 && this.allowEdit) ret.push({
                text: 'Actions',
                value: 'action',
                sortable: false,
                width: '100px', align: 'center'
            })
            return ret;
        },
        rules() {
            return {
                optout_url: [v => !!v || 'OptOut URL is required',
                    v => this.urlRegex.test(v) || 'Please enter a valid optout url e.g http://www.exampledomain.com',
                    v => (v && v.length <= 255) || 'OptOut URL must be less than 255 characters'],
                privacy_url: [v => !!v || 'Privacy URL is required',
                    v => this.urlRegex.test(v) || 'Please enter a valid privacy url e.g http://www.exampledomain.com',
                    v => (v && v.length <= 255) || 'Privacy URL must be less than 255 characters'],
                domains: [() => {
                    return this.data.domains.length === 0 ? 'Please enter at least one tracker domain and confirm it' : true
                },
                    v => ((v && v.length <= 255) || (v === null || v === '')) || 'Domain must be less than 255 characters'],
            }
        },
        hasChanges() {
            return this.$store.state.details.components.domains.hasChanges;
        },
        role() {
            return this.$store.state.users.current ? this.$store.state.users.current.role : null;
        },
        allReqFieldsNotFilled() {
            return (
                this.data.optout_url === null &&
                this.data.privacy_url === null &&
                this.data.domains.length === 0
            )
        }
    },
    methods: {
        init() {
        },
        addDomain() {
            if (this.trackerDomainRegex.test(this.domain)) {
                this.domainErr = [];
            } else {
                this.domainErr = ['Pleaser enter a valid domain'];
                return;
            }
            if (!this.data.domains.map(x => x.name).includes(this.domain)) {
                this.data.domains.push({name: this.domain});
                this.$root.$emit('snackbar', 'Tracker domain successfully added')
                this.domain = null;
                this.handleChange();
                this.$refs.vali
            } else {
                this.$root.$emit('snackbar', 'Tracker domain "' + this.domain + '" already exists', 'warning')
            }
        },
        toggleLanguage() {
            this.$store.commit('details/setLang', this.lang === 'en' ? 'de' : 'en');
        },
        deleteDomain(domainRow) {
            let index = this.data.domains.findIndex(x => x.name === domainRow.domain);
            if (index !== -1) this.data.domains.splice(index, 1);
            this.handleChange();
        },
        handleChange() {
            if (this.$refs.form) this.$refs.form.resetValidation();
            let role = this.role === 'agency_group' ? 'agency' : this.role;
            let og_data = this.role === 'admin' || this.role === 'user' ?
                this.$store.state.agency_vendor.selectedData.details :
                this.$store.state.details.data[role].details;
            if (og_data === null) og_data = JSON.parse(JSON.stringify(this.$store.state.details.details_template));
            let domainsChanged = false;
            if (og_data.domains.length !== 0 && og_data.domains.length === this.data.domains.length) {
                for (let i = 0; i < og_data.domains.length; i++) {
                    if (og_data.domains[i].name !== this.data.domains[i].name) {
                        domainsChanged = true;
                        break;
                    }
                }
            }

            if (og_data.optout_url !== this.data.optout_url ||
                og_data.privacy_url !== this.data.privacy_url ||
                og_data.domains.length !== this.data.domains.length ||
                domainsChanged
            ) {
                if (!this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'domains', value: true});
            } else {
                if (this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'domains', value: false});
            }
        }
    },
    watch: {
        data() {
            this.init()
            if (!this.$store.state.details.isRegistration) {
                setTimeout(() => {
                    this.$emit('validate', 'Domains');
                }, 500);
            }
        },
        valid() {
            //this.validate();
            this.$emit('validate', 'Domains');
        }
    }
}
</script>

<style scoped>
.languageButton {
    position: absolute;
    top: 0;
    right: 0;
}
</style>