<template>
    <div>
        <div class="sectionHeadline text-h6 mb-4">JCA PDF Upload</div>
        <div>
            <v-row no-gutters>
                <v-col>
                    <v-row>
                        <v-col>
                            <p>Here you can upload a new version of the JCA.<br>
                                The upload will override the old JCA/s and
                                will be immediately available for every user. <br>
                                The JCAs that were already signed, won't change. <br>
                            </p>
                            <p>Only PDFs allowed</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div>
                                <v-file-input
                                    v-model="pdf_en"
                                    accept=".pdf"
                                    label="Select new english JCA"
                                >
                                    <template #prepend-inner>
                                        <img src="@/assets/united-kingdom.png" width="25px" height="25" class="mr-2">
                                    </template>
                                </v-file-input>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <div>
                                <v-file-input
                                    v-model="pdf_de"
                                    accept=".pdf"
                                    label="Select new german JCA"
                                >
                                    <template #prepend-inner>
                                        <img src="@/assets/germany.png" width="25px" height="25" class="mr-2">
                                    </template>
                                </v-file-input>
                            </div>
                        </v-col>
                    </v-row>

                    <div class="d-flex justify-center mt-4">
                        <Button :color="ada.blue" icon="mdi-upload" @click="uploadFiles">Upload</Button>
                    </div>
                </v-col>
                <v-col>
                    <div class="d-flex flex-column align-center justify-center pb-10" style="height:100%">
                        <div class="text-body-1 mb-4 font-weight-medium">Current JCAs</div>
                        <div class="d-flex flex-column" style="width:auto">
                            <v-btn text class="mb-4" @click="download('en')">
                                <img src="@/assets/united-kingdom.png" width="25" class="mr-2">
                                English JCA
                                <v-icon :color="ada.darkGrey" class="ml-1" style="font-size:1.1rem">mdi-download
                                </v-icon>
                            </v-btn>
                            <v-btn text @click="download('de')">
                                <img src="@/assets/germany.png" width="25" class="mr-2">
                                German JCA
                                <v-icon :color="ada.darkGrey" class="ml-1" style="font-size:1.1rem">mdi-download
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Button from "../../../components/buttons/Button";

export default {
    name: "Settings_JCA",
    components: {Button},
    data: () => ({
        ada,
        pdf_en: null,
        pdf_de: null,
    }),
    methods: {
        async uploadFiles() {
            if (this.validate()) {
                let formData = new FormData();
                if (this.pdf_en) formData.append('en', this.pdf_en);
                if (this.pdf_de) formData.append('de', this.pdf_de);
                const {data} = await this.$store.dispatch('uploadJCAs', formData);
                if (data.status) {
                    this.$root.$emit('snackbar', 'Successfully uploaded JCA/s');
                }
            }
        },
        async download(lang) {
            let data = await this.$store.dispatch('agency_vendor/downloadJCA', lang);
            const blob = new Blob([data], {type: 'application/json'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'AdAlliance_JCA_' + lang + '.pdf';
            link.click()
            URL.revokeObjectURL(link.href)
        },
        validate() {
            if (this.pdf_en === null && this.pdf_de === null) {
                this.$root.$emit('snackbar', 'No pdfs selected');
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>

<style scoped>

</style>