<template>
    <div :style="{maxHeight: contentHeight}">
        <v-row no-gutters :style="{height: contentHeight}">
            <v-col cols="12" md="6" :style="{height: contentHeight}">
                <div class="d-flex align-center justify-center flex-column" style="height:100%">
                    <img style="width:100px; height:100px; margin-bottom: 30px" src="../assets/check_mark.png">
                    <p class="text-h6">Logout successful</p>
                    <p class="text-h6 font-weight-regular">Redirect to homepage in ... {{ this.count }}</p>
                </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" style="max-height:75vh" :style="{height: contentHeight}">
                <img class="svg" src="../assets/101-unlock.svg">
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'

export default {
    name: "Logout",
    data: () => ({
        ada,
        count: 3,
        waiting_time: 3000,
        intervalFunc: null
    }),
    mounted() {
        this.intervalFunc = setInterval(() => {
            this.count--;
            if (this.count === 0) {
                clearInterval(this.intervalFunc);
            }
        }, 1000)
        setTimeout(() => {
            this.$router.push('/');
        }, this.waiting_time + 1000)
    },
    computed: {
        contentHeight() {
            return this.$vuetify.breakpoint.smAndUp ? ada.md_contentHeight : ada.sm_contentHeight;
        }
    },
}
</script>

<style scoped>
.svg {
    height: 100%;
}
</style>