import {RepositoryFactory} from "../../scripts/RepositoryFactory";
import Vue from "vue";

const UserRepository = RepositoryFactory.get('users');

const state = {
    users: null,
    current: null,
    names: null,
    loading: {
        users: false,
    }
};

// getters
const getters = {};

// actions
const actions = {
    async fetchAllUsers() {
        state.loading.users = true;
        let {data} = await UserRepository.getAll();
        if (data && data !== "") {
            state.users = data;
        }
        state.loading.users = false;
    },
    async getCurrentUser({state, commit, rootState}) {
        let {data} = await UserRepository.getCurrentUser();
        if (data && data !== "") {
            state.current = data;
            if (data.role === 'user') {
                if (rootState.readOnly !== 1) commit('setReadOnly', 1, {root: true});
            } else {
                if (rootState.readOnly !== 0) commit('setReadOnly', 0, {root: true});
            }

        }
    },
    async fetchNames({state}) {
        let {data} = await UserRepository.getNames();
        if (data && data !== "") {
            state.names = data;
        }
    },
    async updateUser({state}, payload) {
        let data;
        if (payload.id) {
            data = await UserRepository.updateUser(payload.id, payload);
        } else {
            payload.validate_email = true;
            data = await UserRepository.createUser(payload);
        }
        if (data.data !== "") {
            Vue.set(state, 'users', data.data);
        }
        return data.data;
    },
    async deleteUser({state}, payload) {
        let {data} = await UserRepository.deleteUser(payload.id, payload);
        if (data !== "") {
            Vue.set(state, 'users', state.users.filter(x => x.id !== payload.id));
        }
        return data;
    },
    async verifyEmail(context, payload) {
        let {data} = await UserRepository.verifyEmail(payload);
        return data;
    }
};

// mutations
const mutations = {
    setCurrent(state, {data}) {
        state.current = data;
    },
    setUsers(state, data){
        state.users = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}