import {RepositoryFactory} from "../../scripts/RepositoryFactory";
import Vue from "vue";

const UserRepository = RepositoryFactory.get('users');
const DetailRepository = RepositoryFactory.get('details');
const ExportRepository = RepositoryFactory.get('export');

const state = {
    isRegistration: null,
    activeTab: 0,
    data: null,
    lang: "en",
    services: [],
    purposes: [],
    data_types: [],
    components: {
        assignments: {hasChanges: false},
        contact: {hasChanges: false},
        privacy: {hasChanges: false},
        services: {hasChanges: false},
        domains: {hasChanges: false},
        purposes: {hasChanges: false},
        data_types: {hasChanges: false},
        cookies: {hasChanges: false},
        jca: {hasChanges: false},
        relations: {hasChanges: false},
    },
    fetching: {
        services: false,
        purposes: false,
        dataTypes: false,
        details: false,
    },
    updated: 0, //for reactivity after save/update
    combobox_hint: 'You can either use our predefined dropdown options or use your own value and hit enter',
    details_template: { //won't change -> used to init when details = null
        "street": null,
        "zip": null,
        "city": null,
        "country": null,
        "service_name": null,
        "tcf_status": "registered",
        "tcf_id": null,
        "description": null,
        "optout_url": null,
        "privacy_url": null,
        "cookie_sync": 1,
        "cookie_sync_description": null,
        "jca": 0,
        "other_jca": 0,
        "processor_only": 0,
        "privacy": {
            "is_eu": 1,
            "non_eu_transfer": 0,
            "data_transfer": null,
            "name": null,
            "email": null,
            "street": null,
            "zip": null,
            "city": null,
            "country": null,
            "phone": null,
        },
        "domains": [],
        "cookies": [],
        "services": [],
        "purposes": [],
        "data_types": [],
    },
    units: [
        {text: "Seconds", value: "seconds"},
        {text: "Minutes", value: "minutes"},
        {text: "Hours", value: "hours"},
        {text: "Days", value: "days"},
        {text: "Months", value: "months"},
        {text: "Years", value: "years"}
    ],
    gvl: null, //IAB's GLOBAL VENDOR LIST
};

// getters
const getters = {
    relations() {
        if (state.data && state.data.agency && state.data.agency.vendors) {
            return state.data.agency.vendors
        } else {
            return [];
        }
    }
};

// actions
const actions = {
    async fetchData({commit, rootState}) {
        state.fetching.details = true;
        let {data} = await UserRepository.get(rootState.user ? rootState.user.id : rootState.users.current.id);
        if (data) commit('setData', data);
        state.fetching.details = false;
    },
    async fetchServices({state}) {
        state.fetching.services = true;
        let {data} = await DetailRepository.getServices();
        if (data) state.services = data;
        state.fetching.services = false;
    },
    async fetchPurposes({state}) {
        state.fetching.purposes = true;
        let {data} = await DetailRepository.getPurposes();
        if (data) state.purposes = data;
        state.fetching.purposes = false;
    },
    async fetchDataTypes({state}) {
        state.fetching.dataTypes = true;
        let {data} = await DetailRepository.getDataTypes();
        if (data) state.data_types = data;
        state.fetching.dataTypes = false;
    },
    async save({commit, rootState}, payload) {
        let {data} = await DetailRepository.save(payload);
        if (data !== "") {
            rootState.users.current.role === 'admin' ? commit('agency_vendor/updateDataDetails', data, {root: true}) : commit('setDetails', data);
            commit('resetHasChanges');
        }
        return data;
    },
    async update({commit, rootState}, payload) {
        let {data} = await DetailRepository.update(payload.id, payload);
        if (data !== "") {
            rootState.users.current.role === 'admin' ? commit('agency_vendor/updateDataDetails', data, {root: true}) : commit('setDetails', data);
            commit('resetHasChanges');
        }
        return data;
    },
    async updateService({dispatch}, payload) {
        let data = createOrUpdate('Service', payload);
        if (data) dispatch('fetchServices');
        return data;
    },
    async updatePurpose({dispatch}, payload) {
        let data = createOrUpdate('Purpose', payload);
        if (data) dispatch('fetchPurposes');
        return data;
    },
    async updateDataType({dispatch}, payload) {
        let data = createOrUpdate('DataType', payload);
        if (data) dispatch('fetchDataTypes');
        return data;
    },
    async deleteService({dispatch}, payload) {
        let {data} = await DetailRepository.deleteService(payload);
        if (data) dispatch('fetchServices');
        return data;
    },
    async deletePurpose({dispatch}, payload) {
        let {data} = await DetailRepository.deletePurpose(payload);
        if (data) dispatch('fetchPurposes');
        return data;
    },
    async deleteDataType({dispatch}, payload) {
        let {data} = await DetailRepository.deleteDataType(payload);
        if (data) dispatch('fetchDataTypes');
        return data;
    },
    async getGVL({commit}) {
        let {data} = await DetailRepository.getGVL();
        if (data) commit('setGVL', data);
        return data;
    },
    async fetchGVL() {
        let {data} = await DetailRepository.fetchGVL();
        return data;
    },
    async getGVLLastModified() {
        let {data} = await DetailRepository.getGVLLastModified();
        return data;
    },
    async generateJCA(context, payload) {
        let {data} = await ExportRepository.generateJCA(payload);
        return data;
    },
};

// mutations
const mutations = {
    setData(state, data) {
        state.data = data;
    },
    setRegistration(state, data) {
        state.isRegistration = data;
    },
    updateHasChanges(state, data) {
        let ret = JSON.parse(JSON.stringify(state.components[data.section]));
        ret.hasChanges = data.value;
        Vue.set(state.components, data.section, ret);
    },
    setActiveTab(state, data) {
        state.activeTab = data;
    },
    setDetails(state, data) {
        let role = state.data.role === 'agency_group' ? 'agency' : state.data.role;
        Vue.set(state.data, role, data);
        state.updated++;
    },
    resetHasChanges(state) {
        Object.keys(state.components).forEach((component) => {
            let tmp = JSON.parse(JSON.stringify(state.components[component]));
            tmp.hasChanges = false;
            Vue.set(state.components, component, tmp);
        })
    },
    setLang(state, data) {
        state.lang = data;
    },
    setGVL(state, data) {
        state.gvl = data;
    }
};

async function createOrUpdate(repositoryAction, payload) {
    let data;
    if (payload.id) {
        data = await DetailRepository['update' + repositoryAction](payload.id, payload);
    } else {
        data = await DetailRepository['create' + repositoryAction](payload);
    }
    return data.data;
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}