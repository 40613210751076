<template>
    <div>
        <Datatable :headers="headers" :items="items" title="Vendor history"
                   :loading="$store.state.agency_vendor.fetching.history"
                   @search="search = $event"
        >
        </Datatable>
    </div>
</template>

<script>
import Datatable from "../../components/Tables/Datatable";

export default {
    name: "HistoryTable",
    components: {Datatable},
    props: {
        type: {
            type: String,
            required: true //vendor/agency
        },
    },
    data: () => ({
        search: null,
        data: null,
    }),
    mounted() {
        this.fetchHistory();
    },
    computed: {
        headers() {
            let ret = [];
            if (this.type === 'vendor') {
                ret.push(
                    {text: 'Vendor', value: 'vendor', width: '250px'}
                );
            } else {
                ret.push({text: 'Agency', value: 'agency', width: '250px'});
            }
            ret.push({text: 'Description', value: 'description'},
                {text: 'User', value: 'user', width: '300px'},
                {text: 'Date', value: 'created', width: '200px'});
            return ret;
        },
        items() {
            if (this.data) {
                let ret = [];
                let options = {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                };
                this.data.forEach((entry) => {
                        let tmp = entry;
                        tmp.created = new Date(tmp.created_at).toLocaleDateString('de-DE', options);
                        ret.push(tmp);
                    }
                )
                return ret;
            }
            return []
        }
    },
    methods: {
        async fetchHistory() {
            let data = await this.$store.dispatch('agency_vendor/fetchHistory', {
                type: this.type,
                search: this.search
            });
            this.data = data.items;
        },
    },
    watch: {
        search() {
            this.fetchHistory();
        }
    },
}
</script>

<style scoped>

</style>