<template>
    <div>
        <v-data-table
            :loading="loading || $store.state.details.fetching.details"
            :headers="headers"
            :items="items"
            :search="search"
            no-data-text="None"
            no-results-text="No Data"
            show-group-by
            v-bind="$attrs"
            item-key="unique_id"
            @click:row="openRelationDialog($event, false)"
        >
            <template v-slot:progress>
                <v-progress-linear indeterminate :color="ada.pink" class="d-flex" height="3"></v-progress-linear>
            </template>
            <template v-slot:top>
                <div class="d-flex align-center justify-space-between mx-4">
                    <!-- SEARCH INPUT -->
                    <div class="d-flex align-center flex-row">
                        <div class="pr-6 text-h5 font-weight-regular">Registered Relations</div>
                        <v-text-field
                            v-model="search"
                            label="Search..."
                            append-icon="mdi-magnify"
                            class="mx-4 pt-5"
                            :color="ada.blue"
                            :style="{width: $vuetify.breakpoint.mdAndUp ? '300px' : '200px'}"
                        ></v-text-field>
                    </div>
                    <!-- ADD BUTTON ON THE TOP RIGHT -->
                    <div class="d-flex flex-row">
                        <Button
                            v-if="$store.state.users.current && ($store.state.users.current.role === 'admin' || $store.state.users.current.role.indexOf('agency') !== -1)"
                            icon="mdi-plus" :color="ada.blue"
                            @click="openRelationDialog(null)">
                            Add {{ $route.name === 'index' ? 'vendor' : '' }} relation
                        </Button>

                        <TooltipButton v-if="showExelExport" tooltip="Excel export relations by vendor"
                                       icon="mdi-microsoft-excel"
                                       color="#008001"
                                       @click="$emit('click:export-vendor')" class="ml-4"
                        >
                            Vendor
                        </TooltipButton>
                        <TooltipButton v-if="showExelExport" tooltip="Excel export relations by agency"
                                       icon="mdi-microsoft-excel"
                                       color="#008001"
                                       @click="$emit('click:export-agency')" class="ml-4"
                        >
                            Agency
                        </TooltipButton>
                        ´
                    </div>
                </div>
            </template>
            <!-- STORAGE COL-->
            <template v-slot:item.pivot.storage_duration="{ item }">
                <v-chip v-if="item.pivot.storage_duration" small
                        :color="getStorageColor(item.pivot)"
                        dark class="font-weight-bold"
                >
                    {{ item.pivot.storage_duration }}
                    {{
                        item.pivot.storage_duration === 1 ? item.pivot.storage_unit.substring(0, item.pivot.storage_unit.length - 1) : item.pivot.storage_unit
                    }}
                </v-chip>
                <span v-else></span>
            </template>
            <!-- CHECK/CLEAR ICON COLS -->
            <template v-for="(col, i) in iconCols" v-slot:[col.slotName]="{ item }">
                <v-icon :key="col.name+'_'+i"
                        :color="item.pivot[col.name] === 1 ? 'green' : 'red'"
                >
                    {{ item.pivot[col.name] === 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
            <!-- ACTION COL -->
            <template v-slot:item.action="{ on, item }">
                <v-tooltip top v-for="(action, i) in item.action" :key="'action_'+i">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="actionIcon"
                            :data-type="action" :color="ada.grey"
                            @click.stop="actionClick(action, item)">
                            {{ getAction(action).icon }}
                        </v-icon>
                    </template>
                    <span>{{ getAction(action).tooltip }}</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <AddEditRelationDialog v-model="showRelationDialog" :details="selected"
                               :relations="items"
                               @fetchAgencies="fetchAgencies"
                               @fetchDetails="fetchDetails"
        ></AddEditRelationDialog>
        <Dialog v-model="showDeleteRelationDialog" :loading="deleteLoading"
                ok-text="Delete" title="Delete Relationship"
                @ok="deleteRelation"
                @cancel="showDeleteRelationDialog = false"
        >
            Are you sure you want to delete the relation to
            <b>{{
                    selected ? vendors.find(x => x.id === selected.pivot.vendor_id).name : ''
                }}</b> ?
        </Dialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Button from "../../components/buttons/Button";
import Dialog from "../../components/Dialog";
import AddEditRelationDialog from "./AddEditRelationDialog";
import TooltipButton from "../../components/buttons/TooltipButton";

export default {
    name: "RelationsTable",
    components: {TooltipButton, AddEditRelationDialog, Dialog, Button},
    props: ['addAgencyCol', 'groupable', 'showExelExport'],
    data: () => ({
        ada,
        showRelationDialog: false,
        showDeleteRelationDialog: false,
        selected: null,
        search: null,
        deleteLoading: false,
        iconCols: [
            {slotName: 'item.pivot.authorized', name: 'authorized'},
            {slotName: 'item.pivot.dpa_signed', name: 'dpa_signed'},
            {slotName: 'item.pivot.data_provided', name: 'data_provided'},
            {slotName: 'item.pivot.personal_data_processed', name: 'personal_data_processed'},
            {slotName: 'item.pivot.in_stream', name: 'in_stream'},
            {slotName: 'item.pivot.out_stream', name: 'out_stream'},
            {slotName: 'item.pivot.in_page', name: 'in_page'},
            {slotName: 'item.pivot.ctv', name: 'ctv'},
        ]
    }),
    mounted() {
        this.fetchAgencies();
    },
    computed: {
        vuexState() {
            return this.$store.state.agency_vendor;
        },
        headers() {
            let ret = [
                {text: 'Authorized', value: 'pivot.authorized', width: "110px", align: 'center', groupable: false},
                {text: 'Vendor', value: 'vendor_name'},
                {text: 'Dpa signed', value: 'pivot.dpa_signed', sortable: false, width: '100px', groupable: false},
                {text: 'Type', value: 'pivot.type', sortable: false, groupable: false},
                {
                    text: 'Data provided',
                    value: 'pivot.data_provided',
                    width: '100px',
                    sortable: false,
                    groupable: false
                },
                //{text: 'Personal data processed', value: 'pivot.personal_data_processed', sortable: false},
                {text: 'Storage', value: 'pivot.storage_duration', width: '', sortable: false, groupable: false},
                {text: 'Relevance', value: 'pivot.relevance', width: '', sortable: false, groupable: false},
                {text: 'In-Stream', value: 'pivot.in_stream', width: '90px', sortable: false, groupable: false},
                {text: 'Out-Stream', value: 'pivot.out_stream', width: '100px', sortable: false, groupable: false},
                {text: 'In-Page', value: 'pivot.in_page', width: '90px', sortable: false, groupable: false},
                {text: 'CTV', value: 'pivot.ctv', width: '80px', sortable: false, groupable: false},
            ];
            if (this.addAgencyCol) {
                ret.splice(2, 0, {text: 'Agency', value: 'agency_name'},)
            }
            return ret;
        },
        items() {
            let ret = [];
            if (this.$route.name === 'admin') {
                if (this.$store.state.agency_vendor.selectedRole === 'agency') {
                    ret = this.$store.state.agency_vendor.selectedData ? this.$store.state.agency_vendor.selectedData.vendors : [];
                } else {
                    ret = this.$store.state.agency_vendor.agencies ?
                        [].concat.apply([], this.$store.state.agency_vendor.agencies.map(x => x.vendors)) : [];
                }
            } else {
                ret = this.$store.getters['details/relations'];
            }
            let id = 1;
            ret.forEach((item) => {
                item.vendor_name = this.vendors.find(x => x.id === item.pivot.vendor_id) ? this.vendors.find(x => x.id === item.pivot.vendor_id).name : '...';
                item.agency_name = this.agencies.find(x => x.id === item.pivot.agency_id) ? this.agencies.find(x => x.id === item.pivot.agency_id).name : '...';
                item.action = this.$store.state.readOnly !== 1 ? ['edit', 'delete'] : ['edit'];
                item.unique_id = id;
                id++;
            })
            return ret;
        },
        vendors() {
            return this.vuexState.vendors ? this.vuexState.vendors : [];
        },
        agencies() {
            return this.vuexState.agencies ? this.vuexState.agencies : [];
        },
        errorCount() {
            return this.$store.state.errorCount;
        },
        loading() {
            return this.$store.state.agency_vendor.fetching.agencies;
        }
    },
    methods: {
        openRelationDialog(data, deleteAction = false) {
            this.selected = data;
            if (deleteAction) {
                this.showDeleteRelationDialog = true;
            } else {
                this.showRelationDialog = true;
            }
        },
        getAction(action) {
            let ret = {
                tooltip: '',
                icon: ''
            };
            if (action === 'edit') {
                ret.tooltip = this.$store.state.readOnly !== 1 ? 'Edit' : 'Show details';
                ret.icon = this.$store.state.readOnly !== 1 ? 'mdi-pencil' : 'mdi-magnify-scan';
            } else if (action === 'delete') {
                ret.tooltip = 'Delete';
                ret.icon = 'mdi-delete';
            }
            return ret;
        },
        actionClick(action, data) {
            if (action === 'edit') {
                this.openRelationDialog(data);
            } else if (action === 'delete') {
                this.openRelationDialog(data, true);
            }
        },
        async fetchAgencies(force) {
            if ((this.vuexState.agencies === null || force) && !this.vuexState.fetching.agencies) {
                await this.$store.dispatch('agency_vendor/fetchAgencies', {withRelations: true});
            }
        },
        async fetchDetails() {
            //for agency view when a agency is logged in
            await this.$store.dispatch('details/fetchData').then(() => {
                this.$emit('update');
            });
        },
        async deleteRelation() {
            this.deleteLoading = true;
            await this.$store.dispatch('agency_vendor/deleteVendorRelation', this.selected.pivot).then((res) => {
                if (res !== "") {
                    if (this.$route.name === 'admin') {
                        this.fetchAgencies(true);
                    } else if (this.$route.name === 'index') {
                        this.fetchDetails();
                    }
                    this.$root.$emit('snackbar', 'Relationship successfully deleted', 'success');
                    this.showDeleteRelationDialog = false;
                }
            });
            this.deleteLoading = false;
        },
        getStorageColor(item) {
            if (['seconds', 'minutes', 'hours', 'days'].includes(item.storage_unit) || (item.storage_unit === 'months' && item.storage_duration < 6)) {
                return 'green';
            } else if (item.storage_unit === 'months' && item.storage_duration < 12) {
                return 'orange'
            } else {
                return 'red';
            }
        }
    },
    watch: {
        errorCount() {
            if (this.loading) this.loading = false;
            if (this.deleteLoading) this.deleteLoading = false;
        }
    },
}
</script>

<style lang="scss" scoped>

.container {
    max-width: 100% !important;
}

</style>