<template>
    <div :style="{maxHeight: contentHeight}">
        <v-row no-gutters :style="{height: contentHeight}">
            <v-col md="5" cols="12" :style="{height: contentHeight}">
                <div class="d-flex align-center justify-center flex-column mx-auto"
                     style="height:100%; max-width: 500px;">
                    <img style=" width:100px; height:100px; margin-bottom: 30px
                " src="../assets/error.png">
                    <p class="text-h6">Error: No user found</p>
                    <p class="text-h6 font-weight-regular text-center">Please contact an admin or consent@ad-alliance.de
                        and ask them to create an account for you.</p>
                    <Button :color="ada.darkGrey" class="mt-4" @click="$router.push({name: 'home'})">Back to Homepage
                    </Button>
                </div>
            </v-col>
            <v-col cols="7" v-if="$vuetify.breakpoint.mdAndUp" :style="{height: contentHeight}">
                <img class="svg" src="../assets/cat.png">
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Button from "../components/buttons/Button";

export default {
    name: "NoUserFound",
    components: {Button},
    data: () => ({
        ada
    }),
    computed: {
        contentHeight() {
            return this.$vuetify.breakpoint.smAndUp ? ada.md_contentHeight : ada.sm_contentHeight;
        }
    },
}
</script>

<style scoped>
.svg {
    margin-top: 3vh;
    height: 100%;
    width: 100%;
    position: relative;
    object-fit: contain;
}
</style>