<template>
    <div>
        <v-dialog v-bind:value="value" @input="$emit('input', $event)" :width="isRegister ? '600px' : '420px'"
                  @click:outside="$emit('click:outside')">
            <v-card class="pa-6">
                <v-card-title class="header justify-center mb-4 font-weight-bold">
                    {{ isRegister ? "Create your account" : "Sign in to your account" }}
                </v-card-title>
                <v-card-text>
                    <div v-if="!isRegister">
                        <v-btn outlined block :color="ada.pink" class="my-6" @click="sso_login">
                            Sign in with SSO
                        </v-btn>
                        <div style="position:relative">
                            <span class="loginDivider">OR</span>
                            <v-divider class="my-10"></v-divider>
                        </div>
                    </div>
                    <v-form v-model="valid" ref="form">
                        <v-row no-gutters>
                            <v-col cols="12"><Input v-model="email" label="Email" :rules="rules.email"
                                                    @enter="focusPassword"></Input>
                            </v-col>
                            <v-col>
                                <Input :class="{'pr-2':isRegister}" v-model="password" label="Password"
                                       :rules="rules.password"
                                       type="password" @enter="login" ref="password"></Input>
                            </v-col>
                            <v-col v-if="isRegister">
                                <Input class="pl-2" v-model="confirm_password" label="Confirm password"
                                       :rules="isRegister ? rules.confirm_password : []"
                                       type="password" @enter="login"></Input>
                            </v-col>
                            <v-col v-if="isRegister" cols="12">
                                <Dropdown v-model="type" :items="account_types" label="Type"
                                          :rules="isRegister ? rules.type : []"
                                          :return-object="false"></Dropdown>
                            </v-col>
                            <v-col v-if="isRegister" cols="12">
                                <v-combobox
                                    v-model="name"
                                    :items="companies"
                                    label="Company Name"
                                    :color="ada.darkGrey"
                                    outlined
                                    :rules="isRegister ? rules.name : []"
                                    validate-on-blur
                                    hide-no-data
                                ></v-combobox>
                            </v-col>
                            <v-col v-if="isRegister && showErrorMessage" cols="12" :style="{color:ada.red}">
                                <v-alert
                                    text
                                    prominent
                                    type="error"
                                    icon="mdi-alert-octagon"
                                >
                                    <b>{{ og_name }}</b> is already registered. <br> <br>
                                    If you need an additional account for {{ og_name }} please to contact us
                                    at
                                    <b>consent@ad-alliance.de</b>.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-form>
                    <div v-if="!isRegister" class="d-flex justify-end">
                        <span class="forgotPassword" @click="showForgotPasswordDialog = true">Forgot password?</span>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex flex-column">
                    <div class="d-flex flex-row justify-space-between" style="width:100%">
                        <v-btn v-if="isRegister && !($store.state.users.current !== null || ssoUser !== null)"
                               :color="ada.pink" text depressed :block="!isRegister"
                               @click="$emit('change:register', !isRegister)">
                            Sign in instead
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="px-4 white--text" :color="ada.pink" :loading="loading.login" depressed
                               :block="!isRegister" ref="submitBtn"
                               @click="login" :disabled="registerDisabled">
                            {{ isRegister ? 'Register' : 'Sign in' }}
                        </v-btn>
                    </div>
                    <span v-if="!isRegister" class="register_now"
                          @click="$emit('change:register', !isRegister)">No account yet? Register now !</span>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Dialog v-model="showForgotPasswordDialog" title="Forgot Password" ok-text="reset password"
                @ok="forgotPassword"
                @cancel="showForgotPasswordDialog = false">
            <p>Did you forgot your password?</p>
            <p>Enter your email address so we can send you a link to reset your password.</p>
            <v-form v-model="forgotValid" ref="forgotForm">
                <Input v-model="forgotEmailAddress" label="Email" @enter="forgotPassword"
                       :rules="showForgotPasswordDialog ? rules.email : []"></Input>
            </v-form>
        </Dialog>
        <Dialog v-if="isLiveHost" v-model="blocker" title="Maintenance" @click:outside="closeAll"
                hide-buttons show-close-button @close="closeAll">
            <p>Due to maintenance work on our tool, registration must currently be made directly to the following email
                address: <b>consent@ad-alliance.de</b></p>
            <p>Thank you for your understanding.</p>
            <p>Intern access:</p>
            <Input v-model="accessToken_input" label="Access Token" dense>
                <template #append-outer>
                    <div style="position:relative; top:-5px;">
                        <Button :color="ada.blue" icon="mdi-check" @click="checkToken">send</Button>
                    </div>
                </template>
            </Input>
        </Dialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Dropdown from "../components/Inputs/Dropdown";
import Input from "../components/Inputs/Input";
import Dialog from "../components/Dialog";
import Utils from "../scripts/Utils";
import Button from "../components/buttons/Button";

export default {
    name: "LoginRegisterDialog",
    props: ['value', 'isRegister'],
    components: {Button, Dialog, Input, Dropdown},
    data: () => ({
        ada,
        blocker: false,
        accessToken: 'vendorentool2022ada',
        accessToken_input: null,
        email: null,
        password: null,
        confirm_password: null,
        type: null,
        name: null,
        valid: false,
        forgotValid: false,
        account_types: [
            {text: 'Vendor', value: 'vendor'},
            {text: 'Agency', value: 'agency'},
            {text: 'Agency Group', value: 'agency_group'},
        ],
        loading: {
            login: false,
            names: false,
        },
        showForgotPasswordDialog: false,
        showErrorMessage: false,
        registerDisabled: false,
        og_name: null,
        forgotEmailAddress: null,
        enableLoginBlocker: false,
        get ssoUser() {
            return localStorage.getItem('ssoUser');
        },
    }),
    mounted() {
        this.init();
    },
    computed: {
        rules() {
            return {
                email: [v => !!v && v.trim() !== "" || 'Email is required',
                    v => Utils.validEmail(v) || 'Please enter a valid email'
                ],
                password: [v => !!v && v.trim() !== "" || 'Password is required'],
                confirm_password: [v => !!v && v.trim() !== "" || 'Please confirm your password',
                    () => this.password === this.confirm_password || 'Passwords are not identical'],
                type: [v => !!v && v.trim() !== "" || 'Type is required'],
                name: [v => !!v && v.trim() !== "" || 'Company name is required']
            }
        },
        isLiveHost() {
            return document.location.host === 'dsgvo.ad-alliance.de' && this.enableLoginBlocker;
        },
        companies() {
            return this.$store.state.users.names.filter(x => x.indexOf("Test") === -1);
        }
    },
    methods: {
        init() {
            this.fetchNames();
            this.loading.login = false;
            this.email = null;
            this.password = null;
            this.confirm_password = null;
            this.type = 'vendor';
            this.name = null;

            this.showErrorMessage = false;
            this.registerDisabled = false;
            if (this.value && this.isLiveHost) this.blocker = true;
        },
        async fetchNames() {
            if (this.$store.state.users.names === null) {
                this.loading.names = true;
                await this.$store.dispatch('users/fetchNames');
                this.loading.names = false;
            }
        },
        async login() {
            this.loading.login = true;
            this.$refs.form.validate();
            setTimeout(async () => {
                if (this.$refs.form.validate() && !this.showErrorMessage) {
                    let body = {
                        email: this.email,
                        password: this.password
                    }
                    if (this.isRegister) {
                        body.role = this.type;
                        body.name = this.name;
                        if (this.type === 'agency_group') body.is_group = 1;
                        await this.$store.dispatch('register', body).catch(() => {
                            this.loading.login = false;
                        });
                    } else {
                        body.sso = false;
                        await this.$store.dispatch('login', body).catch(() => {
                            this.loading.login = false;
                        });
                    }
                    this.loading.login = false;
                } else {
                    this.loading.login = false;
                }
            }, 500);
        },
        async sso_login() {
            let data = await this.$store.dispatch('login', {sso: true});
            location.href = data.url;
        },
        async forgotPassword() {
            await this.$refs.forgotForm.validate()
            if (this.forgotValid) {
                await this.$store.dispatch('forgotPassword', {email: this.forgotEmailAddress}).then((res) => {
                    if (res && res.status) {
                        this.$root.$emit('snackbar', 'Mail to reset password sent to ' + this.forgotEmailAddress);
                        this.showForgotPasswordDialog = false;
                    }
                });
            }
        },
        showCompanyNameErr(hide = false) {
            this.showErrorMessage = !hide;
            this.registerDisabled = !hide;
        },
        focusPassword() {
            this.$refs.password.$el.querySelector('input').focus()
        },
        checkToken() {
            if (this.accessToken_input === this.accessToken) {
                this.blocker = false;
                this.accessToken_input = null;
            } else {
                this.$root.$emit('snackbar', 'Invalid Token', 'warning')
            }
        },
        closeAll() {
            this.accessToken_input = null;
            this.$emit('input', false);
            this.$emit('click:outside', false);
        }
    },
    watch: {
        name(newValue) {
            let names = this.$store.state.users.names.map(x => x.toLowerCase())
            if (newValue && names.includes(newValue.trim().toLowerCase())) {
                let index = names.findIndex(x => x === newValue.trim().toLowerCase());
                this.og_name = this.$store.state.users.names[index];
                this.showCompanyNameErr();
            } else {
                this.og_name = null;
                this.showCompanyNameErr(true);
            }
        },
        isRegister() {
            if (!this.isRegister) this.init();
        },
        showForgotPasswordDialog(newValue) {
            if (!newValue && this.$refs.forgotForm) {
                this.forgotEmailAddress = null;
                this.$refs.forgotForm.resetValidation();
            }
        },
        value() {
            if (this.isLiveHost) {
                setTimeout(() => {
                    this.blocker = !!this.value;
                }, 200)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.loginDivider {
    position: absolute;
    background: white;
    top: -21px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 50px;
    text-align: center;
    color: rgba(0, 0, 0, 0.2);
}

.forgotPassword, .register_now {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
        color: $ada_pink;
    }
}

.register_now {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
    margin-top: 20px;
}

.header {
    font-size: 1.3em !important;
}
</style>