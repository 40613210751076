<template>
    <div style="max-height:70vh" :style="{maxHeight: contentHeight}">
        <v-row no-gutters :style="{height: contentHeight}">
            <v-col md="6" cols="12" :style="{height: contentHeight}">
                <div class="d-flex align-center justify-center flex-column" style="height:100%">
                    <img style="width:100px; height:100px; margin-bottom: 30px" src="../assets/error.png">
                    <p class="text-h6">Unauthorized</p>
                    <p class="text-h6 font-weight-regular">You do not have permission to view this page.</p>
                    <Button :color="ada.darkGrey" class="mt-4" @click="goToHomepage">Back to Homepage</Button>
                </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" :style="{height: contentHeight}">
                <div style="position: relative">
                    <img class="svg" src="../assets/6_unauthorized.svg">
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Button from "../components/buttons/Button";

export default {
    name: "Unauthorized",
    components: {Button},
    data: () => ({
        ada
    }),
    methods: {
        goToHomepage() {
            this.$router.push({name: 'home'});
        }
    },
    computed: {
        contentHeight() {
            return this.$vuetify.breakpoint.smAndUp ? ada.md_contentHeight : ada.sm_contentHeight;
        }
    },
}
</script>

<style scoped>
.svg {
    position: absolute;
    height: 90vh;
    left: -10vw;
    top: -10vh;
}
</style>