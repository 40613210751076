<template>
    <div class="container">
        <div class="sectionHeadline text-h6 mb-4">Service descriptions</div>
        <v-form v-model="valid" ref="form">
            <Textarea label="General Description" v-model="data.description" rows="5"
                      :disabled="$store.state.readOnly === 1 || !allowEdit"
                      :rules="rules.description" @blur="handleChange"></Textarea>
            <div class="mb-3 mt-6"><i>{{ $store.state.details.combobox_hint }}</i></div>
            <div :key="forceReRender" class="mb-4">
                <transition-group name="slide-y-transition">
                    <component v-for="(component, i) in dropdowns" v-model="data.services[i]" :is="component.name"
                               :items="filteredServices()" :hideDelete="i === dropdowns.length - 1"
                               :rules="i === 0 ? rules.services : []" :multiple="false"
                               @change="addToSelected" :allowEdit="allowEdit"
                               :key="component.name+'_'+i" :index="i" @click:clear="deleteService"></component>
                </transition-group>
            </div>
        </v-form>
    </div>
</template>

<script>
import Textarea from "../../../components/Inputs/Textarea";
import ServiceDropdownRow from "../ServiceDropdownRow";

export default {
    name: "Services",
    props: ['data', 'tabIndex', 'previousIndex', 'tabs', 'allowEdit'],
    components: {ServiceDropdownRow, Textarea},
    data: () => ({
        dropdowns: [{name: 'ServiceDropdownRow'}],
        search: null,
        valid: null,
        forceReRender: 0,
    }),
    mounted() {
        this.init();
        this.$emit('mounted', this);
    },
    computed: {
        vuexState() {
            return this.$store.state.details;
        },
        services() {
            return this.vuexState.services;
        },
        rules() {
            return {
                description: [v => !!v || 'General Description is required'],
                services: [this.data.services.length !== 0 || 'Please select at least one description']
            }
        },
        hasChanges() {
            return this.$store.state.details.components.services.hasChanges;
        },
        role() {
            return this.$store.state.users.current ? this.$store.state.users.current.role : null;
        },
        allReqFieldsNotFilled() {
            return (
                this.data.description === null &&
                this.data.services.length === 0
            )
        }
    },
    methods: {
        init() {
            if (this.vuexState.services.length === 0) this.fetchServices();
            if (this.data) {
                // reset to 0 first to delete old Data in Dropdown
                this.resetDropdowns();
                this.dropdowns = [{name: 'ServiceDropdownRow'}];
                for (let i = 0; i < this.data.services.length; i++) {
                    this.dropdowns.push({name: 'ServiceDropdownRow'});
                    if ((this.$store.state.readOnly === 1 || !this.allowEdit) && i === this.data.services.length - 1) {
                        this.dropdowns.pop();
                    }
                }
            }
        },
        filteredServices() {
            let ret = JSON.parse(JSON.stringify(this.services));
            for (let i = 0; i < ret.length; i++) {
                if (this.data.services.map(x => x.name).includes(ret[i].name)) {
                    ret.splice(i, 1);
                    i--;
                }
            }
            return ret;
        },
        async fetchServices() {
            if (!this.$store.state.details.fetching.services) {
                await this.$store.dispatch('details/fetchServices');
            }
        },
        addToSelected(obj) {
            let index = obj.service_old ? this.data.services.findIndex(x => x.id === obj.service_old.id) : -1;
            obj.service_new.pivot = {
                service_id: obj.service_new.id,
                priority: obj.priority
            }
            if (index !== -1) {
                this.data.services[index] = obj.service_new;
                this.forceReRender++;
            } else if (this.data.services.findIndex(x => x.name === obj.service_new.name) === -1) {
                this.data.services.push(obj.service_new);
                this.dropdowns.push({name: 'ServiceDropdownRow'});
            }
            this.handleChange();
        },
        deleteService(service) {
            if (this.dropdowns.length > 1) {
                this.dropdowns.pop();
            }
            if (this.data.services.findIndex(x => x.name === service.name) !== -1) {
                this.data.services.splice(this.data.services.findIndex(x => x.name === service.name), 1);
                for (let i = 0; i < this.data.services.length; i++) {
                    this.data.services[i].pivot.priority = i + 1;
                }
            }
            this.forceReRender++;
            this.handleChange();
        },
        resetDropdowns() {
            this.dropdowns = [];
            this.forceReRender++;
        },
        handleChange() {
            let role = this.role === 'agency_group' ? 'agency' : this.role;
            let og_data = this.role === 'admin' || this.role === 'user' ?
                this.$store.state.agency_vendor.selectedData.details :
                this.$store.state.details.data[role].details;
            if (og_data === null) og_data = JSON.parse(JSON.stringify(this.$store.state.details.details_template));
            let changed = false;
            if (og_data.services.length !== 0 && og_data.services.length === this.data.services.length) {
                for (let i = 0; i < og_data.services.length; i++) {
                    if (og_data.services[i].id !== this.data.services[i].id ||
                        og_data.services[i].pivot.priority !== this.data.services[i].pivot.priority) {
                        changed = true;
                        break;
                    }
                }
            }
            if (og_data.description !== this.data.description ||
                og_data.services.length !== this.data.services.length ||
                changed
            ) {
                if (!this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'services', value: true});
            } else {
                if (this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'services', value: false});
            }
        }
    },
    watch: {
        data() {
            this.init()
            if (!this.$store.state.details.isRegistration) {
                setTimeout(() => {
                    this.$emit('validate', 'Services');
                }, 500);
            }
        },
        valid() {
            this.$emit('validate', 'Services');
        },
        allowEdit() {
            this.init();
        }
    }
}
</script>

<style scoped>

</style>