<template>
    <div class="container">
        <div class="sectionHeadline text-h6 mb-4">Contact</div>
        <v-form style="max-width: 750px;" ref="form" v-model="valid">
            <v-row no-gutters>
                <v-col cols="12">
                    <Input label="Company Name" :rules="rules.name" v-model.trim="name_" @blur="handleChange"
                           :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-domain"
                    ></Input>
                </v-col>
                <v-col cols="12">
                    <Input label="Street + No" :rules="rules.street" @blur="handleChange"
                           :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-home"
                           v-model.trim="data.street"
                    ></Input>
                </v-col>
                <v-col cols="6" class="pr-3">
                    <Input label="Zip / Postal Code" :rules="rules.zip" @blur="handleChange"
                           :disabled="$store.state.readOnly === 1 || !allowEdit"
                           v-model.trim="data.zip" append-icon="mdi-map-marker"
                    ></Input>
                </v-col>
                <v-col cols="6" class="pl-3">
                    <Input label="City"
                           v-model.trim="data.city" :rules="rules.city" @blur="handleChange"
                           :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-map"
                    ></Input>
                </v-col>
                <v-col cols="12">
                    <Dropdown v-model.trim="data.country" label="Country" item-value="value" :return-object="false"
                              :disabled="$store.state.readOnly === 1 || !allowEdit"
                              :rules="rules.country" @blur="handleChange" append-icon="mdi-earth"
                              :items="countries.map(x=> ({'text': x.name.common, 'value':x.name.common}))"></Dropdown>
                </v-col>
                <v-col cols="12">
                    <Input label="Service Name (if different from company name)" @blur="handleChange"
                           :disabled="$store.state.readOnly === 1 || !allowEdit"
                           v-model.trim="data.service_name" append-icon="mdi-domain"
                           :hint="isAgency ? 'If you also want to represent yourself as a vendor, please create a separate vendor account' : ''"
                    ></Input>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import Input from "../../../components/Inputs/Input";
import ada from '@/scss/variables.scss';
import {countries} from '@/scripts/countries.js';
import Dropdown from "../../../components/Inputs/Dropdown";

export default {
    name: "Contact",
    props: ['data', 'tabIndex', 'previousIndex', 'tabs', 'name', 'allowEdit'],
    components: {Dropdown, Input},
    data: () => ({
        rules: {
            name: [v => !!v && v.trim() !== "" || 'Name is required',
                v => (v && v.length <= 255) || 'Name must be less than 255 characters'],
            street: [v => !!v && v.trim() !== "" || 'Street is required',
                v => /\d/.test(v) || 'Street number missing',
                v => (v && v.length <= 255) || 'Street name must be less than 255 characters'
            ],
            zip: [v => !!v && v.trim() !== "" || 'Zip / Postal Code is required',
                v => /^[a-zA-Z0-9]+$/.test(v) || 'Zip / Postal Code must only contain letters or numbers',
                v => (v && v.length <= 20) || 'Zip / Postal Code must be less than 20 characters'],
            city: [v => !!v && v.trim() !== "" || 'City is required',
                v => (v && v.length <= 255) || 'City must be less than 255 characters'],
            country: [v => !!v && v.trim() !== "" || 'Country is required',
                v => (v && v.length <= 255) || 'Country must be less than 255 characters'],
        },
        name_: null,
        valid: null,
        countries,
        ada,
    }),
    mounted() {
        this.init();
        this.$emit('mounted', this);
    },
    computed: {
        hasChanges() {
            return this.$store.state.details.components.contact.hasChanges;
        },
        role() {
            return this.$store.state.users.current ? this.$store.state.users.current.role : null;
        },
        isAgency() {
            return this.role === 'admin' ? this.$store.state.agency_vendor.selectedRole === 'agency' : this.role.indexOf('agency') !== -1
        },
        requiredFieldsNotFilled() {
            return (
                this.data.street === null &&
                this.data.zip === null &&
                this.data.city === null &&
                this.data.country === null
            )
        }
    },
    methods: {
        init() {
            this.name_ = this.name;
        },
        handleChange() {
            let role = this.role === 'agency_group' ? 'agency' : this.role;
            let og_data = this.role === 'admin' || this.role === 'user' ? this.$store.state.agency_vendor.selectedData : this.$store.state.details.data[role];
            if (og_data.details === null) og_data = JSON.parse(JSON.stringify(this.$store.state.details.details_template));
            if (og_data.name !== this.name ||
                og_data.details.street !== this.data.street ||
                og_data.details.zip !== this.data.zip ||
                og_data.details.city !== this.data.city ||
                og_data.details.country !== this.data.country ||
                og_data.details.service_name !== this.data.service_name
            ) {
                if (!this.hasChanges) {
                    this.$store.commit('details/updateHasChanges', {section: 'contact', value: true});
                }
            } else {
                if (this.hasChanges) {
                    this.$store.commit('details/updateHasChanges', {section: 'contact', value: false});
                }
            }
        }
    },
    watch: {
        name(v) {
            this.name_ = v;
        },
        name_(v) {
            if (this.name !== v) {
                this.$emit('change:name', this.name_);
            }
        },
        data() {
            this.init();
            if (!this.$store.state.details.isRegistration) {
                setTimeout(() => {
                    this.$emit('validate', 'Contact');
                    //this.validate();
                }, 500);
            }
        },
        valid() {
            //this.validate();
            this.$emit('validate', 'Contact');
        }
    }
}
</script>

<style scoped>
</style>