<template>
    <v-btn depressed :disabled="disabled" :plain="plain" :block="block" :color="color" :text="text"
           :loading="loading" :outlined="outlined || loading" v-bind="$attrs" v-on="$listeners"
           :class="{'white--text':color && !plain && !text}">
        <v-icon v-if="icon" left :style="iconOptions">{{
                icon
            }}
        </v-icon>
        <span class="button">
             <slot></slot>
        </span>
    </v-btn>
</template>

<script>
import ada from '../../scss/variables.scss';

export default {
    name: "Button",
    props: {
        icon: {
            type: String,
            default: null
        },
        iconOptions: {
            type: Object,
            default: () => {
            }
        },
        color: {
            type: String,
            default: null
        },
        plain: {
            type: Boolean,
        },
        block: {
            type: Boolean,
        },
        text: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
        }
    },
    data: () => {
        return {
            ada
        }
    }
}
</script>

<style lang="scss" scoped>
.pink {
    color: $ada_pink;
}

.blue {
    color: $ada_blue;
}

.purple {
    color: $ada_purple;
}

.button {
    transition: all 0.3s ease-in-out;
}

</style>