import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import routes from './router';
import store from './store'
import vuetify from './plugins/vuetify'


Vue.use(VueRouter);

const router = new VueRouter({routes});
Vue.config.devtools = true;
Vue.config.productionTip = false;

router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Ad Alliance Vendor Tool';
    next();
})

let vm = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');

export default vm;