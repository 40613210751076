<template> <!--https://vuetifyjs.com/en/api/v-autocomplete/-->
    <v-autocomplete
        :items="items"
        :label="label"
        :item-text="itemText"
        :item-value="itemValue"
        :dense="dense"
        :chips="multiple"
        :multiple="multiple"
        :disabled="disabled"
        :color="ada.darkGrey"
        :outlined="outlined"
        :rules="rules"
        auto-select-first
        flat
        :menu-props="menuProps"
        :return-object="returnObject"
        no-data-text="No Data"
        @change="$emit('change', $event)"
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        v-on:blur="$emit('blur', $event)"
        :required="required"
        :append-outer-icon="appendIcon"
        :error-messages="errorMessages"
        :placeholder="placeholder"
        :loading="loading"
        :clearable="clearable"
        :hint="hint"
        :persistent-hint="typeof hint !== 'undefined'"
        validate-on-blur
    >
        <template v-if="multiple" v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                label
                :color="ada.lightGrey"
                :input-value="data.selected"
                :close="!disabled"
                @click="data.select"
                @click:close="removeSize(data.item)"
            >
                {{ data.item[itemText] }}
            </v-chip>
        </template>
        <template v-slot:item="data">
            <slot v-if="customTemplate" name="customItemTemplate" v-bind:item="data.value"></slot>
            <span v-else v-html="data.item[itemText]"></span>
        </template>
        <template #progress>
            <v-progress-linear
                indeterminate
                height="2"
                :color="ada.pink" style="position:absolute; bottom: 5px; width: 100%"
            ></v-progress-linear>
        </template>
        <template #append-outer>
            <slot name="append-outer"></slot>
        </template>
    </v-autocomplete>
</template>

<script>
import ada from "../../scss/variables.scss";

export default {
    name: "Dropdown",
    props: {
        items: {
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        itemText: {
            type: String,       //propertyName of items’s text value
            default: 'text'
        },
        itemValue: {
            type: String,       //propertyName of items’s value
            default: 'value'
        },
        dense: {type: Boolean, default: false},
        multiple: {type: Boolean, default: false},
        loading: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        value: [String, Array, Object, Number],
        required: {type: Boolean, default: false},
        errorMessages: {required: false},
        placeholder: {required: false},
        clearable: {type: Boolean, default: false},
        customTemplate: {type: Boolean, default: false},
        outlined: {type: Boolean, default: true},
        returnObject: {type: Boolean, default: true},
        hint: {type: String},
        appendIcon: {type: String},
        rules: Array || Function,
    },
    data: () => {
        return {
            ada,
            menuProps: {
                allowOverflow: true,
            }
        }
    },
    methods: {
        parseData(name) {
            let ret = name.replaceAll('&nbsp;', '');
            ret = name.replaceAll('&raquo;', '');
            ret = name.replaceAll('&raquo; ', '');
            return ret;
        },
        removeSize(item) {
            let index = this.value.findIndex(x => x[this.itemValue] === item[this.itemValue]);
            if (index >= 0) this.value.splice(index, 1);
            this.$emit('input', this.value);
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .mdi-close-circle::before {
    color: #a6afb5;
}

::v-deep .v-text-field.v-select--chips {
    .v-label {
        top: 12px;
    }

    .v-label--active {
        transform: translateY(-26px) scale(0.75);
    }
}
</style>