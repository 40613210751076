<template>
    <v-snackbar bottom right :color="color"
                v-bind:value="value"
                :timeout="timeout"
                v-on:input="$emit('input', $event)">
        <slot></slot>
        <template v-slot:action="{ attrs }">
            <v-btn icon
                   v-bind="attrs"
                   @click="$emit('input', false)"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "Snackbar",
    props: {
        color: {
            type: String
        },
        value: {},
        timeout: {
            default: 5000
        }
    }
}
</script>

<style scoped>

</style>