<template>
    <v-app class="lightGrey">
        <Navbar/>
        <v-main color="lightGrey">
            <div v-if="$store.state.users.current" class="current_email">
                <span style="font-weight: 300; opacity:0.5;">logged in as:</span>
                <span style="opacity: 0.6;"> {{
                        $store.state.users.current.email || $store.state.users.current.sso_email
                    }}</span>
            </div>
            <!--<v-progress-linear
                :active="loading"
                :indeterminate="loading"
                absolute
                top
                :color="ada.pink"
            ></v-progress-linear>-->
            <div class="routerContainer ma-sm-5 ma-md-10">
                <transition name="fade" mode="out-in">
                    <router-view/>
                </transition>
            </div>
        </v-main>
        <v-footer padless>
            <v-card flat tile
                    width="100%"
                    class="text-center"
                    :color="ada.darkGrey"
            >
                <v-card-text>
                    <div class="d-flex justify-center mb-4">
                        <div v-for="link in footerLinks" :key="link.name" class="footerLink"
                             :class="[$vuetify.breakpoint.name]">
                            <a :href="link.route" :target="link.name === 'Start' ? '_self' : '_blank'">
                                {{ link.name }}
                            </a>
                        </div>
                    </div>
                    <div :style="{color: ada.grey}">
                        <v-icon :style="{color: ada.grey}" small style="position: relative;top:-1px;">mdi-copyright
                        </v-icon>
                        {{ new Date().getFullYear() }} — <strong>Ad Alliance</strong>
                    </div>
                </v-card-text>
            </v-card>
        </v-footer>
        <Snackbar v-model="snackbar" :color="snackbar_type" :timeout="snackbar_timeout">
            <div v-html="snackbar_message"></div>
        </Snackbar>
    </v-app>
</template>

<script>
import Navbar from "./components/Navbar";
import Snackbar from "./components/Snackbar";
import ada from '@/scss/variables.scss';

export default {
    name: 'App',
    components: {Snackbar, Navbar},
    data: () => ({
        ada,
        snackbar: false,
        snackbar_timeout: 5000,
        snackbar_type: 'success',
        snackbar_message: null,
        showDeleteDialog: false,
        deleteData: null,
        deleteLoading: false,
        footerLinks: [
            {name: 'Start', route: '/'},
            {name: 'Privacy Protection', route: 'https://www.ad-alliance.de/cms/lp/datenschutz.html'},
            {name: 'Site Terms', route: 'https://www.ad-alliance.de/cms/lp/agb.html'},
            {name: 'Imprint', route: 'https://www.ad-alliance.de/cms/datenschutz/impressum.html'},
        ]
    }),
    mounted() {
        if (window.location.host === "dsgvo.adalliance.io") {
            location.href = "https://dsgvo.ad-alliance.de/#/";
        }
        this.$root.$on('snackbar', this.activateSnackbar);
        this.$root.$on('snackbarOff', this.deactivateSnackbar);
        this.$root.$on('showDeleteDialog', this.openDeleteDialog);
        this.$root.$on('deleteLoading', this.updateDeleteLoading);
    },
    computed: {
        loading() {
            return this.$store.state.loading;
        },
        errorMessage() {
            return this.$store.state.error;
        },
        errorCount() {
            return this.$store.state.errorCount;
        },
    },
    methods: {
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            // eslint-disable-next-line no-useless-escape
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        activateSnackbar(msg, type = 'success') {
            //if (error && this.showSaveChangesDialog) this.showSaveChangesDialog = false;
            this.snackbar = false;
            this.snackbar_type = type;
            this.snackbar_timeout = msg.length > 85 ? 10000 : (msg.length > 50 ? 7000 : 5000);
            let timeout = type === 'error' ? 300 : 150;
            setTimeout(() => {
                this.snackbar_message = msg;
                this.snackbar = true;
            }, timeout)
        },
        deactivateSnackbar() {
            this.snackbar = false;
        },
        openDeleteDialog(data) {
            /*this.deleteTitle = data.title;
            this.deleteMessage = data.message;*/
            this.deleteData = data;
            this.showDeleteDialog = true;
        },
        updateDeleteLoading(value) {
            this.deleteLoading = value;
        }
    },
    watch: {
        $route() {
            if (this.getParameterByName('session')) {
                // eslint-disable-next-line no-undef
                sso.saveSsoUser();
            }
        },
        errorCount() {
            this.activateSnackbar(this.errorMessage, 'error');
        },
        showLoginDialog() {
            if (this.showLoginDialog) {
                this.showLoginDialogModel = true;
            } else {
                this.showLoginDialogModel = false;
            }
        }
    }
};
</script>

<style lang="scss">

.footerLink {
    color: $ada_grey;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.9em;
    transition: all 0.3s ease-in-out;

    a {
        color: $ada_grey !important;
        text-decoration: none;
    }

    &.md, &.lg, &.xl {
        padding: 0 40px;
    }

    &.sm {
        padding: 0 30px;
    }

    &.xs {
        padding: 0 15px;
    }

    &:not(:last-child) {
        border-right: 1px solid rgba(208, 212, 218, 0.3);
    }
}

.current_email {
    position: absolute;
    top: 15px;
    right: 20px;
    color: $ada_darkGrey;
    font-size: 15px;
    font-weight: 400;
}

.blur {
    filter: blur(10px);
    user-select: none;
}

.lightGrey {
    background-color: $ada_lightGrey !Important;
}

.v-application {
    color: $ada_darkGrey !important;
}

.routerContainer {
    margin: 10px;
}


::v-deep .actionIcon {
    cursor: pointer;
    color: $ada_darkGrey !important;
    opacity: 0.6;
}

::v-deep .actionIcon[data-type="edit"]:hover, ::v-deep .actionIcon[data-type="copy"]:hover, ::v-deep .actionIcon[data-type="json"]:hover {
    color: $ada_blue !important;
    opacity: 1;
}

::v-deep .actionIcon[data-type="delete"]:hover {
    color: $ada_red !important;
    opacity: 1;
}

.fade-enter-active,
.fade-leave-active,
.fastfade-enter-active,
.fastfade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fastfade-enter-active,
.fastfade-leave-active {
    transition-duration: 0.2s;
}

.fade-enter,
.fade-leave-active,
.fastfade-enter,
.fastfade-leave-active {
    opacity: 0
}

.header {
    font-family: 'Montserrat', 'Roboto', Arial, sans-serif !important;
    font-weight: 600;
    font-size: 1.6em !important;
}
</style>
