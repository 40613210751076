<template>
    <div :style="{maxHeight: contentHeight}">
        <v-row no-gutters :style="{height: contentHeight}">
            <v-col md="6" cols="12" :style="{height: contentHeight}">
                <div style="height:100%" class="d-flex flex-column align-center justify-center">
                    <img style="width:100px; height:100px; margin-bottom: 30px" src="../assets/error.png">
                    <p class="text-h6">Error 404: Page not found</p>
                    <p class="text-h6 font-weight-regular">The page you wanted to access does not exist.</p>
                    <Button :color="ada.darkGrey" class="mt-4" @click="$router.push({name: 'home'})">Back to Homepage
                    </Button>
                </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" :style="{height: contentHeight}">
                <img class="svg" src="../assets/404.svg">
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Button from "../components/buttons/Button";

export default {
    name: "Error404",
    components: {Button},
    data: () => ({
        ada
    }),
    computed: {
        contentHeight() {
            return this.$vuetify.breakpoint.smAndUp ? ada.md_contentHeight : ada.sm_contentHeight;
        }
    },
}
</script>

<style scoped>
.svg {
    height: 100%;
    width: 100%;
    left: -5vw;
    position: relative;
    object-fit: contain;
}
</style>