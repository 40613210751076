<template>
    <v-textarea
        :label="label"
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        :color="ada.darkGrey"
        :rows="rows ? rows : 10"
        :disabled="disabled"
        :rules="rules"
        @blur="$emit('blur', $event)"
        hide-details="auto"
        :hint="hint"
        :persistent-hint="typeof hint !== 'undefined'"
        outlined
        :append-icon="appendIcon"
        validate-on-blur
    ></v-textarea>
</template>

<script>
import ada from '../../scss/variables.scss';

export default {
    name: "Textarea",
    props: ['label', 'value', 'rows', 'disabled', 'rules', 'hint', 'appendIcon'],
    data: () => {
        return {ada}
    }
}
</script>

<style scoped>

</style>