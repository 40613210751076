import ada from '@/scss/variables.scss';

export default {
    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        // eslint-disable-next-line no-useless-escape
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    getInitials(email) {
        let initialsArr = email.split('@')[0].split('.');
        let ret = '';
        initialsArr.forEach((initial) => {
            if (email.indexOf('@ad-alliance.de') !== -1) {
                ret += initial[0].toUpperCase();
            } else {
                ret = initial[0].toUpperCase() + ret;
            }
        });
        return ret;
    },

    getName(email) {
        let initialsArr = email.split('@')[0].split('.');
        let ret = [];
        initialsArr.forEach((initial) => {
            if (email.indexOf('@ad-alliance.de') !== -1) {
                ret.push(this.capitalize(initial.replaceAll('-', ' ')));
            } else {
                ret.unshift(this.capitalize(initial.replaceAll('-', ' ')));
            }

        });
        return ret.join(' ');
    },

    capitalize(input) {
        if (input === null) return null;
        var words = input.split(' ');
        var CapitalizedWords = [];
        words.forEach(element => {
            CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
        });
        return CapitalizedWords.join(' ');
    },

    formatDate(date, withTime = false) { //2018-02-26T11:56:50+01:00
        var d = new Date(date);

        let options = {year: 'numeric', month: '2-digit', day: '2-digit'};
        if (withTime) {
            options.hour = '2-digit';
            options.minute = '2-digit';
            options.second = '2-digit';
        }

        return d.toLocaleDateString('de-DE', options);
    },

    htmlDecode(input) {
        var e = document.createElement('textarea');
        e.innerHTML = input;
        // handle case of empty input
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    },

    arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    },

    toFormData(json) {
        let formData = new FormData();
        for (let key in json) {
            if (Array.isArray(json[key])) { //{example:['value1', 'value2']} --> example[]: 'value1', example[]: 'value2'
                json[key].forEach((value) => {
                    formData.append(key + '[]', value);
                })
            } else if (this.isObject(json[key])) { //{example:{key: value}} --> example[key][]: value
                for (let scndlvlKey in json[key]) {
                    formData.append(key + '[' + scndlvlKey + '][]', json[key][scndlvlKey]);
                }
            } else {
                formData.append(key, json[key]);
            }
        }
        return formData;
    },

    handleError(scope, model, message) {
        const errors = [];
        if (!scope.$v[model].$dirty) {
            return errors;
        }
        if (typeof scope.$v[model].required !== "undefined" && !scope.$v[model].required) errors.push(typeof message === "string" ? message : message.required);
        if (typeof message !== "string") {
            Object.keys(message).forEach((validation) => {
                if (typeof scope.$v[model][validation] !== "undefined" && !scope.$v[model][validation]) errors.push(message[validation])
            })
        }
        return errors;
    },
    getDateString() {
        let date = new Date().toISOString();
        date = date.split('.')[0];
        let underscoredDate = date.replaceAll(new RegExp('T|:', 'g'), '-').replaceAll('-', '_');
        return '_copy_' + underscoredDate;
    },

    parseZoneName(str) {
        return str.replaceAll(/(&raquo;)|(&nbsp;)|\s+/g, '');
    },

    isJsonString(jsonString) {
        try {
            var o = JSON.parse(jsonString);
            if (o && typeof o === "object") {
                return o;
            }
        } catch (e) {
            console.error(e);
        }

        return false;
    },

    isObject(a) {
        return (!!a) && (a.constructor === Object);
    },
    formatDateToIso(datestring) {
        var foo = datestring.split('.');
        var DD = foo[0];
        var MM = foo[1];
        var YYYY = foo[2].split(' ') [0].trim();
        var HH = foo[2].split(' ') [1].split(':') [0].trim();
        var MMM = foo[2].split(' ') [1].split(':') [1].trim();
        return YYYY + '-' + MM + '-' + DD + ' ' + HH + ':' + MMM;
    },
    convert: function (d) {
        return (
            d.constructor === Date ? d :
                d.constructor === Array ? new Date(d[0], d[1], d[2]) :
                    d.constructor === Number ? new Date(d) :
                        d.constructor === String ? new Date(d) :
                            typeof d === "object" ? new Date(d.year, d.month, d.date) :
                                NaN
        );
    },
    compare: function (a, b) {
        return (
            isFinite(a = this.convert(a).valueOf()) &&
            isFinite(b = this.convert(b).valueOf()) ?
                (a > b) - (a < b) :
                NaN
        );
    },

    appendCorsParam(secondaryParam = false) {
        let ret = secondaryParam ? '&' : '?';
        if (location.origin.indexOf('localhost') !== -1) {
            return ret += 'local=2';
        } else if (location.origin.indexOf('adcontrol-stage') !== -1) {
            return ret += 'stage=2';
        } else if (location.origin.indexOf('adcontrol') !== -1) {
            return ret += 'live=2';
        }
        return '';
    },

    prettifyHTML(html) {
        let tab = '\t';
        let result = '';
        let indent = '';
        let singletonTags = ['area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr'];

        html.split(/>\s*</).forEach(function (element) {
            if (element.match(/^\/\w/)) {
                indent = indent.substring(tab.length);
            }

            result += indent + '<' + element + '>\r\n';
            let elementTag = element.split(' ')[0];
            if (element.match(/^<?\w[^>]*[^/]$/) && !singletonTags.includes(elementTag)) {
                indent += tab;
            }
        });

        return result.substring(1, result.length - 3);
    },

    uglifyHTML(html) {
        if (html) {
            return html.replace(/(\r\n|\n|\r|\t)/gm, "")
        } else {
            return "";
        }
    },

    naturalCompare(a, b) {
        var ax = [], bx = [];

        if (Object.prototype.hasOwnProperty.call(a, "text")) {
            a.text.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
                ax.push([$1 || Infinity, $2 || ""])
            });
            b.text.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
                bx.push([$1 || Infinity, $2 || ""])
            });
        } else if (Object.prototype.hasOwnProperty.call(a, "completeKey")) {
            a.completeKey.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
                ax.push([$1 || Infinity, $2 || ""])
            });
            b.completeKey.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
                bx.push([$1 || Infinity, $2 || ""])
            });
        } else {
            a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
                ax.push([$1 || Infinity, $2 || ""])
            });
            b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
                bx.push([$1 || Infinity, $2 || ""])
            });
        }


        while (ax.length && bx.length) {
            var an = ax.shift();
            var bn = bx.shift();
            var nn = (an[0] - bn[0]);
            if (isNaN(nn)) {
                if (an[1].indexOf('spteaser') !== -1 || bn[1].indexOf('spteaser') !== -1) {
                    if (an[1] === 'spteaser_' && bn[1] === 'teaser_') {
                        nn = 1;
                    } else if (an[1] === 'teaser_' && bn[1] === 'spteaser_') {
                        nn = -1
                    } else {
                        nn = an[1].localeCompare(bn[1])
                    }
                } else {
                    nn = an[1].localeCompare(bn[1])
                }
            }
            if (nn) return nn;
        }

        return ax.length - bx.length;
    },

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    },

    //just for JSON Editor
    getFinalJsonKey(jsonKey, json) {
        // check if key already exists (ignoring the order) if yes return the key
        let ret = jsonKey;
        if (jsonKey) {
            let currentKeys = jsonKey.replaceAll(' ', '').split(',');
            let keys = [];
            if (json) {
                Object.keys(json).forEach((key) => {
                    keys.push({
                        templates: key.replaceAll(' ', '').split(','),
                        completeKey: key
                    })
                })
            }
            for (let i = 0; i < keys.length; i++) {
                if ((currentKeys.length === keys[i].templates.length) &&
                    (currentKeys.sort().join(',') === keys[i].templates.sort().join(','))) {
                    ret = keys[i].completeKey;
                    break;
                }
            }
        }
        return ret;
    },

    checkDuplicateKeys(key = null, json = null, keyToBeIgnored = null) {
        //checken ob es den key gibt. falls ja -> alert
        //if entry selected --> and this.getFinalJsonKey === this.key --> ignore
        //if entry selected --> this.getFinalJsonKey !== this.key
        let found = [];
        if (key && json) {
            let templateKeys = key.split(',').map(x => x.trim());
            let existingKeys = json ? Object.keys(json) : [];
            if (keyToBeIgnored && existingKeys.findIndex(x => x === keyToBeIgnored) !== -1) {
                existingKeys.splice(existingKeys.findIndex(x => x === keyToBeIgnored), 1);
            }
            existingKeys.forEach((existingKey) => {
                let splittedExistingKey = existingKey.split(',').map(x => x.trim());
                splittedExistingKey.forEach((x) => {
                    if (templateKeys.includes(x)) {
                        found.push(x);
                    }
                })
            })
        }
        return found;
    },

    parseJSONKey(key, types, teasertypes) {
        let template = {'type': null, 'positions': [], 'teasertypes': [], 'completeKey': key};
        let singleKeys = key.split(',');
        for (let i = 0; i < singleKeys.length; i++) {
            let noCoreKeyFound = true;
            types.forEach((type) => {
                if (singleKeys[i].indexOf('_' + type.value) !== -1) {
                    singleKeys[i] = singleKeys[i].replace('_' + type.value, '');
                    template['type'] = type;
                    noCoreKeyFound = false;
                }
            })
            if (noCoreKeyFound && template['type'] !== 'Default') {
                template['type'] = types.find(x => x.value === 'default');
            }

            teasertypes.forEach((teasertype) => {
                if (singleKeys[i].indexOf(teasertype.value) !== -1) {
                    singleKeys[i] = singleKeys[i].replace(teasertype.value, '');
                    if (template['teasertypes'].indexOf(teasertype.name) === -1) {
                        template['teasertypes'].push(teasertype.name);
                    }
                    noCoreKeyFound = false;
                }
            })
            singleKeys[i] = singleKeys[i].replace(/\s/g, '');
            if (template['positions'].indexOf(singleKeys[i]) === -1) {
                template['positions'].push(singleKeys[i]);
            }
        }
        if (!template.teasertypes.includes('Anzeige')) {
            template['teasertypes'].unshift('Presenter');
        }
        return template;
    },

    sortJsonKeys(templateKeys) {
        let ret = [];
        let order = ['default', 'homepage', 'index', 'biga', 'article'];
        order.forEach((type) => {
            if (templateKeys.find(x => x.type.value === type)) {
                let keysOfType = templateKeys.filter(x => x.type.value === type);
                keysOfType.sort(this.naturalCompare);
                let keysWithnoTeasertypes = keysOfType.filter(x => x.teasertypes.length === 0);
                let keysWithteasertypes = keysOfType.filter(x => x.teasertypes.length !== 0);
                keysWithnoTeasertypes.forEach((key) => {
                    ret.push(key);
                })
                keysWithteasertypes.forEach((key) => {
                    ret.push(key);
                })
            }
        })
        return ret;
    },

    validEmail(v) {
        return v ? /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(v) : false;
    },

    //only for vendor and inactive vendor table
    getStatus(item) {
        let ret = {tooltip: '', color: '', prio: 0};
        if (item.pending_at && item.verified_at && item.pending_at > item.verified_at) {
            ret.tooltip = 'verified, but made some changes';
            ret.color = 'lime';
            ret.prio = 3;
        } else if (item.verified_at) {
            ret.tooltip = 'verified';
            ret.color = 'green';
            ret.prio = 5;
        } else if (item.pending_at) {
            if (item.verified_before_update === 1) {
                ret.tooltip = 'was verified before update, waiting to be re-verified';
                ret.color = 'teal';
                ret.prio = 1;
            } else {
                ret.tooltip = 'waiting to be verified';
                ret.color = 'orange';
                ret.prio = 2;
            }
        } else if (item.is_complete === 1) {
            ret.tooltip = 'completed registration but not verified'
            ret.color = 'red';
            ret.prio = 4;
        } else if (item.is_complete === 0) {
            ret.tooltip = 'registration not complete'
            ret.color = ada.lightGrey;
            ret.prio = 6;
        } else {
            ret.tooltip = '???'
            ret.color = ada.red;
        }
        return ret;
    },
}