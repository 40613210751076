<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :search="search_data"
        :items-per-page="itemsPerPage"
        :custom-sort="customSort"
        :loading="loading"
        :no-data-text="noDataText"
        :class="{pointer : hasActionEdit}"
        :disablePagination="disablePagination"
        :hide-default-footer="hideDefaultFooter"
        no-results-text="No Data"
        :dense="dense"
        @click:row="$emit('click:row', $event)"
    >
        <template v-slot:progress>
            <v-progress-linear indeterminate :color="ada.pink" class="d-flex" height="3"></v-progress-linear>
        </template>
        <template v-slot:top>
            <div class="d-flex align-center justify-space-between mx-4">
                <div class="d-flex align-center flex-row">
                    <div v-if="title" class="pr-6 text-h6 font-weight-regular">{{ title }}</div>
                    <v-text-field
                        v-if="!hideSearch"
                        v-model="search_data"
                        label="Search..."
                        append-icon="mdi-magnify"
                        class=" pt-5"
                        :class="{'mx-4': title}"
                        :color="ada.blue"
                        :style="{width: $vuetify.breakpoint.mdAndUp ? '300px' : '200px'}"
                    ></v-text-field>
                    <slot name="top-middle"></slot>
                </div>
                <div>
                    <slot name="top"></slot>
                </div>
            </div>
        </template>
        <template v-slot:item.action="{ on, item }">
            <v-tooltip top v-for="(action, i) in item.action" :key="'action_'+i">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="actionIcon"
                        :data-type="action" :color="ada.grey"
                        @click.stop="$emit('click:'+action, item)">
                        {{ getAction(action).icon }}
                    </v-icon>
                </template>
                <span>{{ getAction(action).tooltip }}</span>
            </v-tooltip>
        </template>
        <template v-slot:[parseItemSlot]="{ item }">
            <slot :name="itemSlot" v-bind="item"></slot>
        </template>
        <template v-slot:[parseItemSlot2]="{ item }">
            <slot :name="itemSlot2" v-bind="item"></slot>
        </template>
        <template #item.created_at="{ on, item }">
            <div>{{ item.createdFormatted ? item.createdFormatted : item.created_at }}</div>
        </template>
    </v-data-table>
</template>

<script>
import ada from '../../scss/variables.scss';

export default {
    name: "Datatable",
    props: {
        headers: {type: Array, required: true},
        items: {type: Array, required: true},
        itemsPerPage: {type: Number, default: 10},
        title: {type: String, default: null},
        customSort: {type: Function},
        itemSlot: {type: String, default: null},
        itemSlot2: {type: String, default: null},
        dense: {type: Boolean, default: false},
        loading: {type: Boolean, default: false},
        hideSearch: {type: Boolean, default: false},
        noDataText: {type: String, default: 'No Data'},
        hideDefaultFooter: {type: Boolean, default: false},
        disablePagination: {type: Boolean, default: false},
    },
    data: () => {
        return {
            search_data: null,
            ada
        }
    },
    computed: {
        hasActionEdit() {
            return this.items.length > 0 &&
                typeof this.items[0].action !== "undefined" &&
                this.items[0].action.indexOf('edit') !== -1;
        },
        parseItemSlot() {
            return this.itemSlot ? this.itemSlot.replace(':', '.') : null;
        },
        parseItemSlot2() {
            return this.itemSlot2 ? this.itemSlot2.replace(':', '.') : null;
        }
    },
    methods: {
        emitRow(data) {
            this.$emit('click:row', data);
        },
        getAction(action) {
            let ret = {
                tooltip: '',
                icon: ''
            };
            if (action === 'edit') {
                ret.tooltip = 'Edit';
                ret.icon = this.$store.state.readOnly !== 1 ? 'mdi-pencil' : 'mdi-magnify-scan';
            } else if (action === 'delete') {
                ret.tooltip = 'Delete';
                ret.icon = 'mdi-delete';
            } else if (action === 'copy') {
                ret.tooltip = 'Copy';
                ret.icon = 'mdi-content-copy';
            } else if (action === 'resetPassword') {
                ret.tooltip = 'Reset Password';
                ret.icon = 'mdi-lock-reset';
            } else if (action === 'resend') {
                ret.tooltip = 'Resend verify email';
                ret.icon = 'mdi-email-sync';
            }
            return ret;
        }
    },
    watch: {
        search_data(newValue) {
            this.$emit('search', newValue);
        }
    },
}
</script>

<style lang="scss" scoped>
.actionIcon {
    cursor: pointer;
    color: $ada_darkGrey !important;
    opacity: 0.6;
}

.actionIcon[data-type="resend"] {
    margin-left: 3px;
}

.actionIcon[data-type="edit"]:hover, .actionIcon[data-type="copy"]:hover, .actionIcon[data-type="resetPassword"]:hover, .actionIcon[data-type="resend"]:hover {
    color: $ada_blue !important;
    opacity: 1;
}

.actionIcon[data-type="delete"]:hover {
    color: $ada_red !important;
    opacity: 1;
}

::v-deep .v-data-table__progress th {
    padding: 0 !important;
}

::v-deep tbody tr {
    cursor: pointer;
}
</style>