<template>
    <div>
        <div :style="{maxHeight: contentHeight}">
            <v-row no-gutters :style="{height: contentHeight}">
                <v-col md="6" cols="12" :style="{height: contentHeight}">
                    <div class="d-flex align-center justify-center flex-column" style="height:90%">
                        <img style="width:100px; height:100px; margin-bottom: 30px" src="../assets/check_mark.png">
                        <p class="text-h6 font-weight-regular">You've successfully reset your password !</p>
                        <p class="text-h6 font-weight-regular">Now you can go ahead and log in into your account.</p>

                        <Button :color="ada.darkGrey" class="mt-4" @click="$router.push({name: 'home'})">Go to
                            Homepage
                        </Button>
                    </div>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" :style="{height: contentHeight}">
                    <img class="svg" src="../assets/025-drawkit-server-woman-colour.svg">
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Button from "../components/buttons/Button";

export default {
    name: "ResetPasswordSuccess",
    components: {Button},
    data: () => ({
        ada
    }),
    computed: {
        contentHeight() {
            return this.$vuetify.breakpoint.smAndUp ? ada.md_contentHeight : ada.sm_contentHeight;
        }
    },
}
</script>

<style scoped>
.svg {
    height: 95vh;
    width: 45vw;
    object-fit: contain;
    position: absolute;
    top: -10vh;
}
</style>