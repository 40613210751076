<template>
    <div>
        <h2 class="header">Overview</h2>

        <!-- REGISTERED VENDORS -->
        <v-tabs v-model="vendor_activeTab" :color="ada.blue" height="55" show-arrows class="my-10 mt-4">
            <v-tabs-slider :color="ada.blue"></v-tabs-slider>
            <v-tab>Vendors</v-tab>
            <v-tab>
                <v-badge
                    :value="$store.state.agency_vendor.inactiveVendors.length !== 0"
                    color="red"
                    transition="scale-transition"
                    :content="$store.state.agency_vendor.inactiveVendors.length"
                >
                    Inactive Vendors
                </v-badge>
            </v-tab>
            <v-tab>History</v-tab>
            <v-tabs-items v-model="vendor_activeTab">
                <v-tab-item>
                    <VendorsTable @click:edit="openDetailsDialog($event, 'vendor')"
                                  @click:export="exportTable('vendor')"></VendorsTable>
                </v-tab-item>
                <v-tab-item eager>
                    <InactiveVendorsTable></InactiveVendorsTable>
                </v-tab-item>
                <v-tab-item eager>
                    <HistoryTable type="vendor"></HistoryTable>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>

        <!-- REGISTERED AGENCIES -->
        <v-tabs v-model="agency_activeTab" :color="ada.blue" height="55" show-arrows class="my-10">
            <v-tabs-slider :color="ada.blue"></v-tabs-slider>
            <v-tab>Agencies</v-tab>
            <v-tab>History</v-tab>
            <v-tabs-items v-model="agency_activeTab">
                <v-tab-item>
                    <AgencyTable @click:edit="openDetailsDialog($event, 'agency')"
                                 @click:export="exportTable('agency')"></AgencyTable>
                </v-tab-item>
                <v-tab-item eager>
                    <HistoryTable type="agency"></HistoryTable>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>

        <!-- REGISTERED RELATIONS -->
        <RelationsTable :relations="relations" :addAgencyCol="true" :showExelExport="true"
                        @click:export-agency="exportTable('relation_agency')"
                        @click:export-vendor="exportTable('relation_vendor')"
                        item-key="null"></RelationsTable>
        <DetailsDialog v-model="showDetailsDialog" :data="$store.state.agency_vendor.selectedData"></DetailsDialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import VendorsTable from "./VendorsTable";
import DetailsDialog from "./DetailsDialog";
import HistoryTable from "./HistoryTable";
import AgencyTable from "./AgencyTable";
import RelationsTable from "./RelationsTable";
import InactiveVendorsTable from "./InactiveVendorsTable";

export default {
    name: "Admin_Index",
    components: {InactiveVendorsTable, RelationsTable, AgencyTable, HistoryTable, DetailsDialog, VendorsTable},
    data: () => ({
        ada,
        showDetailsDialog: false,
        vendor_activeTab: 0,
        agency_activeTab: 0,
    }),
    mounted() {
        if (this.getParameterByName('session')) {
            //eslint-disable-next-line no-undef
            sso.saveSsoUser();
            if (this.getParameterByName('mail_alias')) {
                // eslint-disable-next-line no-undef
                let user = sso.getSsoUser();
                user.mail_alias = this.getParameterByName('mail_alias');
                localStorage.setItem('ssoUser', JSON.stringify(user));
            }
            location.href = "/#/admin"
        } else {
            // eslint-disable-next-line no-undef
            sso.init({
                logoutEndpoint: "https://sso.adalliance.io/api/users/globalsession/logout"
            });
            // eslint-disable-next-line no-undef
            sso.saveSsoUser();
        }
        this.init();
    },
    computed: {
        data() {
            return this.$store.state.agency_vendor.data;
        },
        relations() {
            return this.$store.state.agency_vendor.agencies ?
                [].concat.apply([], this.$store.state.agency_vendor.agencies.map(x => x.vendors)) : [];
        },
        vendorHistory() {
            return this.history ? this.history.filter(x => x.vendor !== null) : [];
        },
        agencyHistory() {
            return this.history ? this.history.filter(x => x.agency !== null) : [];
        }
    },
    methods: {
        init() {
            this.$store.dispatch('users/getCurrentUser');
            this.$store.dispatch('checkSSOId')
        },
        getParameterByName(name, url) {
            if (!url) url = window.location.href;
            // eslint-disable-next-line no-useless-escape
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        openDetailsDialog(eventData, role) {
            //to save the original state
            this.$store.commit('agency_vendor/setSelectedData', {data: eventData, role: role});
            this.showDetailsDialog = true;
        },
        async exportTable(table) {
            let action = null;
            let filename = 'Export.xlsx';
            if (table === 'vendor') {
                filename = 'Vendor_export_';
                action = 'agency_vendor/exportVendors';
            } else if (table === 'agency') {
                filename = 'Agency_export_';
                action = 'agency_vendor/exportAgencies';
            } else if (table === 'relation_agency') {
                filename = 'Agency_relation_export_';
                action = 'agency_vendor/exportAgencyRelations';
            } else if (table === 'relation_vendor') {
                filename = 'Vendor_relation_export_';
                action = 'agency_vendor/exportVendorRelations';
            }
            if (action) {
                const today = new Date();
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // Months start at 0!
                let dd = today.getDate();
                const data = await this.$store.dispatch(action);
                let blob = new Blob([data], {type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename + yyyy + '_' + mm + '_' + dd + '.xlsx';
                link.click();
            }
        }
    },
    watch: {
        showDetailsDialog(newValue) {
            if (!newValue) {
                this.$store.commit('agency_vendor/setSelectedData', null);
            }
        }
    },
}
</script>

<style lang="scss" scoped>

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: rgba(236, 238, 240, 0.50);
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .actionIcon {
    cursor: pointer;
    color: $ada_darkGrey !important;
    opacity: 0.6;
}

::v-deep .v-data-table__progress th {
    padding: 0 !important;
}
</style>