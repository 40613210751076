<template>
    <div>
        <Datatable :headers="headers" :items="items" title="Data Processing"
                   item-slot="item.is_template" item-slot2="item.is_special"
                   :loading="$store.state.details.fetching.dataTypes"
                   @click:row="openEditDialog($event)"
                   @click:edit="openEditDialog($event)"
                   @click:delete="openEditDialog($event, 'delete')"
        >
            <template #top-middle>
                <div class="d-flex ml-6">
                    <v-checkbox v-for="item in filter" :key="item.value"
                                v-model="selectedFilter" :value="item.value"
                                :label="item.text" class="mr-8"
                                :color="ada.pink"
                    >
                    </v-checkbox>
                </div>
            </template>
            <template #top>
                <Button icon="mdi-plus" :color="ada.blue" @click="openEditDialog(null)">Add Data Processing</Button>
            </template>
            <template #item.is_template="item">
                <v-icon :color="item.is_template === 1 ? 'green' : ada.grey"
                >
                    {{ item.is_template === 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
            <template #item.is_special="item">
                <v-icon :color="item.is_special === 1 ? 'green' : ada.grey"
                >
                    {{ item.is_special === 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
        </Datatable>

        <Dialog v-model="showEditDialog" :title="selected.id ? 'Edit Data Processing' : 'Add Data Processing'"
                :loading="loading.submit"
                @ok="submit"
                @cancel="showEditDialog = false">
            <v-form v-model="valid" ref="form">
                <v-switch v-model="selected.is_template" :true-value="1" :false-value="0" :color="ada.pink"
                          hide-details="auto">
                    <template #label>
                        <div class="mr-2">Is a template</div>
                        <TooltipIcon :color="ada.grey"
                                     tooltip="Templates will be shown as a default dropdown option">
                            mdi-information
                        </TooltipIcon>
                    </template>
                </v-switch>
                <v-switch v-model="selected.is_special" :true-value="1" :false-value="0" :color="ada.pink" class="mt-2">
                    <template #label>
                        <div class="mr-2">Is a special data category</div>
                        <TooltipIcon :color="ada.grey"
                                     tooltip="If this is checked, it will be shown under the <br>'Do you have any special categories ?' dropdown">
                            mdi-information
                        </TooltipIcon>
                    </template>
                </v-switch>
                <Input label="Name" v-model="selected.name" @enter="submit"></Input>
            </v-form>
        </Dialog>

        <!-- CONFIRM DELETE DIALOG-->
        <Dialog v-model="showDeleteDialog" title="Delete data processing" ok-text="Yes, Delete"
                :loading="loading.delete" width="400px" min-width="400px"
                @ok="deleteDataType" @cancel="showDeleteDialog = false">
            <p>Are you sure that you want to delete the following data processing ?</p>
            <p class="d-flex justify-center"><b>{{ selected ? selected.name : '' }}</b></p>
        </Dialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Datatable from "../../../components/Tables/Datatable";
import Button from "../../../components/buttons/Button";
import TooltipIcon from "../../../components/buttons/TooltipIcon";
import Input from "../../../components/Inputs/Input";
import Dialog from "../../../components/Dialog";

export default {
    name: "Settings_DataTypes",
    components: {Dialog, Input, TooltipIcon, Button, Datatable},
    data: () => ({
        ada,
        headers: [
            {text: 'Is template', value: 'is_template', width: '120px', align: 'center'},
            {text: 'Name', value: 'name', width: '40%'},
            {text: 'Special Category', value: 'is_special', width: '30%'},
            {text: 'Created at', value: 'created_at', width: '200px'},
            {text: 'Actions', value: 'action', width: '100px'},
        ],
        valid: null,
        showEditDialog: false,
        showDeleteDialog: false,
        selected: {
            name: null,
            is_template: 1,
            is_special: 0,
        },
        loading: {
            submit: false,
            delete: false,
        },
        filter: [
            //{text: 'Template', value: {prop: 'is_template', value: 1}},
            //{text: 'No template', value: {prop: 'is_template', value: 0}},
            {text: 'Special Category', value: 1},
        ],
        selectedFilter: [],
    }),
    mounted() {
        this.init()
    },
    computed: {
        items() {
            let ret = [];
            let options = {year: 'numeric', month: '2-digit', day: '2-digit'};
            this.$store.state.details.data_types.forEach((item) => {
                item.createdFormatted = new Date(item.created_at).toLocaleDateString('de-DE', options);
                item.action = ['edit', 'delete'];
                ret.push(item);
            });
            return this.selectedFilter.length !== 0 ? ret.filter(x => this.selectedFilter.includes(x.is_special)) : ret;
        },
        error() {
            return this.$store.state.errorCount;
        }
    },
    methods: {
        init() {
            if (this.items.length === 0) this.fetch();
        },
        async fetch() {
            await this.$store.dispatch('details/fetchDataTypes');
        },
        async submit() {
            await this.$refs.form.validate();
            if (this.valid) {
                this.loading.submit = true;
                await this.$store.dispatch('details/updateDataType', this.selected).then((res) => {
                    if (res && res !== "") {
                        this.$root.$emit('snackbar', 'Successfully saved');
                        this.showEditDialog = false;
                    }
                })
                this.loading.submit = false;
            }
        },
        async deleteDataType() {
            this.loading.delete = true;
            await this.$store.dispatch('details/deleteDataType', this.selected.id).then((res) => {
                if (res && res !== "") {
                    this.$root.$emit('snackbar', 'Successfully deleted <b>' + this.selected.name + '</b>');
                    if (this.showEditDialog) this.showEditDialog = false;
                    this.showDeleteDialog = false;
                }
            })
            this.loading.delete = false;
        },
        openEditDialog(data, type = 'edit') {
            if (data) this.selected = JSON.parse(JSON.stringify(data));
            if (type === 'delete') this.showDeleteDialog = true;
            if (type === 'edit') this.showEditDialog = true;
        }
    },
    watch: {
        showEditDialog(v) {
            if (!v) {
                this.selected = {
                    name: null,
                    is_template: 1,
                    is_special: 0,
                };
            }
        },
        showDeleteDialog(v) {
            if (!v) {
                this.selected = {
                    name: null,
                    is_template: 1,
                    is_special: 0,
                };
            }
        },
        error() {
            if (this.loading.submit) this.loading.submit = false;
            if (this.loading.delete) this.loading.delete = false;
        }
    }
}
</script>

<style scoped>

</style>