import Repository from "../Repository";

const resource = "/details";
export default {
    getServices() {
        return Repository.get(`services`);
    },
    getPurposes() {
        return Repository.get(`purposes`);
    },
    getDataTypes() {
        return Repository.get(`dataTypes`);
    },
    save(json) {
        return Repository.post(`${resource}`, json);
    },
    update(id, json) {
        return Repository.put(`${resource}/${id}`, json);
    },
    createService(json) {
        return Repository.post(`services`, json);
    },
    updateService(id, json) {
        return Repository.put(`services/${id}`, json);
    },
    deleteService(id) {
        return Repository.delete(`services/${id}`);
    },
    createPurpose(json) {
        return Repository.post(`purposes`, json);
    },
    updatePurpose(id, json) {
        return Repository.put(`purposes/${id}`, json);
    },
    deletePurpose(id) {
        return Repository.delete(`purposes/${id}`);
    },
    createDataType(json) {
        return Repository.post(`dataTypes`, json);
    },
    updateDataType(id, json) {
        return Repository.put(`dataTypes/${id}`, json);
    },
    deleteDataType(id) {
        return Repository.delete(`dataTypes/${id}`);
    },
    fetchGVL() {
        return Repository.post(`gvl`);
    },
    getGVL() {
        return Repository.get(`gvl`);
    },
    getGVLLastModified() {
        return Repository.get(`gvl/time`);
    },
    uploadJCAs(json) {
        return Repository.post(`jca/upload`, json, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}