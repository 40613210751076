<template>
    <v-tooltip :bottom="position === 'bottom'" :top="position === 'top'" :right="position === 'right'"
               :left="position === 'left'">
        <template v-slot:activator="{ on }">
            <v-btn :style="customStyle" :disabled="disabled" :fab="fab" :small="fab && !xsmall" :elevation='0'
                   :x-small="xsmall" :icon="!fab" v-bind="$attrs" v-on="on" @click="$emit('click')" :to="to"
                   :color="color" :loading="loading" :dark="dark" :ripple="ripple">
                <v-icon :class="{'white--text': fab}" v-bind="$attrs">
                    <slot></slot>
                </v-icon>
            </v-btn>
        </template>
        <span v-html="tooltip"></span>
    </v-tooltip>
</template>

<script>
export default {
    name: "TooltipIcon",
    props: {
        'tooltip': {
            type: String,
            required: true,
        },
        to: {
            default: null
        },
        color: {
            default: null
        },
        position: {
            type: String,
            default: 'top'
        },
        fab: {
            type: Boolean,
            default: false
        },
        xsmall: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        customStyle: {
            type: [String, Object]
        },
        dark: {
            type: Boolean,
            default: false
        },
        ripple: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped>
</style>