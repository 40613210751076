<template>
    <div class="container">
        <div class="sectionHeadline text-h6 mb-4">Privacy protection</div>
        <v-form ref="form" v-model="valid">
            <v-row>
                <v-col cols="12">
                    <div class="text-body-1 font-weight-medium">Company domicile</div>
                    <v-radio-group v-model="data.privacy.is_eu" row @change="handleChange"
                                   :disabled="$store.state.readOnly === 1 || !allowEdit">
                        <v-radio :color="ada.pink" class="mr-8"
                                 label="EU"
                                 :value="1"
                        ></v-radio>
                        <v-radio :color="ada.pink"
                                 label="Non-EU"
                                 :value="0"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-slide-y-transition hide-on-leave>
                <div v-show="data.privacy.is_eu === 1">
                    <v-row>
                        <v-col cols="12">
                            <div class="text-body-1 font-weight-medium">Do you transfer data to non-EU countries?</div>
                            <v-radio-group v-model="data.privacy.non_eu_transfer" row @change="handleChange"
                                           class="mt-1" :disabled="$store.state.readOnly === 1 || !allowEdit">
                                <v-radio :color="ada.pink" class="mr-8" label="Yes" :value="1"></v-radio>
                                <v-radio :color="ada.pink" label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-slide-y-transition>
                        <v-row v-show="data.privacy.non_eu_transfer === 1" no-gutters>
                            <v-col>
                                <div class="text-body-1 font-weight-medium">Data transfer
                                    <TooltipIcon position="right"
                                                 tooltip="
                                                 DPF - Data Privacy Framework<br>
                                                 SCC - Standard Contractual Clauses<br>
                                                 TIA - Transfer Impact Assessment<br><br>
                                                 We reserve the right to request the document(s) from you"
                                                 :color="ada.grey">mdi-information
                                    </TooltipIcon>
                                </div>
                                <v-radio-group v-model="data.privacy.data_transfer" row @change="handleChange"
                                               :disabled="$store.state.readOnly === 1 || !allowEdit" class="mt-0"
                                               :rules="data.privacy.is_eu === 0 || data.privacy.non_eu_transfer === 1 ? rules.data_transfer : []">
                                    <v-radio :color="ada.pink" class="mr-2" label="DPF" value="DPF"></v-radio>
                                    <v-icon class="mr-8" style="font-size:1em;cursor: pointer; width:1.5em;"
                                            :color="ada.blue"
                                            @click="openDPFLink">
                                        mdi-open-in-new
                                    </v-icon>
                                    <v-radio :color="ada.pink" class="mr-8" label="SCC" value="SCC"></v-radio>
                                    <v-radio :color="ada.pink" label="SCC + TIA" value="TIA"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-slide-y-transition>
                    <div class="text-body-1 font-weight-medium mt-8 mb-4">EU Companies -- Privacy Protection Contact
                    </div>
                    <v-row>
                        <v-col cols="6">
                            <Input label="Privacy Officer" v-model.trim="data.privacy.name" :rules="rules.name"
                                   :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-account"
                                   @blur="handleChange"></Input>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pr-3">
                            <Input label="Email or URL" v-model.trim="data.privacy.email" :rules="rules.email"
                                   :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-email"
                                   @blur="handleChange"></Input>
                        </v-col>
                        <v-col cols="6" class="pl-3">
                            <Input label="Phone No." v-model.trim="data.privacy.phone" :rules="rules.phone"
                                   :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-phone"
                                   @blur="handleChange"></Input>
                        </v-col>
                    </v-row>
                </div>
            </v-slide-y-transition>
            <v-slide-y-transition hide-on-leave>
                <div v-show="data.privacy.is_eu === 0">
                    <v-row no-gutters>
                        <v-col>
                            <div class="text-body-1 font-weight-medium">Data transfer
                                <TooltipIcon position="right"
                                             tooltip="
                                                 DPF - Data Privacy Framework<br>
                                                 SCC - Standard Contractual Clauses<br>
                                                 TIA - Transfer Impact Assessment<br><br>
                                                 We reserve the right to request the document(s) from you"
                                             :color="ada.grey">mdi-information
                                </TooltipIcon>
                            </div>
                            <v-radio-group v-model="data.privacy.data_transfer" row @change="handleChange"
                                           :disabled="$store.state.readOnly === 1 || !allowEdit" class="mt-0"
                                           :rules="data.privacy.is_eu === 0 || data.privacy.non_eu_transfer === 1 ? rules.data_transfer : []">
                                <v-radio :color="ada.pink" class="mr-2" label="DPF" value="DPF"></v-radio>
                                <v-icon class="mr-8" style="font-size:1em;cursor: pointer; width:1.5em;"
                                        :color="ada.blue"
                                        @click="openDPFLink" no-ripple>
                                    mdi-open-in-new
                                </v-icon>
                                <v-radio :color="ada.pink" class="mr-8" label="SCC" value="SCC"></v-radio>
                                <v-radio :color="ada.pink" label="SCC + TIA" value="TIA"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <div class="text-body-1 font-weight-medium mt-8">Non-EU Companies -- Privacy Protection Contact
                    </div>
                    <div class="text-body-2 font-weight-medium mt-1 mb-4">EU representative according to Art. 27 GDPR
                    </div>
                    <v-row>
                        <v-col cols="6">
                            <Input label="Name" v-model.trim="data.privacy.name" :rules="rules.name"
                                   :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-account"
                                   @blur="handleChange"></Input>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pr-3">
                            <Input label="Email or URL" v-model.trim="data.privacy.email" :rules="rules.email"
                                   :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-email"
                                   @blur="handleChange"></Input>
                        </v-col>
                        <v-col cols="6" class="pl-3">
                            <Input label="Phone No." v-model.trim="data.privacy.phone" :rules="rules.phone"
                                   :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-phone"
                                   @blur="handleChange"></Input>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <div class="text-body-1 font-weight-medium mt-4">Same company?</div>
                            <v-radio-group v-model="same_company" row @change="handleChange" class="mt-1"
                                           :disabled="$store.state.readOnly === 1 || !allowEdit">
                                <v-radio :color="ada.pink" class="mr-8" label="Yes" :value="1"></v-radio>
                                <v-radio :color="ada.pink" label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-slide-y-transition>
                        <div v-show="same_company === 0">
                            <div class="text-body-1 mt-4 mb-2 font-weight-medium">Please provide an address</div>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <Input label="Street + No." v-model.trim="data.privacy.street"
                                           :disabled="$store.state.readOnly === 1 || !allowEdit" append-icon="mdi-home"
                                           :rules="isNotSameCompany ? rules.street : []" @blur="handleChange"></Input>
                                </v-col>
                                <v-col cols="6" class="pr-3">
                                    <Input label="Zip / Postal Code" v-model.trim.number="data.privacy.zip"
                                           :disabled="$store.state.readOnly === 1 || !allowEdit"
                                           append-icon="mdi-map-marker"
                                           :rules="isNotSameCompany ? rules.zip : []" @blur="handleChange"></Input>
                                </v-col>
                                <v-col cols="6" class="pl-3">
                                    <Input label="City" v-model.trim="data.privacy.city" append-icon="mdi-map"
                                           :disabled="$store.state.readOnly === 1 || !allowEdit"
                                           :rules="isNotSameCompany ? rules.city : []" @blur="handleChange"></Input>
                                </v-col>
                                <v-col cols="12">
                                    <Dropdown label="Country" v-model.trim="data.privacy.country" :return-object="false"
                                              :disabled="$store.state.readOnly === 1 || !allowEdit"
                                              append-icon="mdi-earth"
                                              :rules="isNotSameCompany ? rules.country : []" @change="handleChange"
                                              :items="countries.map(x=> ({'text': x.name.common, 'value':x.name.common}))"></Dropdown>
                                </v-col>
                            </v-row>
                        </div>
                    </v-slide-y-transition>
                </div>
            </v-slide-y-transition>
        </v-form>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import {countries} from '@/scripts/countries.js';
import Input from "../../../components/Inputs/Input";
import Dropdown from "../../../components/Inputs/Dropdown";
import Utils from "../../../scripts/Utils";
import TooltipIcon from "../../../components/buttons/TooltipIcon";

export default {
    name: "Privacy",
    props: ['data', 'tabIndex', 'previousIndex', 'tabs', 'allowEdit'],
    components: {TooltipIcon, Dropdown, Input},
    data: () => ({
        valid: null,
        same_company: 1,
        urlRegex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
        ada,
        countries
    }),
    mounted() {
        this.init();
        this.$emit('mounted', this);
    },
    computed: {
        rules() {
            return {
                name: [v => !!v || 'Name is required',
                    v => (v && v.length <= 255) || 'Name must be less than 255 characters'],
                data_transfer: [v => v !== null && !!v || 'Data transfer is required'],
                phone: [v => !!v || 'Phone is required',
                    v => v && v.length > 6 || 'Phone no must be longer than 6 digits',
                    v => /(\(?([\d \-)–+/(]+){6,}\)?([ .\-–/]?)([\d]+))/.test(v) || 'Please enter valid phone number'],
                email: [v => !!v || 'Email or URL is required',
                    v => (Utils.validEmail(v) || this.urlRegex.test(v)) || 'Please enter a valid email or URL',
                    v => (v && v.length <= 255) || 'Email or URL must be less than 255 characters'
                ],
                street: [v => !!v || 'Street is required',
                    v => /\d/.test(v) || 'Street number missing',
                    v => (v && v.length <= 255) || 'Street name must be less than 255 characters'],
                zip: [v => !!v || 'Zip / PostalCode is required',
                    v => /^[a-zA-Z0-9]+$/.test(v) || 'Zip / Postal Code must only contain letters or numbers'],
                city: [v => !!v || 'City is required',
                    v => (v && v.length <= 100) || 'City must be less than 100 characters'],
                country: [v => !!v || 'Country is required',
                    v => (v && v.length <= 255) || 'Country must be less than 255 characters'],
            }
        },
        isNotSameCompany() {
            return this.data.privacy.is_eu === 0 && this.same_company === 0;
        },
        hasChanges() {
            return this.$store.state.details.components.privacy.hasChanges;
        },
        role() {
            return this.$store.state.users.current ? this.$store.state.users.current.role : null;
        },
        allReqFieldsNotFilled() {
            return (
                this.data.privacy.name === null &&
                this.data.privacy.email === null &&
                this.data.privacy.street === null &&
                this.data.privacy.zip === null &&
                this.data.privacy.city === null &&
                this.data.privacy.data_transfer === null &&
                this.data.privacy.phone === null &&
                this.data.privacy.country === null
            )
        }
    },
    methods: {
        init() {
            this.same_company = this.data.privacy.street !== null ? 0 : 1;
        },
        handleChange() {
            let role = this.role === 'agency_group' ? 'agency' : this.role;
            let og_data = this.role === 'admin' || this.role === 'user' ?
                this.$store.state.agency_vendor.selectedData.details :
                this.$store.state.details.data[role].details;
            if (og_data === null) og_data = JSON.parse(JSON.stringify(this.$store.state.details.details_template));
            if (og_data.privacy === null) og_data.privacy = JSON.parse(JSON.stringify(this.$store.state.details.details_template.privacy));
            if (og_data.privacy.is_eu !== this.data.privacy.is_eu ||
                og_data.privacy.non_eu_transfer !== this.data.privacy.non_eu_transfer ||
                og_data.privacy.name !== this.data.privacy.name ||
                og_data.privacy.email !== this.data.privacy.email ||
                og_data.privacy.data_transfer !== this.data.privacy.data_transfer ||
                og_data.privacy.street !== this.data.privacy.street ||
                og_data.privacy.zip !== this.data.privacy.zip ||
                og_data.privacy.city !== this.data.privacy.city ||
                og_data.privacy.phone !== this.data.privacy.phone ||
                og_data.privacy.country !== this.data.privacy.country
            ) {
                if (!this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'privacy', value: true});
            } else {
                if (this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'privacy', value: false});
            }
        },
        openDPFLink() {
            window.open('https://www.dataprivacyframework.gov/s/', '_blank').focus();
        }
    },
    watch: {
        same_company(v) {
            if (v === 1) {
                this.$refs.form.resetValidation();
                this.data.privacy.street = null;
                this.data.privacy.zip = null;
                this.data.privacy.city = null;
                this.data.privacy.country = null;
                this.data.privacy.data_transfer = null;
            }
        },
        data() {
            this.init();
            if (!this.$store.state.details.isRegistration) {
                setTimeout(() => {
                    this.$emit('validate', 'Privacy');
                    //this.validate();
                }, 500);
            }
        },
        valid() {
            this.$emit('validate', 'Privacy', true);
        }
    }
}
</script>

<style scoped>

</style>