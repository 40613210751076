<template>
    <div>
        <!-- USERS TABLE -->
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search_data"
            :loading="$store.state.users.loading.users"
            no-data-text="No Data"
            class="pointer"
            no-results-text="No Data"
            @click:row="openActionDialog($event)"
        >
            <template v-slot:progress>
                <v-progress-linear indeterminate :color="ada.pink" class="d-flex" height="3"></v-progress-linear>
            </template>
            <template v-slot:top>
                <div class="d-flex align-center justify-space-between mx-4">
                    <div class="d-flex align-center flex-row">
                        <div class="pr-6 text-h6 font-weight-regular">Users</div>
                        <v-text-field
                            v-model="search_data"
                            label="Search..."
                            append-icon="mdi-magnify"
                            class="mx-4 pt-5"
                            :color="ada.blue"
                            :style="{width: $vuetify.breakpoint.mdAndUp ? '300px' : '200px'}"
                        ></v-text-field>
                        <div class="d-flex ml-6">
                            <v-checkbox v-for="role in roles" :key="role.value"
                                        v-model="selectedRoles" :value="role.value"
                                        :label="role.text" class="mr-8"
                                        :color="ada.pink"
                            >
                            </v-checkbox>
                        </div>
                    </div>
                    <div>
                        <Button :color="ada.blue" icon="mdi-plus" @click="openActionDialog(null)">Add User</Button>
                    </div>
                </div>
            </template>
            <template v-slot:item.created_at="{ on, item }">
                <div>{{ item.createdFormatted }}</div>
            </template>
            <template v-slot:item.action="{ on, item }">
                <v-tooltip top v-for="(action, i) in item.action" :key="'action_'+i">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="actionIcon"
                            :data-type="action" :color="ada.grey"
                            @click.stop="openActionDialog(item, action)">
                            {{ getAction(action).icon }}
                        </v-icon>
                    </template>
                    <span>{{ getAction(action).tooltip }}</span>
                </v-tooltip>
            </template>
            <template #[col.name]="{item}"
                      v-for="col in [
                          {name:'item.receive_verify_mail', value:'receive_verify_mail'},
                          {name:'item.reminder_mail_sent', value:'reminder_mail_sent'},
                          {name:'item.warning_mail_sent', value:'warning_mail_sent'}]">
                <v-icon :color="item[col.value] === 1 ? 'green' : ada.grey " :key="col.value">
                    {{ item[col.value] === 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
        </v-data-table>

        <!-- ADD / EDIT USER DIALOG -->
        <Dialog v-model="showEditDialog" :title="selected && selected.id ? 'Edit User' : 'Add new user'"
                :loading="loading.submit" :alignBtnsLeft="selected && typeof selected.id !== 'undefined'"
                @ok="submit" @cancel="showEditDialog = false">
            <v-form v-if="selected" v-model="valid" ref="form">
                <v-switch v-model="ssoUser" v-if="selected && typeof selected.id === 'undefined'" label="SSO user"
                          :color="ada.pink"></v-switch>
                <Input v-if="selected.sso_email !== null" label="SSO Email" v-model="selected.sso_email"
                ></Input>
                <Input v-else label="Email" v-model="selected.email" :rules="rules.email"></Input>
                <Input v-if="typeof selected.id === 'undefined' && !this.ssoUser"
                       label="Password" v-model="selected.password" :rules="rules.password"
                       hint="Please write this down before you 'save'. You won't have access to the password after this dialog closes."
                >
                    <template #append-outer>
                        <div style="position:relative; top:-5px; display: flex;">
                            <TooltipIcon xsmall tooltip="Generate new password" fab :color="ada.pink"
                                         @click="generatePassword" customStyle="margin-right:5px;">
                                mdi-reload
                            </TooltipIcon>
                            <TooltipIcon xsmall tooltip=" Copy password
                            " fab :color="ada.darkGrey"
                                         @click="copyTextToClipboard(selected.password)">
                                mdi-content-copy
                            </TooltipIcon>
                        </div>
                    </template>
                </Input>
                <Dropdown label="Role" v-model="selected.role" :items="roles" :return-object="false"
                          item-value="value" :rules="rules.role"></Dropdown>
                <Dropdown v-if="selected.role === 'vendor'" clearable
                          v-model="selected.vendor_id" item-value="id"
                          :label="roles.find(x => x.value === selected.role).text"
                          :loading="loading.vendors" :return-object="false"
                          :rules="selected.role === 'vendor' ? rules.vendor : []"
                          :items="vendors ? vendors : []" item-text="name"></Dropdown>
                <Dropdown v-else-if="isAgency"
                          v-model="selected.agency_id" item-value="id" clearable
                          :label="roles.find(x => x.value === selected.role).text"
                          :rules="isAgency ? rules.agency : []"
                          :loading="loading.agencies" :return-object="false"
                          :items="agencies ? (selected.role === 'agency_group' ? agencies.filter(x => x.is_group === 1) : agencies.filter(x => x.is_group === 0)) : []"
                          item-text="name"></Dropdown>
                <v-row v-if="selected.role === 'admin' || selected.role === 'user'">
                    <v-col>
                        <v-checkbox v-model="selected.receive_verify_mail" :false-value="0" :true-value="1"
                                    class="ma-0" :color="ada.pink">
                            <template #label>
                                <div class="mr-2">Receive verify request mails</div>
                                <TooltipIcon :color="ada.grey"
                                             tooltip="Verify request mails are mails that are sent when a vendor
                                                        <br>has completed the registration and clicks on 'verify' <br>
                                                        thus requesting to be verified. <br><br> This mail is also
                                                        always sent to consent@ad-alliance.de">
                                    mdi-information
                                </TooltipIcon>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
            <template #button v-if="selected && typeof selected.id !== 'undefined'">
                <div v-if="selected ? !selected.sso_email : false" class="mr-2 mb-2">
                    <TooltipIcon xsmall tooltip="Reset password" fab :color="ada.blue"
                                 @click="openActionDialog('skip', 'resetPassword')"> mdi-lock-reset
                    </TooltipIcon>
                </div>
                <div v-if="selected ? selected.email_verified_at === null && !selected.sso_email : false"
                     class="mr-2 mb-2">
                    <TooltipIcon xsmall tooltip="Resend verify email" fab :color="ada.purple"
                                 @click="openActionDialog('skip', 'resend')"> mdi-email-sync
                    </TooltipIcon>
                </div>
                <div class="mb-2">
                    <TooltipIcon tooltip="Delete user" fab :color="ada.red" xsmall
                                 @click="openActionDialog('skip', 'delete')"> mdi-delete
                    </TooltipIcon>
                </div>
                <v-spacer></v-spacer>
            </template>
        </Dialog>

        <!-- RESET PASSWORD DIALOG-->
        <Dialog v-model="showResetPasswordDialog" title="Reset password" ok-text="Send Email"
                :loading="loading.resetPassword" @cancel="showResetPasswordDialog = false"
                @ok="resetPassword" width="400px" min-width="400px">
            <p>Send an email to reset the password to <br><b>{{ selected ? selected.email : null }}</b> ?</p>
        </Dialog>

        <!-- RESEND VERIFY MAIL DIALOG-->
        <Dialog v-model="showResendDialog" title="Resend verify mail" ok-text="Send Email"
                :loading="loading.resend" @cancel="showResendDialog = false"
                @ok="resendVerifyMail" width="400px" min-width="400px">
            <p style="word-break: break-word">Resend the email to verify the email address to <br>
                <b>{{ selected ? selected.email : null }}</b> ?</p>
            <v-divider class="my-6"></v-divider>

            <span :style="'color:purple;margin-right:2px;'">OR </span> manually verify that it's a real and trustworthy
            email.

            <Button outlined class="mt-2" @click="manuallyVerifyMail" color="purple" :loading="loading.manualVerify">
                Verify email NOW
            </Button>
        </Dialog>

        <!-- CONFIRM DELETE USER DIALOG-->
        <Dialog v-model="showDeleteDialog" title="Delete user" ok-text="Yes, Delete" :loading="loading.delete"
                @ok="deleteUser" @cancel="showDeleteDialog = false"
                width="450px"
        >
            <p>Are you sure that you want to delete the following user ?</p>
            <p class="d-flex justify-center"><b>{{ selected ? (selected.email || selected.sso_email) : '' }}</b></p>
            <v-alert type="error" text dense style="font-size: 14px; margin-bottom: 0;">Warning! This action cannot be
                undone ! <br><br>
                <div v-if="selected && selected.name">
                    If this is the only registered user of <b>{{ selected ? selected.name : null }}</b>,
                    the company data will be deleted too. Signed JCA's won't be deleted.
                </div>
            </v-alert>
        </Dialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Button from "../../../components/buttons/Button";
import Dialog from "../../../components/Dialog";
import Dropdown from "../../../components/Inputs/Dropdown";
import Input from "../../../components/Inputs/Input";
import TooltipIcon from "../../../components/buttons/TooltipIcon";
import Utils from "../../../scripts/Utils";


export default {
    name: "Settings_Users",
    components: {TooltipIcon, Input, Dropdown, Dialog, Button},
    data: () => ({
        ada,
        valid: null,
        headers: [
            {text: 'Role', value: "role", width: '120px'},
            {text: 'Verify Mail', value: "receive_verify_mail", align: 'center', width: '120px'},
            {text: 'User', value: "mail"},
            {text: 'Company', value: "name"},
            {text: 'Email verified', value: "emailVerifiedAt", align: 'center', width: '130px'},
            {text: '1st Reminder', value: "reminder_mail_sent", align: 'center', width: '130px'},
            {text: '2nd Reminder', value: "warning_mail_sent", align: 'center', width: '130px'},
            {text: 'Created at', value: "created_at", width: '120px'},
            {text: 'Actions', value: "action", align: 'center', sortable: false, width: '140px'},
        ],
        roles: [
            {text: 'Admin', value: 'admin'},
            {text: 'User', value: 'user'},
            {text: 'Vendor', value: 'vendor'},
            {text: 'Agency', value: 'agency'},
            {text: 'Agency group', value: 'agency_group'},
        ],
        vendors: null,
        agencies: null,
        selected: null,
        loading: {
            agencies: false,
            vendors: false,
            delete: false,
            submit: false,
            resetPassword: false,
            resend: false,
            manualVerify: false,
        },
        showEditDialog: false,
        showDeleteDialog: false,
        showResetPasswordDialog: false,
        showResendDialog: false,
        ssoUser: false,
        search_data: null,
        selectedRoles: [],
    }),
    mounted() {
        this.init()
    },
    computed: {
        vuexStore() {
            return this.$store.state.users;
        },
        users() {
            return this.vuexStore.users;
        },
        items() {
            let ret = [];
            if (this.users) {
                let options = {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                };
                this.users.forEach((user) => {
                    let tmp = JSON.parse(JSON.stringify(user));
                    tmp.mail = user.email || user.sso_email;
                    tmp.name = tmp.role === 'vendor' ? tmp.vendor.name : (tmp.role === 'agency' ? tmp.agency.name : null);
                    tmp.createdFormatted = new Date(tmp.created_at).toLocaleDateString('de-DE', options);
                    tmp.emailVerifiedAt = tmp.email_verified_at ? new Date(tmp.email_verified_at).toLocaleDateString('de-DE', options) : null;
                    tmp.action = ['edit'];
                    if (!tmp.sso_email) tmp.action.push('resetPassword');
                    if (!tmp.email_verified_at && !tmp.sso_email) tmp.action.push('resend');
                    tmp.action.push('delete');
                    ret.push(tmp);
                });
            }
            return this.selectedRoles.length !== 0 ? ret.filter(x => this.selectedRoles.includes(x.role)) : ret;
        },
        rules() {
            return {
                email: [v => !!v || 'Email is required',
                    v => Utils.validEmail(v) || 'Please enter a valid email'],
                role: [v => !!v || 'Role is required',
                    () => {
                        if ((this.selected.role === 'admin' || this.selected.role === 'user') && !this.ssoUser && typeof this.selected.id === 'undefined') {
                            return `Only SSO users can have this role`;
                        } else {
                            return true;
                        }
                    }
                ],
                vendor: [v => !!v || 'Vendor is required'],
                agency: [v => !!v || 'Agency is required'],
                password: [v => !!v && v.trim() !== "" || 'Password is required'],
            }
        },
        isAgency() {
            return this.selected && this.selected.role ? this.selected.role.indexOf('agency') !== -1 : false;
        },
        error() {
            return this.$store.state.errorCount;
        }
    },
    methods: {
        init() {
            this.fetchUsers();
            this.fetchVendorsAndAgencies()
        },
        async fetchUsers() {
            await this.$store.dispatch('users/fetchAllUsers');
        },
        openActionDialog(data, type = 'edit') {
            if (data !== 'skip') {
                if (data) {
                    this.selected = JSON.parse(JSON.stringify(data));
                } else {
                    this.selected = {
                        role: null,
                        sso_email: null,
                        email: null,
                        receive_verify_mail: null,
                        password: null,
                        is_group: 0,
                    }
                    this.generatePassword();
                }
            }
            if (type === 'edit') this.showEditDialog = true;
            if (type === 'delete') this.showDeleteDialog = true;
            if (type === 'resetPassword') this.showResetPasswordDialog = true;
            if (type === 'resend') this.showResendDialog = true;
        },
        async fetchVendorsAndAgencies() {
            let config = [
                {type: 'vendors', commit: 'agency_vendor/fetchVendors'},
                {type: 'agencies', commit: 'agency_vendor/fetchAgencies'},
            ];
            for (let i = 0; i < config.length; i++) {
                this.loading[config[i].type] = true;
                let data = await this.$store.dispatch(config[i].commit, {returnOnly: true})
                if (data && data !== "") this[config[i].type] = data;
                this.loading[config[i].type] = false;
            }
        },
        async submit() {
            await this.$refs.form.validate()
            if (this.valid) {
                if (typeof this.selected.id === 'undefined') {
                    if (this.selected.role === 'agency_group') this.selected.is_group = 1;
                    if (this.ssoUser) this.selected.sso_email = this.selected.email;
                }
                this.loading.submit = true;
                let res = await this.$store.dispatch('users/updateUser', this.selected)
                this.loading.submit = false;
                if (res && res !== "") {
                    this.$root.$emit('snackbar', 'Successfully saved');
                    if (this.showEditDialog) this.showEditDialog = false;
                }
            } else {
                this.$root.$emit('snackbar', 'Please make sure all fields are filled in correctly', 'warning');
            }
        },
        async deleteUser() {
            this.loading.delete = true;
            let data = await this.$store.dispatch('users/deleteUser', this.selected);
            if (data && data !== "") {
                this.showDeleteDialog = false;
                if (this.showEditDialog) this.showEditDialog = false;
                this.$root.$emit('snackbar', 'Successfully deleted ' + (this.selected.email || this.selected.sso_email));
            }
            this.loading.delete = false;
        },
        async resetPassword() {
            this.loading.resetPassword = true;
            await this.$store.dispatch('forgotPassword', {email: this.selected.email}).then((res) => {
                if (res && res.status) {
                    this.showResetPasswordDialog = false;
                    if (this.showEditDialog) this.showEditDialog = false;
                    this.$root.$emit('snackbar', 'Reset password mail sent');
                }
                this.loading.resetPassword = false;
            }).catch(() => {
                this.loading.resetPassword = false;
            })
        },
        async resendVerifyMail() {
            this.loading.resend = true;
            await this.$store.dispatch('resendVerifyEmail', {email: this.selected.email}).then((res) => {
                if (res && res.status) {
                    this.fetchUsers();
                    this.showResendDialog = false;
                    if (this.showEditDialog) this.showEditDialog = false;
                    this.$root.$emit('snackbar', 'Verify email address mail sent');
                }
            });
            this.loading.resend = false;
        },
        async manuallyVerifyMail() {
            this.loading.manualVerify = true;
            await this.$store.dispatch('manuallyVerifyEmail', {email: this.selected.email}).then((res) => {
                if (res && res.status) {
                    this.showResendDialog = false;
                    if (this.showEditDialog) this.showEditDialog = false;
                    this.$root.$emit('snackbar', 'Email is now successfully verified');
                }
            });
            this.loading.manualVerify = false;
        },
        generatePassword() {
            let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            let passwordLength = 12;
            let password = "";
            for (var i = 0; i <= passwordLength; i++) {
                var randomNumber = Math.floor(Math.random() * chars.length);
                password += chars.substring(randomNumber, randomNumber + 1);
            }
            this.selected.password = password;
        },
        async validate() {
            await this.$refs.form.validate();
        },
        fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                //console.log('Fallback: Copying text command was ' + msg);
                this.$root.$emit('snackbar', 'Copy to clipboard ' + msg);
            } catch (err) {
                this.$root.$emit('snackbar', 'Oops, unable to copy', 'error');
            }
            document.body.removeChild(textArea);
        },
        copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(() => {
                this.$root.$emit('snackbar', 'Copying to clipboard was successful');
            }, (err) => {
                this.$root.$emit('snackbar', 'Could not copy text: ' + err, 'error');
            });
        },
        getAction(action) {
            let ret = {};
            if (action === 'edit') {
                ret.tooltip = 'Edit';
                ret.icon = this.$store.state.readOnly !== 1 ? 'mdi-pencil' : 'mdi-magnify-scan';
            } else if (action === 'delete') {
                ret.tooltip = 'Delete';
                ret.icon = 'mdi-delete';
            } else if (action === 'resetPassword') {
                ret.tooltip = 'Reset Password';
                ret.icon = 'mdi-lock-reset';
            } else if (action === 'resend') {
                ret.tooltip = 'Resend verify email';
                ret.icon = 'mdi-email-sync';
            }
            return ret;
        }
    },
    watch: {
        showEditDialog(v) {
            if (!v) {
                this.selected = null;
                this.ssoUser = false;
            }
        },
        error() {
            if (this.loading.submit) this.loading.submit = false;
            if (this.loading.delete) this.loading.delete = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.actionIcon {
    cursor: pointer;
    color: $ada_darkGrey !important;
    opacity: 0.6;
}

.actionIcon[data-type="resend"] {
    margin-left: 3px;
}

.actionIcon[data-type="edit"]:hover, .actionIcon[data-type="copy"]:hover, .actionIcon[data-type="resetPassword"]:hover, .actionIcon[data-type="resend"]:hover {
    color: $ada_blue !important;
    opacity: 1;
}

.actionIcon[data-type="delete"]:hover {
    color: $ada_red !important;
    opacity: 1;
}

::v-deep .v-data-table__progress th {
    padding: 0 !important;
}

::v-deep tbody tr {
    cursor: pointer;
}
</style>