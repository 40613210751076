<template>
    <div class="container container--fluid d-flex align-center flex-column justify-center" style="height:82vh;">
        <v-card elevation="0">
            <v-card-text>
                <h2 class="text-lg-h5 mb-6">Reset your password</h2>
                <div>Please fill out the form below to set your new password.</div>
                <v-form v-model="valid" ref="form" class="pt-6">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <Input :value="email" label="Email" :disabled="true"></Input>
                        </v-col>
                        <v-col cols="12">
                            <Input v-model="password" label="New password" type="password"
                                   :rules="rules.password"></Input>
                        </v-col>
                        <v-col cols="12">
                            <Input v-model="confirmPassword" label="Confirm new password" type="password"
                                   :rules="rules.confirmPassword"
                            ></Input>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center align-center pb-6">
                <Button :color="ada.pink" @click="resetPassword">Save password</Button>
            </v-card-actions>
        </v-card>
        <router-link class="d-flex align-center justify-center ma-8 homepageLink" to="/"
                     :style="{color: '#6e7c97', textDecoration: 'none'}"> Back to
            homepage
        </router-link>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Input from "../components/Inputs/Input";
import Utils from "../scripts/Utils";
import Button from "../components/buttons/Button";

export default {
    name: "ResetPassword",
    components: {Button, Input},
    data: () => ({
        ada,
        valid: null,
        password: null,
        confirmPassword: null,
    }),
    computed: {
        rules() {
            return {
                password: [v => !!v || "Password is required"],
                confirmPassword: [v => !!v || "Confirm password is required",
                    v => this.password === v || "Passwords don't match"
                ],
            }
        },
        email() {
            return Utils.getParameterByName('email');
        },
        token() {
            return Utils.getParameterByName('token');
        }
    },
    methods: {
        async resetPassword() {
            await this.$refs.form.validate();
            if (this.valid) {
                await this.$store.dispatch('resetPassword', {
                    email: this.email,
                    token: this.token,
                    password: this.password,
                    confirm_password: this.confirmPassword,
                }).then((res) => {
                    if (res && res.status) this.$router.push({name: 'passwordSuccess'});
                });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.container {
    max-width: 500px
}

.homepageLink:hover {
    color: $ada_pink;
}
</style>