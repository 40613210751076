<template>
    <div class="mb-2">
        <v-row>
            <v-col cols="10">
                <Combobox label="User-friendly Description"
                          v-model="selectedService" :multiple="false"
                          :items="items" :rules="rules"
                          :disabled="$store.state.readOnly === 1 || !allowEdit"
                ></Combobox>
            </v-col>
            <v-col cols="2" style="position:relative">
                <Input v-model="prio" label="Priority" @input="emitResult" hide-details
                       :disabled="true"
                ></Input>
                <div v-if="$store.state.readOnly !== 1" class="deleteIcon">
                    <TooltipIcon v-if="!hideDelete" tooltip="Delete this row"
                                 @click="$emit('click:clear', value)">mdi-delete
                    </TooltipIcon>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Input from "../../components/Inputs/Input";
import TooltipIcon from "../../components/buttons/TooltipIcon";
import Combobox from "../../components/Inputs/Combobox";

export default {
    name: "ServiceDropdownRow",
    components: {Combobox, TooltipIcon, Input},
    props: ['value', 'items', 'hideDelete', 'rules', 'index', 'allowEdit'],
    data: () => ({
        selectedService: null,
        search: null,
        prio: null,
        ignore: false,
    }),
    mounted() {
        let prios = this.data ? this.data.services.map(x => x.pivot.priority).sort() : [];
        let tmpPrio = this.index + 1;
        this.prio = this.data ? (prios.includes(tmpPrio) ? prios[prios.length - 1] + 1 : tmpPrio) : tmpPrio;
        this.init();
    },
    computed: {
        data() {
            return this.$store.state.details.data && this.$store.state.details.data[this.$store.state.details.data.role] ? this.$store.state.details.data[this.$store.state.details.data.role].details : null;
        },
    },
    methods: {
        init() {
            this.selectedService = this.value;
            this.prio = this.value ? this.value.pivot.priority : this.index + 1;
            this.ignore = true;
        },
        emitResult(v) {
            this.$emit('change', {
                service_new: this.selectedService,
                service_old: this.selectedService,
                priority: parseInt(v)
            });
        }
    },
    watch: {
        selectedService(newValue, oldValue) {
            if (newValue && JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                let selected = JSON.parse(JSON.stringify(newValue));
                if (!this.ignore) {
                    if (typeof selected === "string") {
                        selected = {
                            name: selected,
                            is_template: 0,
                            pivot: {
                                priority: null
                            }
                        }
                    }
                }
                this.$emit('change', {service_new: selected, service_old: oldValue, priority: this.prio})
            } else {
                this.ignore = false;
            }
        },
        value(v) {
            if (v) this.init();
        }
    },
}
</script>

<style lang="scss" scoped>
.deleteIcon {
    position: absolute;
    right: -30px;
    top: 22%;
}

::v-deep {
    .mdi-delete:hover {
        color: #F44336;
    }
}
</style>