import Home from '../views/Home.vue'
import Index from '../views/private/Index.vue'
import RegisterSuccess from "../views/RegisterSuccess";
import Logout from "../views/Logout";
import Unauthorized from "../views/Unauthorized";
import Admin_Index from "../views/admin/Admin_Index";
import NoUserFound from "../views/NoUserFound";
import ResetPassword from "../views/ResetPassword";
import Settings from "../views/admin/settings/Settings";
import Documentation from "../views/admin/Documentation";
import Error404 from "../views/Error404";
import EmailVerify from "../views/EmailVerify";
import EmailSuccess from "../views/EmailSuccess";
import ResetPasswordSuccess from "../views/ResetPasswordSuccess";

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/index',
        name: 'index',
        component: Index
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin_Index
    },
    {
        path: '/admin/settings',
        name: 'admin_settings',
        component: Settings
    },
    {
        path: '/admin/noUserFound',
        name: 'adminNoUser',
        component: NoUserFound
    },
    {
        path: '/register/success',
        name: 'register_success',
        component: RegisterSuccess
    },
    {
        path: '/password/reset',
        name: 'passwordReset',
        component: ResetPassword
    },
    {
        path: '/password/success',
        name: 'passwordSuccess',
        component: ResetPasswordSuccess
    },
    {
        path: '/email/verify',
        name: 'verifyEmail',
        component: EmailVerify
    },
    {
        path: '/email/success',
        name: 'emailSuccess',
        component: EmailSuccess
    },
    {
        path: '/docs',
        name: 'docs',
        component: Documentation
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: Unauthorized
    },
    {
        path: '*',
        name: 'error',
        component: Error404
    }
];

export default routes;
