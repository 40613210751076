import axios from 'axios';
import store from '../store/index'
import vm from '../main'

const baseDomain = process.env.VUE_APP_BACKEND_URL;
const baseUrl = `${baseDomain}/api`;
const axiosInstance = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    if (getCookie('session_cookie') && localStorage.getItem('session_id') && (getCookie('session_cookie') === localStorage.getItem('session_id'))) {
        config.headers['Authorization'] = localStorage.getItem('session_id');
        config.headers['session_user_id'] = localStorage.getItem('session_user_id');
    } else {
        // eslint-disable-next-line no-undef
        sso.appendHeadersToRequest(function (key, value) {
            config.headers[key] = value;
        });
        // eslint-disable-next-line no-undef
        let ssoUser = sso.getSsoUser();
        if (ssoUser) {
            config.headers['sso-user-id'] = parseInt(ssoUser.id);
            config.headers['sso-user-mail'] = ssoUser.mail;
        }
    }

    const hash = location.hash.substring(1);
    let splitted = hash.split('?');
    if (splitted.length > 1) {
        const urlParams = new URLSearchParams('?' + splitted[1]);
        let key = urlParams.get('key');
        if (key) {
            config.params = {...config.params, key: key};
        }
    }
    config.withCredentials = true;
    return config;
});

axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data && ((typeof response.data.status !== 'undefined' && response.data.status === 'redirect') || (typeof response.data.loginStatus !== "undefined" && !response.data.loginStatus))) {
        store.commit('setResponse', response.data);
        if (response.headers.session_id) {
            //setCookie('session_cookie', response.headers.session_id, 4);
            setCookie('session_cookie', response.headers.session_id, 4);
            localStorage.setItem('session_id', response.headers.session_id);
            if (response.headers.session_user_id)
                localStorage.setItem('session_user_id', response.headers.session_user_id);
        }
        vm.$router.push(response.data.url);
        return response;
    } else {
        if (!response.data.status && typeof response.data.message !== "undefined" && response.data.message !== "") store.commit('errorMessage', response.data.message);
        return response;
    }
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    let returnError = error;
    if (typeof error.response !== "undefined" && Object.prototype.hasOwnProperty.call(error.response, 'data')) {
        let errorMessage = error.response.data.message ?? error.response.data;
        if (Object.prototype.hasOwnProperty.call(error.response.data, 'errors')) {
            for (let key in error.response.data.errors) {
                if (Object.prototype.hasOwnProperty.call(error.response.data.errors, key)) {
                    errorMessage += '\n' + key + ': ';
                    for (let i = 0; i < error.response.data.errors[key].length; i++) {
                        errorMessage += error.response.data.errors[key][i] + '. ';
                    }
                }
            }
        }
        returnError = new Error(errorMessage);
    }
    store.commit('errorMessage', returnError.message);
    return Promise.reject(returnError);
});

function setCookie(name, value, hours) {
    var expires = "";
    var date = new Date();
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000)); //4 * 60 * 60 * 1000 -> 4 hours
    expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export default axiosInstance;