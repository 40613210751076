<template>
    <div>
        <div :style="{maxHeight: contentHeight}">
            <v-row no-gutters :style="{height: contentHeight}">
                <v-col md="6" cols="12" :style="{height: contentHeight}">
                    <div class="d-flex align-center justify-center flex-column" style="height:90%">
                        <img style="width:100px; height:100px; margin-bottom: 30px" src="../assets/check_mark.png">
                        <p class="text-h6 font-weight-regular">Your account has been successfully created !</p>
                        <p class="text-h6 font-weight-regular">Please check your mails to activate your account.</p>
                        <p class="text-h6 font-weight-regular">
                            If you don't find it there, kindly check your spam or <br> junk folder as it might have
                            landed there by mistake.</p>
                    </div>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" :style="{height: contentHeight}">
                    <img class="svg" src="../assets/103-social-feed-colour.svg">
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'

export default {
    name: "RegisterSuccess",
    computed: {
        contentHeight() {
            return this.$vuetify.breakpoint.smAndUp ? ada.md_contentHeight : ada.sm_contentHeight;
        }
    },
}
</script>

<style scoped>
.svg {
    height: 70vh;
    width: 80%;
    object-fit: contain;
}
</style>