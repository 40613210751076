<template>
    <div :style="{maxHeight: contentHeight}">
        <v-row no-gutters :style="{height: contentHeight}">
            <v-col cols="12" :style="{height: contentHeight}">
                <div class="d-flex align-center justify-center flex-column mx-auto"
                     style="height:100%; max-width: 500px;">
                    <transition name="fade" mode="out-in">
                        <div v-if="loading" class="d-flex justify-center align-center flex-column">
                            <v-progress-circular :size="150" :width="4" indeterminate class="mb-16"
                                                 :color="ada.pink"></v-progress-circular>
                            <p class="text-h4">Validating your email address...</p>
                        </div>
                        <div v-if="invalid" class="d-flex justify-center align-center flex-column text-center">
                            <img style=" width:100px; height:100px; margin-bottom: 30px"
                                 src="../assets/error.png">
                            <p class="text-h4">Error: Invalid token</p>
                            <p class="text-h6">Email could not be verified, please contact an admin or
                                consent@ad-alliance.de</p>
                        </div>
                    </transition>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'

export default {
    name: "EmailVerify",
    data: () => ({
        ada,
        loading: true,
        invalid: false,
    }),
    mounted() {
        this.init();
    },
    computed: {
        contentHeight() {
            return this.$vuetify.breakpoint.smAndUp ? ada.md_contentHeight : ada.sm_contentHeight;
        }
    },
    methods: {
        async init() {
            if (this.$route.query.token) {
                let data = await this.$store.dispatch('users/verifyEmail', {token: this.$route.query.token});
                this.loading = false;
                if (!data.status && data.message === "Invalid token") {
                    this.invalid = true;
                } else {
                    this.$router.push({name: 'emailSuccess'})
                }
            } else {
                this.$router.push({name: 'error'})
            }
        }
    },
}
</script>

<style scoped>

</style>