<template>
    <div class="container">
        <div class="sectionHeadline text-h6 mb-4">Cookies</div>
        <v-form v-model="valid" ref="form">
            <v-row no-gutters>
                <v-col cols="5">
                    <Input label="Cookie name" v-model.trim="name" @enter="addCookie" append-icon="mdi-cookie"
                           hide-details class="mr-4"></Input>
                </v-col>
                <v-col cols="3">
                    <Input label="Storage Duration" v-model.trim="storage_duration" type="number" @enter="addCookie"
                           hide-details class="mr-4" :min="0"></Input>
                </v-col>
                <v-col cols="2">
                    <Dropdown v-model="storage_unit" :items="$store.state.details.units" label="Unit"
                              :return-object="false" hide-details class="mr-4"></Dropdown>
                </v-col>
                <v-col>
                    <v-checkbox label="Refreshed ?" :true-value="1" :false-value="0" @change="handleChange"
                                v-model="refreshed" hide-details :color="ada.pink"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" class="d-flex justify-center mb-10">
                    <div style="width: min-content">
                        <TooltipButton :color="ada.blue" @click="addCookie"
                                       icon="mdi-plus"
                                       tooltip="Make sure to also click on 'SAVE' <br> to save your added cookies after you're done">
                            Add Cookie
                        </TooltipButton>
                    </div>
                </v-col>
            </v-row>
        </v-form>

        <v-data-table
            :headers="headers"
            :items="cookies"
        >
            <template v-slot:item.refreshed="{ item }">
                <v-icon :color="item.refreshed === 1 ? 'green' : 'red'"
                >
                    {{ item.refreshed === 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
            <template v-slot:item.storage_duration="{ item }">
                {{ item.storage_duration }} {{
                    item.storage_duration === 1 ? item.storage_unit.substring(0, item.storage_unit.length - 1) : item.storage_unit
                }}
            </template>
            <template v-slot:item.action="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="actionIcon"
                            @click.stop="deleteCookie(item)">
                            mdi-{{ item.action }}
                        </v-icon>
                    </template>
                    <span>Delete</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-radio-group v-model="data.cookie_sync" row @change="handleChange"
                       :disabled="$store.state.readOnly === 1">
            <template #label>
                <div class="text-body-1 mr-4">Cookie sync</div>
            </template>
            <v-radio :color="ada.pink" class="mr-8"
                     label="yes"
                     :value="1"
            ></v-radio>
            <v-radio :color="ada.pink"
                     label="no"
                     :value="0"
            ></v-radio>
        </v-radio-group>
        <v-slide-y-transition hide-on-leave>
                <Textarea v-if="data.cookie_sync === 1" @input="handleChange"
                          v-model="data.cookie_sync_description"
                          label="Please describe / name tech vendors that you are syncing with" rows="3"
                          :disabled="$store.state.readOnly === 1"
                ></Textarea>
        </v-slide-y-transition>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Input from "../../../components/Inputs/Input";
import Textarea from "../../../components/Inputs/Textarea";
import TooltipButton from "../../../components/buttons/TooltipButton";
import Dropdown from "../../../components/Inputs/Dropdown";

export default {
    name: "Cookies",
    props: ['data', 'tabIndex', 'previousIndex', 'tabs'],
    components: {Dropdown, TooltipButton, Textarea, Input},
    data: () => ({
        name: null,
        storage_duration: null,
        refreshed: 0,
        storage_unit: 'months',
        ada,
        valid: null,
        reRender: 0,
        rules: {
            name: [v => !!v && v.trim() !== "" || 'Name is required',
                v => ((v && v.length <= 255) || v === null || v === "") || 'Name must be less than 255 characters'],
            storage: [
                v => !!v || 'Storage is required',
                v => (v && !/[^0-9.]/.test(v)) || 'Must be a positive number',
            ]
        },
    }),
    mounted() {
        this.$emit('mounted', this);
    },
    computed: {
        cookies() {
            let ret = []
            this.data.cookies.forEach((item) => {
                item.action = 'delete';
                ret.push(item);
            });
            return ret;
        },
        headers() {
            let ret = [
                {text: 'Cookie', value: 'name',},
                {text: 'Refreshed', value: 'refreshed', sortable: false, width: '140px', align: 'center'},
                {text: 'Storage duration', value: 'storage_duration', width: '180px', sortable: false}
            ];
            if (this.$store.state.readOnly !== 1) ret.push({
                text: 'Actions',
                value: 'action',
                width: '1%', align: 'center',
                sortable: false
            })
            return ret;
        },
        hasChanges() {
            return this.$store.state.details.components.cookies.hasChanges;
        },
        role() {
            return this.$store.state.users.current ? this.$store.state.users.current.role : null;
        }
    },
    methods: {
        addCookie() {
            if (this.validateCookie()) {
                if (!this.data.cookies.map(x => x.name).includes(this.name)) {
                    this.data.cookies.push({
                        name: this.name,
                        refreshed: this.refreshed,
                        storage_duration: this.storage_duration,
                        storage_unit: this.storage_unit,
                        action: 'delete'
                    })
                    this.$root.$emit('snackbar', "Cookie <b>" + this.name + "</b> added", 'success');
                    this.name = null;
                    this.storage_duration = null;
                    this.refreshed = 0;
                    this.handleChange();
                } else {
                    this.$root.$emit('snackbar', 'Cookie already exists', 'warning');
                }
            }
        },
        validateCookie() {
            let empty = [];
            if (this.name && this.storage_duration) {
                if (this.storage_duration < 0) {
                    this.$root.$emit('snackbar', 'Storage duration must be a positive number', 'warning')
                    return false;
                }
                return true;
            } else {
                if (this.name === null || this.name === "") empty.push('cookie name');
                if (this.storage_duration === null || this.storage_duration === "") empty.push('storage duration');
                this.$root.$emit('snackbar', 'Please enter a ' + empty.join(' and '), 'warning');
                return false;
            }
        },
        deleteCookie(cookie) {
            this.data.cookies.splice(this.data.cookies.findIndex((x) => {
                let key = typeof cookie.id !== "undefined" ? 'id' : 'name';
                return x[key] === cookie[key]
            }), 1);
            this.handleChange();
        },
        handleChange() {
            let role = this.role === 'agency_group' ? 'agency' : this.role;
            let og_data = this.role === 'admin' || this.role === 'user' ?
                this.$store.state.agency_vendor.selectedData.details :
                this.$store.state.details.data[role].details;
            if (og_data === null) og_data = JSON.parse(JSON.stringify(this.$store.state.details.details_template));
            let changed = false;
            if (og_data.cookies.length !== 0 && og_data.cookies.length === this.data.cookies.length) {
                for (let i = 0; i < og_data.cookies.length; i++) {
                    if (og_data.cookies[i].id !== this.data.cookies[i].id ||
                        og_data.cookies[i].refreshed !== this.data.cookies[i].refreshed ||
                        og_data.cookies[i].storage_duration !== this.data.cookies[i].storage_duration
                    ) {
                        changed = true;
                        break;
                    }
                }
            }
            if (og_data.cookies.length !== this.data.cookies.length ||
                og_data.cookie_sync !== this.data.cookie_sync ||
                og_data.cookie_sync_description !== this.data.cookie_sync_description ||
                changed
            ) {
                if (!this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'cookies', value: true});
            } else {
                if (this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'cookies', value: false});
            }
        }
    },
    watch: {
        data() {
            if (!this.$store.state.details.isRegistration) {
                setTimeout(() => {
                    this.$emit('validate', 'Cookies');
                }, 500);
            }
        },
        valid() {
            this.$emit('validate', 'Cookies');
        }
    },
}
</script>

<style scoped>
.actionIcon:hover {
    color: #F44336;
}
</style>