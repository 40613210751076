<template> <!--https://vuetifyjs.com/en/api/v-input/-->
    <v-text-field
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        :outlined="outlined"
        :dense="dense"
        :label="label"
        :color="ada.darkGrey"
        :disabled="readOnly || disabled"
        :type="type"
        :loading="loading"
        :required="required"
        :hide-details="hideDetails"
        :error-messages="errorMessages"
        :placeholder="placeholder"
        :hint="hint"
        :rules="rules"
        :persistent-hint="typeof hint !== 'undefined'"
        @blur="$emit('blur', $event)"
        @keyup.enter="emitEnter"
        :validate-on-blur="validateOnBlur"
        :class="{'mb-4': hideDetails === 'auto' || hideDetails === 'false'}"
    >
        <v-icon v-if="prependIcon"
                slot="prepend"
                :color="prependIconColor"
                @click="$emit('click:prepend')"
        >
            {{ prependIcon }}
        </v-icon>

        <v-icon v-if="appendIcon"
                slot="append"
                :color="appendIconColor"
                @click="$emit('click:append')"
        >
            {{ appendIcon }}
        </v-icon>

        <v-icon v-if="appendOuterIcon"
                slot="append-outer"
                :color="appendOuterIconColor"
                @click="$emit('click:append-outer')"
        >
            {{ appendOuterIcon }}
        </v-icon>
        <template #append-outer>
            <slot name="append-outer"></slot>
        </template>
    </v-text-field>
</template>

<script>
import ada from '../../scss/variables.scss';

export default {
    name: "Input",
    props: {
        'value': [String, Number],
        'label': String,
        'readOnly': Boolean,
        'disabled': Boolean,
        'appendIcon': {
            type: String
        },
        'appendIconColor': {
            type: String,
            default: null
        },
        'appendOuterIcon': {
            type: String
        },
        'appendOuterIconColor': {
            type: String,
            default: null
        },
        'prependIcon': {
            type: String
        },
        'hint': {
            type: String
        },
        'prependIconColor': {
            type: String,
            default: null
        },
        'type': {
            type: String,
            default: 'text'
        },
        'outlined': {
            type: Boolean,
            default: true
        },
        'dense': {
            type: Boolean,
            default: false
        },
        'hideDetails': {
            type: [Boolean, String],
            default: 'auto'
        },
        required: {
            type: Boolean,
            default: false
        },
        validateOnBlur: {
            type: Boolean,
            default: true
        },
        errorMessages: {
            required: false
        },
        placeholder: String,
        loading: Boolean,
        rules: [Function, Array],
    },
    data: () => {
        return {
            ada
        }
    },
    methods: {
        emitEnter() {
            this.$emit('enter');
        }
    }
}
</script>

<style scoped>

</style>