<template>
    <div>
        <Datatable :headers="headers" :items="items" title="TCF purposes"
                   :loading="$store.state.details.fetching.purposes"
                   item-slot="item.is_template" item-slot2="item.type"
                   @click:row="openEditDialog($event)"
                   @click:edit="openEditDialog($event)"
                   @click:delete="openEditDialog($event, 'delete')"
        >
            <template #top-middle>
                <div class="d-flex ml-6">
                    <v-checkbox v-for="item in types" :key="item.value"
                                v-model="selectedFilter" :value="item.value"
                                :label="item.text" class="mr-8"
                                :color="ada.pink"
                    >
                    </v-checkbox>
                </div>
            </template>
            <template #top>
                <Button icon="mdi-plus" :color="ada.blue" @click="openEditDialog(null)">Add TCF Purpose</Button>
            </template>
            <template #item.is_template="item">
                <v-icon :color="item.is_template === 1 ? 'green' : ada.grey"
                >
                    {{ item.is_template === 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
            <template #item.type="item">
                <div>{{
                        types.find(x => x.value === item.type).text === 'Default' ? '' : types.find(x => x.value === item.type).text
                    }}
                </div>
            </template>
        </Datatable>
        <Dialog v-model="showEditDialog" :title="selected ? 'Add TCF Purpose' : 'Edit TCF Purpose'"
                :loading="loading.submit" width="600px"
                @ok="submit"
                @cancel="showEditDialog = false">
            <v-form v-model="valid" ref="form">
                <span class="body-1">Type</span>
                <v-radio-group v-model="selected.type" row class="mt-2">
                    <v-radio v-for="type in types" :key="type.text"
                             :color="ada.pink" :label="type.text" :value="type.value"
                    ></v-radio>
                </v-radio-group>
                <Input label="Name" v-model="selected.name" @enter="submit"></Input>
            </v-form>
        </Dialog>

        <!-- CONFIRM DELETE DIALOG-->
        <Dialog v-model="showDeleteDialog" title="Delete TCF purpose" ok-text="Yes, Delete"
                :loading="loading.delete" width="450px" min-width="450px"
                @ok="deletePurpose" @cancel="showDeleteDialog = false">
            <p>Are you sure that you want to delete the following TCF <br> purpose ?</p>
            <p class="d-flex justify-center"><b>{{ selected ? selected.name : '' }}</b></p>
        </Dialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Datatable from "../../../components/Tables/Datatable";
import Button from "../../../components/buttons/Button";
import Input from "../../../components/Inputs/Input";
import Dialog from "../../../components/Dialog";

export default {
    name: "Settings_Purposes",
    components: {Dialog, Input, Button, Datatable},
    data: () => ({
        ada,
        headers: [
            {text: 'Type', value: 'type', width: '170px'},
            {text: 'Name', value: 'name'},
            {text: 'Created at', value: 'created_at', width: '200px'},
            {text: 'Actions', value: 'action', width: '100px'},
        ],
        types: [
            {text: 'Default', value: null},
            {text: 'Special Purpose', value: 'special'},
            {text: 'Feature', value: 'feature'},
            {text: 'Special Feature', value: 'special_feature'},
        ],
        valid: null,
        showEditDialog: false,
        showDeleteDialog: false,
        selected: {
            name: null,
            type: null,
        },
        loading: {
            submit: false,
            delete: false,
        },
        selectedFilter: []
    }),
    mounted() {
        this.init()
    },
    computed: {
        items() {
            let ret = [];
            let options = {year: 'numeric', month: '2-digit', day: '2-digit'};
            this.$store.state.details.purposes.forEach((item) => {
                item.createdFormatted = new Date(item.created_at).toLocaleDateString('de-DE', options);
                item.action = ['edit', 'delete'];
                ret.push(item);
            });
            return this.selectedFilter.length !== 0 ? ret.filter(x => this.selectedFilter.includes(x.type)) : ret;
        },
        error() {
            return this.$store.state.errorCount;
        }
    },
    methods: {
        init() {
            if (this.items.length === 0) this.fetch();
        },
        async fetch() {
            await this.$store.dispatch('details/fetchPurposes');
        },
        async submit() {
            await this.$refs.form.validate();
            if (this.valid) {
                this.loading.submit = true;
                await this.$store.dispatch('details/updatePurpose', this.selected).then((res) => {
                    if (res && res !== "") {
                        this.$root.$emit('snackbar', 'Successfully saved');
                        this.showEditDialog = false;
                    }
                })
                this.loading.submit = false;
            }
        },
        async deletePurpose() {
            this.loading.delete = true;
            await this.$store.dispatch('details/deletePurpose', this.selected.id).then((res) => {
                if (res && res !== "") {
                    this.$root.$emit('snackbar', 'Successfully deleted <b>' + this.selected.name + '</b>');
                    if (this.showEditDialog) this.showEditDialog = false;
                    this.showDeleteDialog = false;
                }
            })
            this.loading.delete = false;
        },
        openEditDialog(data, type = 'edit') {
            if (data) this.selected = JSON.parse(JSON.stringify(data));
            if (type === 'delete') this.showDeleteDialog = true;
            if (type === 'edit') this.showEditDialog = true;
        }
    },
    watch: {
        showEditDialog(v) {
            if (!v) {
                this.selected = {
                    name: null,
                    type: null,
                };
            }
        },
        showDeleteDialog(v) {
            if (!v) {
                this.selected = {
                    name: null,
                    type: null,
                };
            }
        },
        error() {
            if (this.loading.submit) this.loading.submit = false;
            if (this.loading.delete) this.loading.delete = false;
        }
    }
}
</script>

<style scoped>

</style>