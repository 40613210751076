<template>
    <div class="container">
        <v-form v-model="valid" ref="form">
            <RelationsTable :relations="relations" :addAgencyCol="false" :showExelExport="false"></RelationsTable>
        </v-form>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import RelationsTable from "../../admin/RelationsTable";

export default {
    name: "Relations",
    components: {RelationsTable},
    props: ['tabIndex', 'previousIndex', 'tabs'],
    data: () => ({
        ada,
        valid: null,
    }),
    mounted() {
        this.$emit('mounted', this);
    },
    computed: {
        role() {
            return this.$store.state.users.current ? this.$store.state.users.current.role : null;
        },
        relations() {
            if (this.role === 'admin' || this.role === 'user') {
                return this.$store.state.agency_vendor.selectedData &&
                this.$store.state.agency_vendor.selectedData.vendors ?
                    this.$store.state.agency_vendor.selectedData.vendors : [];
            } else {
                return typeof this.$store.state.details !== "undefined" &&
                typeof this.$store.state.details.data !== "undefined" &&
                typeof this.$store.state.details.data.agency !== "undefined" &&
                typeof this.$store.state.details.data.agency.vendors !== "undefined"
                    ? this.$store.state.details.data.agency.vendors : [];
            }
        }
    },
    watch: {
        relations() {
            if (!this.$store.state.details.isRegistration) {
                setTimeout(() => {
                    this.$emit('validate', 'Relations');
                }, 500);
            }
        },
        valid() {
            this.$emit('validate', 'Relations');
        }
    },
}
</script>

<style lang="scss" scoped>

.container {
    max-width: 100% !important;
}

</style>