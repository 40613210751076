import Vue from 'vue'
import Vuex from 'vuex'

import users from './modules/users'
import agency_vendor from './modules/agency_vendor'
import details from './modules/details'

import {RepositoryFactory} from "../scripts/RepositoryFactory";

const UserRepository = RepositoryFactory.get('users');
const DetailRepository = RepositoryFactory.get('details');

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
    state: {
        readOnly: 0,
        error: null,
        errorCount: 0,
        response: null,
    },
    actions: {
        async login(context, payload) { //SitetypeId
            let headers = {};
            if (typeof payload.sso !== 'undefined' && payload.sso) {
                headers = {
                    params: {
                        sso: payload.sso + ''
                    }
                }
            }
            let {data} = await UserRepository.login(payload, headers);
            return data;
        },
        async logout({commit}, onlyClearSession = false) {
            if (!onlyClearSession) {
                await UserRepository.logout();
                commit('agency_vendor/resetAll', null, {root: true});
                commit('details/setData', null, {root: true});
                //commit('details/resetHasChanges', true, {root: true});
                commit('details/resetHasChanges', null, {root: true});
            }

            commit('users/setCurrent', {data: null}, {root: true});
            localStorage.removeItem('session_id');
            localStorage.removeItem('session_user_id');
            document.cookie = 'session_cookie=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
        },
        async checkSSOId() {
            await UserRepository.checkSSOId();
        },
        async register(context, payload) { //SitetypeId
            await UserRepository.register(payload)
        },
        async forgotPassword(context, payload) {
            return await UserRepository.forgotPassword(payload);
        },
        async resetPassword(context, payload) {
            return await UserRepository.resetPassword(payload);
        },
        async resendVerifyEmail(context, payload) {
            return await UserRepository.resendVerifyEmail(payload);
        },
        async manuallyVerifyEmail({commit}, payload) {
            let {data} = await UserRepository.manuallyVerifyEmail(payload);
            if (data.status) commit('users/setUsers', data.users, {root: true});
            return data;
        },
        async uploadJCAs(context, payload) {
            return await DetailRepository.uploadJCAs(payload);
        }
    },
    mutations: {
        setReadOnly(state, payload) {
            state.readOnly = payload;
        },
        errorMessage(state, payload) {
            state.error = payload;
            state.errorCount++;
        },
        setResponse(state, payload) {
            state.response = payload;
            //state.showLoginDialog = true;
        },
        closeLoginDialog(state) {
            state.showLoginDialog = false;
        }
    },
    modules: {
        users,
        agency_vendor,
        details
    }
})
