<template>
    <div>
        <div style="position: relative">
            <StatusStepper v-if="currentUser && currentUser.role === 'vendor'" :data="data"></StatusStepper>
            <Button v-if="!allowEdit" @click="showAllowEditDialog = true"
                    :color="ada.pink" icon="mdi-pencil"
                    :style="{position: $vuetify.breakpoint.lgAndUp ? 'absolute' : 'block',
                            top: $vuetify.breakpoint.lgAndUp ? '10px' : '0px', marginBottom:$vuetify.breakpoint.mdAndDown ? '24px' : '0px'}">
                enable edit
            </Button>
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="loading.status" :key="0"
                 style="min-height:585px; display:flex;align-items: center; justify-content: center">
                <v-progress-circular indeterminate width="3" :color="ada.pink" size="50"></v-progress-circular>
            </div>
            <div v-else :key="1">
                <v-tabs v-model="activeTab" :color="ada.blue" height="55" show-arrows
                        :grow="currentUser && (currentUser.role === 'vendor' || (this.isAdminOrUser && this.vuexStateAgencyVendor.selectedRole === 'vendor'))">
                    <!--icons-and-text-->
                    <v-tabs-slider :color="ada.blue"></v-tabs-slider>
                    <v-tab v-for="(item, i) in tabs"
                           :key="i"
                    >
                        <v-badge :value="typeof item.status !== 'undefined' && item.status !== null"
                                 :color="getStatus(item.status).color"
                                 :icon="getStatus(item.status).icon"
                                 class="mt-1"
                        >
                            <v-icon left :class="item.status">
                                {{ item.icon }}
                            </v-icon>
                            {{ item.name }}
                        </v-badge>
                    </v-tab>
                    <v-tabs-items v-model="activeTab">
                        <v-tab-item :eager="true"
                                    v-for="(item, i) in tabs"
                                    :key="i"
                        >
                            <v-card flat>
                                <template slot="progress">
                                    <v-progress-linear
                                        :color="ada.pink"
                                        indeterminate
                                    ></v-progress-linear>
                                </template>
                                <v-card-text>
                                    <div>
                                        <component :is="item.component_name"
                                                   :tabIndex="i" :tabs.sync="tabs"
                                                   :allowEdit="allowEdit"
                                                   :assignments="assignments"
                                                   :previousIndex="previousTab"
                                                   :jca_through="data ? data.jca_through : null"
                                                   :data.sync="syncedData"
                                                   :name.sync="name"
                                                   @change:name="updateName" :relations="relations"
                                                   @change:services="updateServices"
                                                   @change:assignments="updateAssignments"
                                                   @mounted="saveVueComponent"
                                                   @validate="validateSection"
                                                   @updateBadge="updateTabBadge"
                                        ></component>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
                <v-card flat class="pa-4 pt-0">
                    <div>
                        <v-slide-x-transition hide-on-leave>
                            <v-btn class="mr-4" color="#39495617" depressed @click="previousSection"
                                   v-show="activeTab !== 0">
                                Previous
                            </v-btn>
                        </v-slide-x-transition>

                        <v-btn v-if="($route.name === 'admin' && tabs.length-1 !== activeTab) || !allowEdit"
                               class="mr-4"
                               color="#39495617" depressed @click="nextSection"
                        >
                            Next
                        </v-btn>
                        <v-btn
                            v-if="!$store.state.readOnly &&
                    (typeof tabs[activeTab] !== 'undefined' && (tabs[activeTab].id !== 'relations'))"
                            class="white--text" :color="ada.pink" depressed
                            @click="submit(false)" :loading="loading.submit"
                            :disabled="saveDisabled"
                        >
                            Save{{ $route.name === 'admin' || activeTab === tabs.length - 1 ? '' : ' & Continue' }}
                        </v-btn>
                        <v-tooltip top v-if="role === 'vendor' && !$store.state.readOnly">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="white--text ml-4"
                                       :color="ada.blue" depressed
                                       v-bind="attrs"
                                       v-on="on" :loading="loading.verify"
                                       @click="showVerifyDialog = true"
                                       :disabled="data.vendor.is_complete === 0 || !requiredTabsComplete || !allowEdit || loading.submit"
                                >
                                    Verify
                                </v-btn>
                            </template>
                            <span>{{
                                    $store.state.details.data && $store.state.details.data.vendor && $store.state.details.data.vendor.pending_at !== null ? 'Restart the verifying process' : 'Start the verifying process'
                                }}</span>
                        </v-tooltip>
                    </div>
                </v-card>

            </div>
        </transition>
        <Dialog v-model="showVerifySuccessDialog" title="Verification started" show-close-button persistent
                hide-buttons>
            <div>
                <p>The verification process started successfully! </p>
                <p>We will revise your registered information and
                    verify you as a vendor. Mind that this process can take up to a few weeks.</p>
                <p>We will notify you per email after we finished revising you.</p>
                <p>Besides that you're always free to check your verification status on this page (after you logged
                    in).</p>
            </div>
            <div class="d-flex align-center justify-center mt-2" style="width: 100%">
                <Button @click="showVerifySuccessDialog = false" :color="ada.blue">ok</Button>
            </div>
        </Dialog>
        <Dialog v-model="showVerifyDialog" title="Start verifying process?" ok-text="Yes, verify"
                @cancel="showVerifyDialog = false" :loading="loading.verify"
                @ok="verifyRequest">
            <p>Are you sure that you want to start the verification process?</p>
            <p>After starting this process you will need to restart the verification process after you made some
                changes.</p>
        </Dialog>
        <Dialog v-model="showAllowEditDialog" title="Start editing?" ok-text="Ok"
                @cancel="showAllowEditDialog = false" @ok="enableEdit">
            <p>After you've enabled the editing please make sure to <b>click on the 'verify' button after you're done
                editing</b>.
                That way we can revise your information again.</p>
        </Dialog>
        <Dialog v-model="showVerifyOrContinueDialog" title="Continue editing?" persistent hide-buttons>
            <p>Do you want to continue editing or are you done and want to verify now ?</p>
            <div class="d-flex justify-end">
                <Button :color="ada.darkGrey" class="mr-4" @click="continueEditing">Continue</Button>
                <Button :color="ada.blue" @click="verifyRequest" :loading="loading.verify">Verify</Button>
            </div>
        </Dialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss';
import Contact from "./sections/Contact";
import Privacy from "./sections/Privacy";
import Services from "./sections/Services";
import Domains from "./sections/Domains";
import TCF from "./sections/TCF";
import ProcessedData from "./sections/ProcessedData";
import Cookies from "./sections/Cookies";
import JCA from "./sections/JCA";
import Assignment from "./sections/Assignment";
import Relations from "./sections/Relations";
import StatusStepper from "./StatusStepper";
import Dialog from "../../components/Dialog";
import Button from "../../components/buttons/Button";

export default {
    name: "RegistrationForm",
    props: ['data', 'triggerInit'],
    components: {
        Button,
        Dialog,
        StatusStepper,
        Relations, Assignment, JCA, Cookies, ProcessedData, TCF, Domains, Services, Privacy, Contact
    },
    data: () => ({
        ada,
        showVerifySuccessDialog: false,
        showVerifyDialog: false,
        initiallyAllowedEdit: null,
        showAllowEditDialog: false,
        showVerifyOrContinueDialog: false,
        allowEdit: false,
        previousTab: null,
        activeTab: 0,
        name: null,
        assignments: null,
        relations: null,
        syncedData: {
            "street": null,
            "zip": null,
            "city": null,
            "country": null,
            "service_name": null,
            "tcf_status": "registered",
            "tcf_id": null,
            "description": null,
            "optout_url": null,
            "privacy_url": null,
            "cookie_sync": 1,
            "cookie_sync_description": null,
            "jca": 0,
            "privacy": {
                "is_eu": 1,
                "non_eu_transfer": 1,
                "name": null,
                "email": null,
                "street": null,
                "zip": null,
                "city": null,
                "country": null,
                "phone": null,
            },
            "domains": [],
            "cookies": [],
            "services": [],
            "purposes": [],
            "data_types": [],
        },
        possible_tabs: [
            {
                id: 'assignments',
                name: 'Agency Assignment',
                component_name: 'Assignment',
                icon: 'mdi-account-multiple-plus',
                status: null
            },
            {id: 'contact', name: 'Contact', component_name: 'Contact', icon: 'mdi-account-details', status: null},
            {
                id: 'privacy',
                name: 'Privacy Protection',
                component_name: 'Privacy',
                icon: 'mdi-shield-lock',
                status: null
            },
            {
                id: 'services',
                name: 'Service Descriptions',
                component_name: 'Services',
                icon: 'mdi-room-service',
                status: null
            },
            {
                id: 'domains',
                name: 'Tracker Domains',
                component_name: 'Domains',
                icon: 'mdi-domain',
                status: null
            },
            {id: 'purposes', name: 'TCF v2.2', component_name: 'TCF', icon: 'mdi-application-cog', status: null},//application-cog-outline handshake-outline
            {
                id: 'data_types',
                name: 'Processed Data',
                component_name: 'ProcessedData',
                icon: 'mdi-database',
                status: null
            },
            {id: 'cookies', name: 'Cookies', component_name: 'Cookies', icon: 'mdi-cookie', status: null},
            {id: 'jca', name: 'JCA', component_name: 'JCA', icon: 'mdi-checkbox-marked-outline', status: null},
            {
                id: 'relations',
                name: 'Vendor Relations',
                component_name: 'Relations',
                icon: 'mdi-handshake-outline',
                status: null
            }
        ],
        tabs: [],
        vueComponents: {},
        loading: {
            verify: false,
            submit: false,
            status: false,
        },
        vendor_tabs: ['Contact', 'Privacy Protection', 'Service Descriptions', 'Tracker Domains', 'TCF v2.2', 'Processed Data', 'Cookies', 'JCA'],
        agency_tabs: ['Contact', 'Privacy Protection', 'JCA', 'Vendor Relations'],
        agency_group_tabs: ['Agency Assignment', 'Contact', 'Privacy Protection', 'JCA', 'Vendor Relations'],
    }),
    mounted() {
        this.init();
    },
    computed: {
        vuexState() {
            return this.$store.state.users;
        },
        vuexStateAgencyVendor() {
            return this.$store.state.agency_vendor;
        },
        currentUser() {
            return this.vuexState.current;
        },
        role() {
            let ret = this.currentUser ? this.currentUser.role : null;
            return ret === 'agency_group' ? 'agency' : ret;
        },
        updated() {
            return this.$store.state.details.updated;
        },
        requiredTabsComplete() {
            let requiredTabs = ['contact', 'privacy', 'services', 'domains', 'purposes', 'data_types'];
            let completed = true;
            for (let i = 0; i < requiredTabs.length; i++) {
                let tab = this.tabs.find(x => x.id === requiredTabs[i]);
                if (tab && tab.status !== 'complete') {
                    completed = false;
                    break;
                }
            }
            return completed;
        },
        isAdminOrUser() {
            return this.role === 'admin' || this.role === 'user';
        },
        saveDisabled() {
            return !this.allowEdit && this.tabs[this.activeTab].id !== 'cookies' && this.tabs[this.activeTab].id !== 'jca'
        }
    },
    methods: {
        init() {
            if (typeof this.tabs[this.activeTab] === "undefined") this.activeTab = 0;
            if (this.data && (this.data[this.role] || this.isAdminOrUser)) {
                this.checkAllowEdit();
                let data = this.isAdminOrUser ? this.data : this.data[this.role];
                this.name = data.name;
                this.assignments = typeof data.assignments !== "undefined" ? JSON.parse(JSON.stringify(data.assignments)) : null;
                this.relations = typeof data.vendors !== "undefined" ? data.vendors : (typeof data.agencies !== "undefined" ? data.agencies : null);
                if (typeof data.details !== "undefined" && data.details) {
                    this.syncedData = JSON.parse(JSON.stringify(data.details));
                    if (this.syncedData.privacy === null) {
                        this.syncedData.privacy = JSON.parse(JSON.stringify(this.$store.state.details.details_template.privacy));
                    }
                } else {
                    this.syncedData = JSON.parse(JSON.stringify(this.$store.state.details.details_template));
                }
            }
            this.updateTabs();
        },
        async submit(stopAutoStep = false, triggeredFromPrevTab = false) {
            await this.validateSection(this.tabs[this.activeTab].component_name, true);
            if (this.currentUser && this.currentUser.role === 'user') return;
            if (this.vueComponents[this.tabs[this.activeTab].component_name].valid && this.$store.state.details.components[this.tabs[this.activeTab].id].hasChanges) {
                let ret = JSON.parse(JSON.stringify(this.role === 'admin' ? this.data : this.data[this.role]));
                ret.name = this.name;
                if (typeof ret.assignments !== "undefined") ret.assignments = this.assignments;
                ret.details = this.syncedData;
                //!== false needed -> triggeredFromPrevTab can be 0 and with 0 it will fail otherwise but it shouldn't
                ret.section = this.tabs[triggeredFromPrevTab !== false ? triggeredFromPrevTab : this.activeTab].id;
                ret.lang = this.$store.state.details.lang;
                let type = typeof ret.details.id !== "undefined" ? 'update' : 'save';
                this.loading.submit = true;
                // for history entry
                ret.user_id = this.currentUser.id;
                // necessary for admin roles or else i wont know if data belongs to vendor or agency if details = null
                ret.role = this.currentUser.role !== 'admin' ? this.currentUser.role : this.vuexStateAgencyVendor.selectedRole;
                await this.$store.dispatch('details/' + type, ret)
                .then((res) => {
                    if (res && res !== "") {
                        this.updateTabBadge();
                        this.$root.$emit('snackbar', `${this.tabs[this.activeTab].name} successfully saved`);
                        if (this.activeTab !== this.tabs.length - 1 && !stopAutoStep && this.$route.name === 'index') this.nextSection();
                        setTimeout(() => {
                            if (this.initiallyAllowedEdit !== null && this.initiallyAllowedEdit === false) this.showVerifyOrContinueDialog = true;
                        }, 400)
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
                this.loading.submit = false;
            } else {
                //this.$store.state.details.components[this.tabs[this.activeTab].id].valid &&
                if (!this.$store.state.details.components[this.tabs[this.activeTab].id].hasChanges) {
                    //console.log(this.vueComponents[this.tabs[this.activeTab].component_name] + ' no changes');
                    //if (location.href.indexOf('localhost') !== -1) this.$root.$emit('snackbar', 'No Changes.', 'info');
                } else if (!this.vueComponents[this.tabs[this.activeTab].component_name].valid) {
                    this.$root.$emit('snackbar', 'Please make sure all fields are filled in correctly', 'warning');
                    if (this.vueComponents[this.tabs[this.activeTab].component_name]) {
                        await this.validateSection(this.tabs[this.activeTab].component_name, true);
                    }
                }
                //on tab change dont go to next section
                if (this.$route.name === 'index' && !stopAutoStep && this.vueComponents[this.tabs[this.activeTab].component_name].valid) this.nextSection();
            }
        },
        async verifyRequest() {
            //check if required sections are filled
            //purposes tcf_id muss gegeben sein
            this.showVerifyDialog = false;
            let requiredSections = ['contact', 'privacy', 'services', 'domains', 'purposes', 'data_types'];
            let valid = true;
            for (let i = 0; i < requiredSections.length; i++) {
                if (this.tabs.find(x => x.id === requiredSections[i]).status !== 'complete') {
                    valid = false;
                    break;
                }
            }
            if (valid) {
                if (this.data.vendor.details.tcf_id === null) {
                    this.$root.$emit('snackbar', 'Please enter your TCF v2.2 ID to start the verifying process', 'warning');
                } else {
                    this.loading.verify = true;
                    await this.$store.dispatch('agency_vendor/startVendorVerify', this.data.vendor_id)
                    .then((res) => {
                        if (res !== "") {
                            if (this.showVerifyOrContinueDialog) this.showVerifyOrContinueDialog = false;
                            this.showVerifySuccessDialog = true;
                            //this.$root.$emit('snackbar', 'Verfiying process started !', 'success');
                        } else {
                            this.loading.verify = false;
                        }
                    })
                    this.loading.verify = false;
                }
            } else {
                this.$root.$emit('snackbar', 'Please make sure all required fields/sections are filles correctly', 'warning')
            }
        },
        async validateSection(component_name, showValidationErrs) {
            let component = this.vueComponents[component_name];
            if (component) {
                if (component.$refs.form) await component.$refs.form.validate();
                let index = this.tabs.findIndex(x => x.component_name === component_name);
                if (index !== -1) this.updateTabBadge(index);
                if (this.$route.name === 'index' && component.requiredFieldsNotFilled && !showValidationErrs) {
                    if (component.$refs.form) component.$refs.form.resetValidation();
                }
            }
        },
        updateTabBadge(tabIndex) {
            let index = tabIndex || this.activeTab;
            let component_name = this.tabs[index].component_name;
            let showBadge = false;
            //Components with Badges that only show on certain conditions
            let cmpWthCndtnBadges = ['Cookies', 'Relations', 'Assignment'];

            //show cookie badge only if cookies.length !== 0 && jca badge only if its checked
            if (cmpWthCndtnBadges.includes(component_name)) {
                let data = this.isAdminOrUser ? this.data : this.data[this.role];
                if (component_name === 'Cookies') showBadge = data && data.details && data.details.cookies.length !== 0;
                if (component_name === 'Relations') showBadge = data && data.vendors && data.vendors.length !== 0;
                if (component_name === 'Assignment') showBadge = data && data.assignments && data.assignments.length !== 0;

                if (!showBadge) this.tabs[index].status = null;
            }

            if (this.vueComponents[component_name] && (showBadge || (!cmpWthCndtnBadges.includes(component_name)))) {
                if (this.tabs[index].id === 'purposes') {
                    if (this.vueComponents[component_name].purposes_valid === null) {
                        this.tabs[index].status = 'loading';
                    } else {
                        let data = this.isAdminOrUser ? this.data : this.data[this.role];
                        if (data.details) {
                            this.tabs[index].status = data.details.tcf_status === 'planned' ? 'incomplete' :
                                (this.vueComponents[component_name].valid === false ? 'fail' :
                                    (this.vueComponents[component_name].purposes_valid === false ? 'incomplete' : 'complete'));
                        }
                    }
                } else {
                    this.tabs[index].status = this.vueComponents[component_name].valid === false ? 'fail' : 'complete';
                }
            }
        },
        updateTabs() {
            let ret = [];
            if (this.currentUser) {
                let tab_template;
                if (this.currentUser.role === 'vendor' ||
                    (this.isAdminOrUser && this.vuexStateAgencyVendor.selectedRole === 'vendor')) {
                    tab_template = this.vendor_tabs;
                } else if (this.currentUser.role === 'agency' ||
                    (this.isAdminOrUser && this.vuexStateAgencyVendor.selectedRole === 'agency')) {

                    if (this.isAdminOrUser && this.vuexStateAgencyVendor.selectedData && this.vuexStateAgencyVendor.selectedData.is_group === 1) {
                        tab_template = this.agency_group_tabs;
                    } else {
                        tab_template = this.agency_tabs;
                    }
                } else if (this.currentUser.role === 'agency_group') {
                    tab_template = this.agency_group_tabs;
                }

                tab_template.forEach((tab_name) => {
                    let tab = this.possible_tabs.find(x => x.name === tab_name);
                    if (tab) ret.push(tab);
                })
            }
            this.tabs = ret;
            if (typeof this.tabs[this.activeTab] === "undefined") this.activeTab = 0;
        },
        previousSection() {
            if (this.activeTab !== 0) this.activeTab--;
        },
        nextSection() {
            if (this.activeTab !== this.tabs.length - 1) this.activeTab++;
        },
        getStatus(status) {
            let ret = {};
            if (status === 'complete') {
                ret.color = 'green';
                ret.icon = 'mdi-check-bold';
            } else if (status === 'incomplete') {
                ret.color = 'orange';
                ret.icon = 'mdi-exclamation-thick';
            } else if (status === 'fail') { // don't use "error" cause it is a predefined class from vuetify
                ret.color = 'red';
                ret.icon = 'mdi-exclamation-thick';
            } else if (status === 'loading') {
                ret.color = '#CFD8DC';
                ret.icon = 'mdi-loading';
            }
            return ret;
        },
        updateName(name) {
            this.name = name;
        },
        updateServices(services) {
            this.syncedData.services = services;
        },
        updateAssignments(assignments) {
            this.assignments = assignments;
        },
        saveVueComponent(component) {
            this.vueComponents[component.$options._componentTag] = component;
        },
        checkAllowEdit() {
            this.allowEdit = !(this.currentUser && this.role === 'vendor' && this.data && this.data.vendor && this.data.vendor.pending_at);
            this.initiallyAllowedEdit = !(this.currentUser && this.role === 'vendor' && this.data && this.data.vendor && this.data.vendor.pending_at);
        },
        enableEdit() {
            this.allowEdit = true;
            this.showAllowEditDialog = false;
        },
        continueEditing() {
            this.allowEdit = true;
            this.showVerifyOrContinueDialog = false;
        }
    },
    watch: {
        previousTab() {
            if (this.$route.name === 'index' && !this.saveDisabled) this.submit(true, this.previousTab);
        },
        activeTab(newValue, oldValue) {
            this.previousTab = oldValue;
            this.$store.commit('details/setActiveTab', this.tabs[newValue].component_name);

            let component = this.vueComponents[this.tabs[newValue].component_name];
            if (component && component.allReqFieldsNotFilled) {
                component.$refs.form.resetValidation();
            }
        },
        triggerInit() {
            this.init();
        },
        updated() {
            this.init();
        },
        currentUser(newVal) {
            if (newVal) this.updateTabs();
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .container {
    max-width: 750px;
}

::v-deep .sectionHeadline {
    user-select: none;
    font-family: 'Montserrat', 'Roboto', 'Arial', 'sans-serif' !important;
    font-weight: 600;
}

::v-deep {
    ::v-deep .v-stepper .v-stepper__label {
        color: $ada_darkGrey !important;
    }

    .v-stepper__step--inactive .v-stepper .v-stepper__label {
        color: rgba(57, 73, 86, 0.8) !important;
    }
}

::v-deep .mdi-loading::before {
    animation: rotation 0.7s infinite linear;
    -webkit-animation: rotation 0.7s infinite linear;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}
</style>