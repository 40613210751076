<template>
    <div class="container">
        <div class="sectionHeadline text-h6">Agency Assignment</div>
        <div class="mb-4">
            <p>Please use our drop-down menu to choose the agencies, which belong to your company. If an agency is
                missing, please ask them to register at: https://dgsvo.ad-alliance.de/</p>

            <p>For selected agencies the joint controller agreement can be concluded at a later stage - provided that
                an authorization is available.</p>
        </div>
        <v-form v-model="valid" ref="form">
            <Dropdown v-model="selectedAgencies" :items="singleAgencies" label="Agencies" item-text="name"
                      multiple item-value="id" :loading="loading" :disabled="$store.state.readOnly === 1"></Dropdown>

        </v-form>
    </div>
</template>

<script>
import Dropdown from "../../../components/Inputs/Dropdown";

export default {
    name: "Assignment",
    props: ['assignments', 'tabIndex', 'previousIndex', 'tabs'],
    components: {Dropdown},
    data: () => ({
        selectedAgencies: null,
        valid: null,
        loading: false,
        ignore: false,
    }),
    mounted() {
        this.init();
        this.$emit('mounted', this);
    },
    computed: {
        agencies() {
            return this.$store.state.agency_vendor.agencies;
        },
        singleAgencies() {
            let ret = JSON.parse(JSON.stringify(this.$store.getters['agency_vendor/singleAgencies']));
            if (this.agencies && this.assignments) {
                this.assignments.forEach((assignment) => {
                    ret.push(this.agencies.find(x => x.id === assignment.id));
                })
            }
            ret.sort();
            return ret;
        },
        hasChanges() {
            return this.$store.state.details.components.assignments.hasChanges;
        },
        role() {
            return this.$store.state.users.current ? this.$store.state.users.current.role : null;
        },
        og_assignments() {
            return this.role === 'admin' || this.role === 'user' ? this.$store.state.agency_vendor.selectedData.assignments : this.$store.state.details.data.agency.assignments;
        }
    },
    methods: {
        async init() {
            if (this.agencies === null && !this.$store.state.agency_vendor.fetching.agencies) {
                this.loading = true
                await this.$store.dispatch('agency_vendor/fetchAgencies');
                this.loading = false
            }
            if (this.assignments) {
                this.selectedAgencies = this.assignments;
                if (this.og_assignments !== 0) this.$emit('validate', 'Assignment');
            }
        }
    },
    watch: {
        assignments() {
            this.init()
            if (!this.$store.state.details.isRegistration) {
                setTimeout(() => {
                    this.$emit('validate', 'Assignment');
                }, 500);
            }
        },
        valid() {
            this.$emit('validate', 'Assignment');
        },
        selectedAgencies() {
            this.$emit('change:assignments', this.selectedAgencies);
            if (JSON.stringify(this.og_assignments) !== JSON.stringify(this.selectedAgencies)) {
                if (!this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'assignments', value: true});
            } else {
                if (this.hasChanges)
                    this.$store.commit('details/updateHasChanges', {section: 'assignments', value: false});
            }
        }
    },
}
</script>

<style scoped>
::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
    min-height: 60px;
}
</style>