import Repository from "../Repository";

export default {
    getPublicVendors() {
        return Repository.get('/public/vendors');
    },
    getVendors(params = {}) {
        return Repository.get('/vendors', {
            params: params
        });
    },
    getInactiveVendors() {
        return Repository.get('/vendors/inactive');
    },
    getAgencies(params = {}) {
        return Repository.get('/agencies', {
            params: params
        });
    },
    saveVendorRelation(json) {
        return Repository.post('/agencies/relation', json);
    },
    updateVendor(id, json) {
        return Repository.put('/vendors/' + id, json);
    },
    deleteVendorRelation(json) {
        return Repository.delete('/agencies/relation', {
            data: json
        });
    },
    startVendorVerify(id, json) {
        return Repository.post('/vendors/verify/start/' + id, json)
    },
    verifyVendor(id, json) {
        return Repository.post('/vendors/verify/' + id, json);
    },
    unverifyVendor(id, json) {
        return Repository.post('/vendors/verify/revoke/' + id, json);
    },
    rejectVerification(id, json) {
        return Repository.post('/vendors/verify/reject/' + id, json);
    },
    getHistory(params) {
        return Repository.get('/history', {
            params: params
        });
    },
    exportVendors() {
        return Repository.get('/vendors/export', {responseType: 'blob'});
    },
    exportVendorRelations() {
        return Repository.get('/vendors/export/relations', {responseType: 'blob'});
    },
    exportAgencies() {
        return Repository.get('/agencies/export', {responseType: 'blob'});
    },
    exportAgencyRelations() {
        return Repository.get('/agencies/export/relations', {responseType: 'blob'});
    },
    downloadJCA(lang) { //lang = ['de', 'en']
        return Repository.get('/download/jca/' + lang, {responseType: 'blob'});
    }
}