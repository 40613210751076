<template>
    <div class="container">
        <h2 class="header">Welcome !</h2>
        <div class="content py-6">
            <div>
                <p>Dear visitor,</p>

                <p>welcome to the GDPR registration website of Ad Alliance. As you are an advertising agency, advertiser
                    and/or tech vendor in business with Ad Alliance, we need you to verify yourself on this website.</p>

                <p>Because of GDPR and Bertelsmann-specific requirements, information about e.g. data collection, data
                    storage and data handling is required in order to book campaigns at Ad Alliance. Campaign trackings
                    will be only accepted, if a vendor is registered and approved by Ad Alliance. <br>
                    Our current vendor list within the CMP, can always be looked up here:</p>
                <p>
                    <v-btn class="mr-4" :color="ada.pink" dark depressed @click="openVendorList">
                        Show Vendor List
                    </v-btn>
                </p>

                <p class="mt-8">Please note, that this is mandatory for running campaigns with us. <br>Only IAB TCF
                    registered vendors can continue and request an approval.
                </p>
            </div>
            <div class="mt-10">
                <v-btn v-if="!($store.state.users.current !== null || ssoUser !== null)" class="mr-4" :color="ada.pink"
                       dark
                       depressed @click="openLoginDialog(false)">
                    Sign in
                </v-btn>
                <v-btn :color="ada.blue" dark depressed @click="openLoginDialog(true)">
                    Register
                </v-btn>
            </div>
        </div>

        <Dialog v-model="showVendorsDialog" title="Vendors" hide-buttons show-close-button
                width="900px">
            <Datatable :headers="headers" :items="vendor_list" style="width:100%" dense
                       :loading="$store.state.agency_vendor.fetching.public"></Datatable>
            <!--            <Datatable :headers="headers" :items="vendors" style="width:100%" dense
                                   :loading="$store.state.agency_vendor.fetching.public"></Datatable>-->
        </Dialog>

        <LoginRegisterDialog v-model="showLoginDialog" :isRegister="register"
                             @click:outside="deactivateBlur" @change:register="register = $event"></LoginRegisterDialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss';
import LoginRegisterDialog from "./LoginRegisterDialog";
import Dialog from "../components/Dialog";
import Datatable from "../components/Tables/Datatable";
import {vendors} from '../scripts/vendors';

export default {
    name: 'Home',
    components: {Datatable, Dialog, LoginRegisterDialog},
    data: () => ({
        ada,
        showLoginDialog: false,
        showVendorsDialog: false,
        register: false,
        vendors: [],
        headers: [
            {text: 'Vendor', value: 'name', width: '300px'},
            {text: 'TCF v2.2 ID', value: 'tcf_id', width: '120px'}
        ],
        get ssoUser() {
            return localStorage.getItem('ssoUser');
        },
    }),
    beforeRouteLeave(to, from, next) {
        this.deactivateBlur();
        next();
    },
    mounted() {
        if (this.$store.state.response && this.$store.state.response.callback) {
            if (this[this.$store.state.response.callback]) {
                if (this.$store.state.response.callback === "openLoginDialog") {
                    this.$store.dispatch('logout', true);
                }
                this[this.$store.state.response.callback]();
            }
        }
        if (this.vendors.length === 0) this.fetchPublicVendors();
        this.$store.dispatch('users/getCurrentUser');
        setTimeout(() => {
            this.$store.dispatch('users/getCurrentUser');
        }, 5000)
    },
    computed: {
        vendor_list() {
            let ret = [];
            if (vendors) {
                ret = vendors.filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.name === value.name && t.tcf2 === value.tcf2 && t.privacyUrl === value.privacyUrl
                        ))
                )
            }
            return ret;
        },
    },
    methods: {
        async fetchPublicVendors() {
            await this.$store.dispatch('agency_vendor/fetchPublicVendors').then((res) => {
                if (res && res !== "") this.vendors = res;
            });
        },
        openLoginDialog(register) {
            if (!this.$parent.$refs.main.classList.contains('blur'))
                this.$parent.$refs.main.classList.add('blur');
            this.register = register;
            this.showLoginDialog = true;
        },
        deactivateBlur() {
            if (this.$parent.$refs.main.classList.contains('blur'))
                this.$parent.$refs.main.classList.remove('blur');
        },
        openVendorList() {
            this.showVendorsDialog = true;
        },
    },
}
</script>

<style lang="scss" scoped>
.container {
    max-width: 1000px;
}
</style>