<template>
    <div>
        <v-data-table
            :loading="loading || $store.state.agency_vendor.fetching.agencies"
            :headers="headers"
            :items="items"
            :search="search"
            no-data-text="None"
            no-results-text="No Data"
            @click:row="$emit('click:edit', $event)"
        >
            <template v-slot:progress>
                <v-progress-linear indeterminate :color="ada.pink" class="d-flex" height="3"></v-progress-linear>
            </template>
            <template v-slot:top>
                <div class="d-flex align-center justify-space-between mx-4">
                    <div class="d-flex align-center flex-row">
                        <div class="pr-6 text-h5 font-weight-regular">Registered Agencies</div>
                        <v-text-field
                            v-model="search"
                            label="Search..."
                            append-icon="mdi-magnify"
                            class="mx-4 pt-5"
                            :color="ada.blue"
                            :style="{width: $vuetify.breakpoint.mdAndUp ? '300px' : '200px'}"
                        ></v-text-field>
                    </div>

                    <div>
                        <Button icon="mdi-microsoft-excel" color="#008001" @click="$emit('click:export')">
                            Export Excel
                        </Button>
                    </div>
                </div>
            </template>
            <!-- IS GROUP COL -->
            <template v-slot:item.is_group="{ item }">
                <v-icon :color="item.is_group === 1 ? 'green' : '#d0d4da'"
                >
                    {{ item.is_group === 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
            <!-- RELATED VENDORS COL -->
            <template v-slot:item.vendors="{ item }">
                <div @click.stop="openVendorsDialog(item)">{{ item.vendors ? item.vendors.length : '' }}</div>
            </template>
            <!-- JCA COL -->
            <template v-slot:item.has_jca="{ item }">
                <v-icon v-if="item.has_jca" :color="item.has_jca === 1 ? 'green' : 'red'"
                >
                    {{ item.has_jca === 1 ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
            <!-- ACTION COL-->
            <template v-slot:item.action="{ on, item }">
                <v-tooltip top v-for="(action, i) in item.action" :key="'action_'+i">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="actionIcon"
                            :data-type="action" :color="ada.grey"
                            @click.stop="$emit('click:'+action, item)">
                            {{ getAction(action).icon }}
                        </v-icon>
                    </template>
                    <span>{{ getAction(action).tooltip }}</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <Dialog v-model="showVendorsDialog" :title="(selected ? selected.name : 'Vendor') + ' relations'"
                show-close-button
                hide-buttons width="650px">
            <Datatable :headers="relation_headers" :items="selected ? selected.vendors : []"
                       title="Registered Relations" item-slot="item.details.jca">
                <template #item.details.jca="item">
                    <v-icon v-if="item.details" :color="item.details.jca === 1 ? 'green' : 'red'"
                    >
                        {{ item.details && item.details.jca === 1 ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
            </Datatable>
        </Dialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Dialog from "../../components/Dialog";
import Datatable from "../../components/Tables/Datatable";
import Button from "../../components/buttons/Button";

export default {
    name: "AgencyTable",
    components: {Button, Datatable, Dialog},
    data: () => ({
        loading: false,
        headers: [
            {text: 'Is a group', value: 'is_group', width: '120px', align: 'center'},
            {text: 'Agency', value: 'name', width: '350px'},
            {text: 'Country', value: 'details.country', width: '150px'},
            {text: 'Related vendors', value: 'vendors', align: 'center', width: '250px'},
            {text: 'JCA', value: 'has_jca'},
            {text: 'Actions', value: 'action', width: '100px', align: 'center', sortable: false},
        ],
        search: null,
        showVendorsDialog: false,
        selected: null,
        relation_headers: [
            {text: 'Vendor', value: 'name'},
            {text: 'Country', value: 'details.country'},
            {text: 'TCF v2.2 ID', value: 'details.tcf_id'},
            {text: 'JCA', value: 'jca'},
        ],
        ada
    }),
    mounted() {
        this.init();
    },
    computed: {
        agencies() {
            return this.$store.state.agency_vendor.agencies;
        },
        items() {
            let ret = [];
            if (this.agencies) {
                this.agencies.forEach((item) => {
                    let tmp = item;
                    tmp.action = ['edit'];

                    let jcaVendor = this.checkJCA(item);
                    tmp.has_jca = jcaVendor.has_jca;
                    tmp.jca_through = jcaVendor.jca_through;

                    ret.push(tmp);
                })
            }
            return ret;
        }
    },
    methods: {
        init() {
            this.fetchAgencies();
        },
        async fetchAgencies() {
            this.loading = true;
            await this.$store.dispatch('agency_vendor/fetchAgencies', {withRelations: true});
            this.loading = false;
        },
        getAction(action) {
            let ret = {
                tooltip: '',
                icon: ''
            };
            if (action === 'edit') {
                ret.tooltip = this.$store.state.readOnly !== 1 ? 'Edit' : 'Show details';
                ret.icon = this.$store.state.readOnly !== 1 ? 'mdi-pencil' : 'mdi-magnify-scan';
            } else if (action === 'delete') {
                ret.tooltip = 'Delete';
                ret.icon = 'mdi-delete';
            }
            return ret;
        },
        actionClick(action, data) {
            if (action === 'edit') {
                this.openRelationDialog(data);
            } else if (action === 'delete') {
                this.openRelationDialog(data, true);
            }
        },
        openVendorsDialog(agency) {
            this.selected = agency;
            this.showVendorsDialog = true;
        },
        checkJCA(agency) {
            if (agency.is_group === 0) {
                //chekc if agencies can inherit from agency group
                if (agency.parent && agency.parent.details && (agency.parent.details.jca === 1 || agency.parent.details.other_jca === 1)) {
                    return {
                        has_jca: 1,
                        jca_through: agency.parent.name
                    }
                }
            }
            return {
                has_jca: (agency.details && (agency.details.jca === 1 || agency.details.other_jca === 1)) ? 1 : 0,
                jca_through: null
            }
        }
    },
}
</script>

<style scoped>

</style>