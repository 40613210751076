import UserRepository from "./repositories/user";
import AgencyVendorRepository from "./repositories/agency_vendor";
import DetailRepository from "./repositories/details";
import ExportRepository from "./repositories/export";

const repositories = {
    users: UserRepository,
    agency_vendor: AgencyVendorRepository,
    details: DetailRepository,
    export: ExportRepository,
};

export const RepositoryFactory = {
    get: name => repositories[name]
};