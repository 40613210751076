<template>
    <Dialog v-bind:value="value"
            @input="$emit('input', $event)"
            :title="details ? 'Edit relation' : 'Add new relation'"
            :hide-buttons="$store.state.readOnly === 1"
            width="700px" :loading="loading.save" @ok="submit" @cancel="$emit('input', false)"
    >
        <v-form v-model="valid" ref="form" class="mt-2">
            <Dropdown v-model="data.vendor_id" :items="vendors" label="Tech Vendor" item-text="name"
                      :return-object="false" :loading="$store.state.agency_vendor.fetching.vendors"
                      :disabled="$store.state.readOnly === 1"
                      item-value="id" :rules="rules.vendor" hint="The listed vendors are not necessarily verified">
            </Dropdown>
            <Dropdown v-model="data.agency_id" :items="agencies" label="Agency" item-text="name"
                      class="mt-2"
                      :return-object="false" :disabled="$route.name === 'index' || $store.state.readOnly === 1"
                      :loading="$store.state.agency_vendor.fetching.agencies"
                      item-value="id" :rules="rules.agency"></Dropdown>
            <v-slide-y-transition>
                <v-row no-gutters v-if="data.vendor_id">
                    <v-col>
                        <div class="subtitle-1">Are you authorized to represent
                            {{
                                vendors.find(x => x.id === data.vendor_id) ? vendors.find(x => x.id === data.vendor_id).name : 'the vendor'
                            }}
                            ? (i.e. JCA)
                        </div>
                        <v-radio-group v-model="data.authorized" row :disabled="$store.state.readOnly === 1">
                            <v-radio v-for="item in yesNo_options" :key="item.label"
                                     :color="ada.pink" class="mr-8"
                                     :label="item.label"
                                     :value="item.value"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </v-slide-y-transition>
            <v-row no-gutters>
                <v-col>
                    <div class="subtitle-1">DPA signed</div>
                    <v-radio-group v-model="data.dpa_signed" row :disabled="$store.state.readOnly === 1">
                        <v-radio v-for="item in dpa_options" :key="item.label"
                                 :color="ada.pink" class="mr-8"
                                 :label="item.label"
                                 :value="item.value"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <div class="subtitle-1">Type of relationship</div>
                    <v-radio-group v-model="data.type" row :disabled="$store.state.readOnly === 1">
                        <v-radio v-for="item in type_options" :key="item.label"
                                 :color="ada.pink" class="mr-8"
                                 :label="item.label"
                                 :value="item.value"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <div class="subtitle-1">Does the vendor provide you with data?</div>
                    <v-radio-group v-model="data.data_provided" row :disabled="$store.state.readOnly === 1">
                        <v-radio v-for="item in yesNo_options" :key="item.label"
                                 :color="ada.pink" class="mr-8"
                                 :label="item.label"
                                 :value="item.value"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-slide-y-transition hide-on-leave>
                        <Textarea v-if="data.data_provided === 1" class="mt-2 mb-4" rows="5"
                                  :disabled="$store.state.readOnly === 1"
                                  v-model.trim="data.data_provided_description"
                                  :rules="data.data_provided === 1 ? rules.data_provided_description : []"
                                  label="Description of provided data"></Textarea>
                    </v-slide-y-transition>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <div class="subtitle-1">If this data includes personal data (i.e. UserCookieId, IP, IDFA) is it
                        further processed in your company?
                    </div>
                    <v-radio-group v-model="data.personal_data_processed" row :disabled="$store.state.readOnly === 1">
                        <v-radio v-for="item in yesNo_options" :key="'pd_'+item.label"
                                 :color="ada.pink" class="mr-8"
                                 :label="item.label"
                                 :value="item.value"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">
                    <div class="subtitle-1">How long is the data stored?</div>
                </v-col>
                <v-col cols="4">
                    <Input class="mt-2 mr-4" v-model.trim="data.storage_duration"
                           label="Storage duration"
                           :disabled="$store.state.readOnly === 1"
                           type="number"></Input></v-col>
                <v-col cols="3">
                    <Dropdown v-model="data.storage_unit" :items="$store.state.details.units" label="Unit"
                              :return-object="false" hide-details class="mt-2"></Dropdown>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <div class="subtitle-1">Relevance of vendor for your company</div>
                    <v-radio-group v-model="data.relevance" row :disabled="$store.state.readOnly === 1">
                        <v-radio v-for="item in relevance_options" :key="item.label"
                                 :color="ada.pink" class="mr-8"
                                 :label="item.label"
                                 :value="item.value"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <div class="subtitle-1">Vendor is used for</div>
                    <v-checkbox v-for="(item, i) in checkboxes" :key="item.label"
                                v-model="data[item.vModel]"
                                :label="item.label" :hide-details="i+1 !== checkboxes.length"
                                :color="ada.pink" :rules="rules.type"
                                :disabled="$store.state.readOnly === 1"
                                :true-value="1" :false-value="0"
                                @change="resetValidation"
                    ></v-checkbox>
                </v-col>
            </v-row>
        </v-form>
    </Dialog>
</template>

<script>
import ada from '@/scss/variables.scss'
import Dialog from "../../components/Dialog";
import Dropdown from "../../components/Inputs/Dropdown";
import Input from "../../components/Inputs/Input";
import Textarea from "../../components/Inputs/Textarea";

export default {
    name: "AddEditRelationDialog",
    components: {Textarea, Input, Dropdown, Dialog},
    props: {
        value: {},
        details: {
            type: Object
        },
        relations: Array
    },
    data: () => ({
        valid: null,
        ada,
        data: {
            vendor_id: null,
            agency_id: null,
            authorized: 0,
            dpa_signed: 1,
            type: 'processor',
            data_provided: 0,
            data_provided_description: null,
            personal_data_processed: 1,
            storage_duration: null,
            relevance: 'critical',
            in_stream: 0,
            out_stream: 0,
            in_page: 0,
            ctv: 0,
        },
        checkboxes: [
            {label: 'In-Stream', vModel: 'in_stream'},
            {label: 'Out-Stream', vModel: 'out_stream'},
            {label: 'In-Page', vModel: 'in_page'},
            {label: 'CTV', vModel: 'ctv'},
        ],
        dpa_options: [
            {label: 'Signed', value: 1},
            {label: 'Not Signed', value: 0},
        ],
        type_options: [
            {label: 'Processor', value: 'processor'},
            {label: 'Controller', value: 'controller'},
            {label: 'Joint Controller', value: 'joint controller'},
        ],
        yesNo_options: [
            {label: 'Yes', value: 1},
            {label: 'No', value: 0}
        ],
        relevance_options: [
            {label: 'Critical', value: 'critical'},
            {label: 'Very frequent use', value: 'very frequent use'},
            {label: 'Frequent use', value: 'frequent use'},
            {label: 'Rare use', value: 'rare use'}
        ],
        loading: {
            save: false,
            agency: false,
        }
    }),
    mounted() {
        this.init();
    },
    computed: {
        vendors() {
            if (this.$store.state.agency_vendor.vendors) {
                //only verified vendors
                let ret = this.$store.state.agency_vendor.vendors.filter(x => x.is_complete === 1);
                if (this.relations) {
                    //remove vendors where a relation already exists
                    if (this.$route.name !== 'admin') {
                        for (let i = 0; i < this.relations.length; i++) {
                            if (((this.details && this.relations[i].id !== this.details.id) || this.details === null) &&
                                ret.findIndex(x => x.id === this.relations[i].id) !== -1) {
                                ret.splice(ret.findIndex(x => x.id === this.relations[i].id), 1);
                                i--;
                            }
                        }
                    }
                }
                return ret;
            }
            return this.$store.state.agency_vendor.vendors;
        },
        agencies() {
            return this.$store.state.agency_vendor.agencies;
        },
        rules() {
            return {
                vendor: [v => !!v || 'Vendor is required'],
                agency: [v => !!v || 'Agency is required'],
                data_provided_description: [v => !!v || 'Description is required'],
                type: [this.vendorTypeSelected || 'Please select at least one'],
            }
        },
        vendorTypeSelected() {
            return this.data.in_stream === 1 ||
                this.data.out_stream === 1 ||
                this.data.in_page === 1 ||
                this.data.ctv === 1;
        },
        errorCount() {
            return this.$store.state.errorCount;
        }
    }
    ,
    methods: {
        init() {
            this.fetchVendors();
            this.$emit('fetchAgencies', false);
            if (this.details) {
                this.data = JSON.parse(JSON.stringify(this.details.pivot));
            } else {
                this.data = {
                    vendor_id: null,
                    agency_id: this.$store.state.details.data && this.$store.state.details.data.agency ? this.$store.state.details.data.agency.id :
                        (this.$store.state.agency_vendor.selectedData ? this.$store.state.agency_vendor.selectedData.id : null),
                    authorized: 0,
                    dpa_signed: 1,
                    type: 'processor',
                    data_provided: 0,
                    data_provided_description: null,
                    personal_data_processed: 1,
                    storage_duration: null,
                    relevance: 'critical',
                    in_stream: 0,
                    out_stream: 0,
                    in_page: 0,
                    ctv: 0,
                };
            }
        }
        ,
        async fetchVendors() {
            if (this.$store.state.agency_vendor.vendors === null && !this.$store.state.agency_vendor.fetching.vendors) {
                await this.$store.dispatch('agency_vendor/fetchVendors');
            }
        }
        ,
        async submit() {
            await this.validate();
            if (this.valid) {
                this.loading.save = true;
                let ret = {
                    old_vendor_id: this.details ? this.details.pivot.vendor_id : null,
                    old_agency_id: this.details ? this.details.pivot.agency_id : null,
                    relation: this.data
                }
                await this.$store.dispatch('agency_vendor/saveVendorRelation', ret).then((res) => {
                    if (res !== "") {
                        if (this.$route.name === 'admin') {
                            this.$emit('fetchAgencies', true);
                        } else if (this.$route.name === 'index') {
                            this.$emit('fetchDetails');
                        }
                        this.$root.$emit('snackbar', 'Relationship successfully saved', 'success');
                        this.$emit('input', false);
                    }
                });
                this.loading.save = false;
            }
        }
        ,
        async validate() {
            if (this.$refs.form) await this.$refs.form.validate();
            if (!this.valid) this.$root.$emit('snackbar', 'Please make sure all fields are filled in correctly', 'warning')
        }
        ,
        resetValidation() {
            if (this.$refs.form) this.$refs.form.resetValidation();
        }
    }
    ,
    watch: {
        details() {
            this.init();
        }
        ,
        errorCount() {
            if (this.loading.save) this.loading.save = false;
            if (this.loading.vendors) this.loading.vendors = false;
            if (this.loading.agencies) this.loading.agencies = false;
        }
        ,
        value(newValue) {
            if (newValue) {
                this.resetValidation();
                this.init()
            }
        }
    }
    ,
}
</script>

<style scoped>
.subtitle-1 {
    position: relative;
    top: 10px;
    font-size: 1rem !important;
    font-weight: 500;
}
</style>
