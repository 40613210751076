<template>
    <div class="d-flex flex-column">
        <v-tooltip :top="!right && !left" :right="right" :left="left" v-bind="$attrs">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :color="color"
                    depressed
                    :elevation=0
                    :disabled="disabled"
                    class="white--text"
                    :loading="loading"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('click', $event)"
                >
                    <v-icon v-if="icon"
                            left
                            color="white"
                    >
                        {{ icon }}
                    </v-icon>
                    <slot></slot>
                </v-btn>
            </template>
            <span v-html="tooltip"></span>
        </v-tooltip>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'

export default {
    name: "TooltipButton",
    props: {
        color: {default: ada.pink},
        tooltip: {type: String, required: true},
        loading: {type: Boolean, default: false},
        right: {default: false},
        left: {default: false},
        disabled: {default: false},
        icon: {type: String},
    },
    data: () => ({
        ada,
    }),
}
</script>

<style lang="scss" scoped>
</style>