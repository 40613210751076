import {RepositoryFactory} from "../../scripts/RepositoryFactory";
import Vue from "vue";

const AgencyVendorRepository = RepositoryFactory.get('agency_vendor');

const state = {
    data: null,
    inactiveVendors: [],
    vendors: null,
    agencies: null,
    fetching: {
        vendors: false,
        agencies: false,
        history: false,
        public: false,
        inactive: false,
    },
    selectedData: null, //selected Agency or Vendor,
    selectedRole: null, //'vendor' or 'agency'
};

// getters
const getters = {
    singleAgencies() {
        let ret = [];
        if (state.agencies) {
            state.agencies.forEach((agency) => {
                if (agency.is_group === 0 && agency.group_id === null) {
                    ret.push(agency);
                }
            })
        }
        return ret;
    }
};

// actions
const actions = {
    async fetchPublicVendors() {
        state.fetching.public = true;
        let {data} = await AgencyVendorRepository.getPublicVendors();
        state.fetching.public = false;
        return data;
    },
    async fetchHistory({commit}, payload) {
        state.fetching.history = true;
        let {data} = await AgencyVendorRepository.getHistory(payload);
        if (data) {
            commit('setHistory', data);
        }
        state.fetching.history = false;
        return data;
    },
    async fetchVendors({state, commit}, payload) {
        state.fetching.vendors = true;
        let params = typeof payload === 'undefined' || payload.returnOnly ? {} : payload;
        let {data} = await AgencyVendorRepository.getVendors(params);
        state.fetching.vendors = false;
        if (payload && payload.returnOnly) {
            return data;
        } else if (data) {
            commit('setVendors', data);
        }
    },
    async fetchInactiveVendors({state, commit}) {
        state.fetching.inactive = true;
        let {data} = await AgencyVendorRepository.getInactiveVendors();
        state.fetching.inactive = false;
        commit('setInactiveVendors', data);
    },
    async fetchAgencies({state, commit}, payload) {
        state.fetching.agencies = true;
        let params = typeof payload === 'undefined' || payload.returnOnly ? {} : payload;
        let {data} = await AgencyVendorRepository.getAgencies(params);
        state.fetching.agencies = false;
        if (payload && payload.returnOnly) {
            return data;
        } else if (data) {
            commit('setAgencies', data);
        }
    },
    async startVendorVerify({commit, rootState, dispatch}, payload) {
        let json = {user_id: rootState.users.current.id};
        let {data} = await AgencyVendorRepository.startVendorVerify(payload, json);
        if (rootState.users.current.role === 'admin') {
            fetchHistoryAndUpdateData(data, commit, dispatch);
        } else if (rootState.users.current.role === 'vendor') {
            commit('details/setDetails', data, {root: true});
        }
    },
    async verifyVendor({commit, rootState, dispatch}, payload) {
        let json = {user_id: rootState.users.current.id};
        let {data} = await AgencyVendorRepository.verifyVendor(payload, json);
        if (rootState.users.current.role === 'admin' && data && data !== "") {
            dispatch('fetchHistory');
            commit('updateDataDetails', data);
        }
        return data;
    },
    async rejectVerification({commit, rootState, dispatch}, payload) {
        let json = {user_id: rootState.users.current.id, reason: payload.reason};
        let {data} = await AgencyVendorRepository.rejectVerification(payload.id, json);
        if (rootState.users.current.role === 'admin') fetchHistoryAndUpdateData(data, commit, dispatch);
        return data;
    },
    async unverifyVendor({commit, rootState, dispatch}, payload) {
        let json = {user_id: rootState.users.current.id, reason: payload.reason};
        let {data} = await AgencyVendorRepository.unverifyVendor(payload.id, json);
        if (rootState.users.current.role === 'admin') fetchHistoryAndUpdateData(data, commit, dispatch);
        return data;
    },
    async saveVendorRelation({commit, rootState, dispatch}, payload) {
        let {data} = await AgencyVendorRepository.saveVendorRelation(payload);
        handleRelationRes(data, commit, dispatch, rootState);
        return data;
    },
    async deleteVendorRelation({commit, rootState, dispatch}, payload) {
        let {data} = await AgencyVendorRepository.deleteVendorRelation(payload);
        handleRelationRes(data, commit, dispatch, rootState);
        return data;
    },
    async updateVendor({commit, dispatch}, payload) {
        let {data} = await AgencyVendorRepository.updateVendor(payload.id, payload);
        if (data !== "") {
            dispatch('fetchHistory');
            commit('updateDataDetails', data);
        }
        return data;
    },
    async exportVendors() {
        let {data} = await AgencyVendorRepository.exportVendors();
        return data;
    },
    async exportAgencies() {
        let {data} = await AgencyVendorRepository.exportAgencies();
        return data;
    },
    async exportAgencyRelations() {
        let {data} = await AgencyVendorRepository.exportAgencyRelations();
        return data;
    },
    async exportVendorRelations() {
        let {data} = await AgencyVendorRepository.exportVendorRelations();
        return data;
    },
    async downloadJCA(context, payload) {
        let {data} = await AgencyVendorRepository.downloadJCA(payload);
        return data;
    }
};

// mutations
const mutations = {
    setData(state, data) {
        state.data = data;
    },
    setVendors(state, data) {
        state.vendors = data;
    },
    setInactiveVendors(state, data) {
        state.inactiveVendors = data;
    },
    setAgencies(state, data) {
        state.agencies = data;
    },
    setSelectedData(state, data) {
        state.selectedData = data ? data.data : null;
        state.selectedRole = data ? data.role : null;
    },
    setHistory(state, data) {
        state.history = data;
    },
    updateDataDetails(state, data) {
        let currentRole = state.selectedRole === 'vendor' ? 'vendors' : 'agencies';
        let index = state[currentRole].findIndex(x => x.id === data.id);
        if (state.selectedData.id === data.id && state.selectedData.created_at === data.created_at) {
            state.selectedData = data;
        }
        Vue.set(state[currentRole], index, data);
    },
    resetAll(state) {
        state.data = null;
        state.vendors = null;
        state.agencies = null;
        state.selectedData = null;
        state.selectedRole = null;
    }
};

function handleRelationRes(data, commit, dispatch, rootState) {
    if (data !== "") {
        if (rootState.users.current.role === 'admin') {
            if (state.selectedData !== null) {
                commit('updateDataDetails', data);
            } else {
                dispatch('fetchVendors', {withRelations: true});
                dispatch('fetchAgencies', {withRelations: true});
            }
        } else {
            commit('details/setData', data, {root: true});
        }
        //commit('details/resetHasChanges', false, {root: true});
        commit('details/resetHasChanges', null, {root: true});
    }
}

function fetchHistoryAndUpdateData(data, commit, dispatch) {
    if (data && data !== "") {
        dispatch('fetchHistory');
        commit('updateDataDetails', data);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}