<template>
    <div>
        <Dialog v-bind:value="value" @input="$emit('input', $event)"
                :width="$store.state.agency_vendor.selectedRole === 'agency' && $store.state.details.activeTab === 'Relations' ? '100%' : '1000px'"
                :title="title"
                hide-buttons
                show-close-button>
            <div style="position: relative">
                <v-row v-if="$store.state.agency_vendor.selectedRole === 'vendor'" class="mb-4">
                    <v-col :cols="$store.state.readOnly !== 1 ? 11 : 12">
                        <Textarea v-model="comment" label="Comment" rows="2"
                                  :disabled="$store.state.readOnly === 1"></Textarea>
                    </v-col>
                    <v-col cols="1" class="d-flex align-center" v-if="$store.state.readOnly !== 1">
                        <TooltipIcon fab tooltip="Save comment" :color="ada.blue" :loading="loading.submit"
                                     @click="saveComment"
                                     :disabled="commentSaveDisabled">
                            mdi-content-save
                        </TooltipIcon>
                    </v-col>
                </v-row>
                <RegistrationForm :data="data" :trigger-init="triggerInit" ref="registrationForm"></RegistrationForm>
                <div v-if="$store.state.agency_vendor.selectedRole === 'vendor' && $store.state.readOnly !== 1"
                     style="position: absolute; right: 0; bottom:16px">
                    <div v-if="data ? data.verified_at === null : false" class="d-flex align-center">
                        <TooltipButton :color="ada.blue" icon="mdi-check" class="mr-4"
                                       @click="verifyVendor" :loading="loading.verify"
                                       tooltip="Add this vendor to the public vendor list"
                        >
                            Verify
                        </TooltipButton>
                        <div v-if="data ? data.pending_at !== null : false" class="d-flex flex-row align-center">
                            <TooltipButton :color="ada.pink" icon="mdi-close" :loading="loading.reject"
                                           @click="showRejectVerificationDialog = true"
                                           tooltip="Reject this vendor with a reason"
                            >
                                Reject
                            </TooltipButton>
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <TooltipButton :color="ada.blue" icon="mdi-check" class="mr-4"
                                       v-if="data.pending_at && data.verified_at && data.pending_at > data.verified_at"
                                       @click="verifyVendor" :loading="loading.verify"
                                       tooltip="Verify the information. Vendor is already in public list."
                        >
                            Verify
                        </TooltipButton>
                        <TooltipButton v-if="data ? data.verified_at !== null : false" :top="true" :color="ada.red"
                                       @click="showRejectVerificationDialog = true" :loading="loading.reject"
                                       tooltip="Remove this vendor from the public vendor list"
                        >
                            Unverify Vendor
                        </TooltipButton>
                    </div>
                </div>
            </div>
        </Dialog>
        <Dialog v-model="showRejectVerificationDialog" title="Reject verification" ok-text="Yes, reject"
                @ok="rejectVerification" width="900px" @cancel="showRejectVerificationDialog = false"
                :align-btns-left="true">
            <v-row>
                <v-col>
                    <p>Are you sure that you want to reject <b>{{ data ? data.name : '' }}</b> ?
                    </p>
                    <p>The vendor will receive an email that they were rejected.</p>
                    <Textarea v-model="reason" label="Rejection reason ?" rows="12" class="mb-4"
                              hint="You can use basic HTML to format the text. Use <br> to add a new line."></Textarea>
                </v-col>
                <v-col>
                    <div style="border-left:1px solid #ccc;padding-left:20px; overflow: auto; max-height: 60vh;">
                        <div class="font-weight-bold mb-4">EMAIL PREVIEW</div>
                        <p>Dear {{ data ? data.name : '' }},</p>
                        <p>our top priority is the protection of our users’ data. In connection with this, we try to
                            keep the number of integrated vendors as low as possible. <br>
                        </p>
                        <p>Therefore, after careful consideration, we cannot consider you for our vendorlist.
                        </p>
                        <p v-if="reason" v-html="reason"></p>
                        <p>Please don’t hesitate to contact us at consent@ad-alliance.de, if you want to work on the
                            feedback above.</p>
                        <p>Yours,<br>
                            Ad Alliance GDPR Team</p>
                    </div>
                </v-col>
            </v-row>
        </Dialog>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Dialog from "../../components/Dialog";
import RegistrationForm from "../private/RegistrationForm";
import Textarea from "../../components/Inputs/Textarea";
import TooltipIcon from "../../components/buttons/TooltipIcon";
import Utils from "../../scripts/Utils";
import TooltipButton from "../../components/buttons/TooltipButton";

export default {
    name: "DetailsDialog",
    props: ['value', 'data'],
    components: {TooltipButton, TooltipIcon, Textarea, RegistrationForm, Dialog},
    data: () => ({
        ada,
        triggerInit: 0,
        loading: {
            submit: false,
            verify: false,
            reject: false,
        },
        reason: null,
        comment: null,
        showUnverifyDialog: false,
        showRejectVerificationDialog: false,
        commentSaveDisabled: false,
    }),
    mounted() {
        this.init();
    },
    computed: {
        title() {
            return `${Utils.capitalize(this.$store.state.agency_vendor.selectedRole)} Profile - ${this.data ? this.data.name : ''}`
        },
        errorCount() {
            return this.$store.state.errorCount;
        },
        vuexState() {
            return this.$store.state.details;
        }
    },
    methods: {
        init() {
            this.reason = null;
            this.comment = this.data ? this.data.comment : null;
        },
        async saveComment() {
            let ret = JSON.parse(JSON.stringify(this.data));
            ret.comment = this.comment ? this.comment.trim() : null;
            ret.user_id = this.$store.state.users.current.id;
            this.loading.submit = true;
            await this.$store.dispatch('agency_vendor/updateVendor', ret).then((res) => {
                if (res !== "") this.$root.$emit('snackbar', 'comment successfully saved')
            })
            this.loading.submit = false;
        },
        async verifyVendor() {
            this.loading.verify = true;
            await this.$store.dispatch('agency_vendor/verifyVendor', this.data.id).then((res) => {
                if (res !== "") {
                    let msg = typeof res.message !== "undefined" ? res.message : `<b>${this.data.name}</b> successfully verified`;
                    this.$root.$emit('snackbar', msg);
                }
            });
            this.loading.verify = false;
        },
        async rejectVerification() {
            this.loading.reject = true;
            await this.$store.dispatch('agency_vendor/rejectVerification', {
                id: this.data.id,
                reason: this.reason
            }).then((res) => {
                if (res !== "") {
                    this.reason = null;
                    this.showRejectVerificationDialog = false;
                    let msg = typeof res.message !== "undefined" ? res.message : `Rejection Mail sent`;
                    this.$root.$emit('snackbar', msg);
                }
            });
            this.loading.reject = false;
        },
        hasChanges() {
            return this.data ? (this.comment !== this.data.comment) : false;
        }
    },
    watch: {
        data(newValue) {
            if (newValue) this.triggerInit++;
            this.init();
        },
        errorCount() {
            if (this.loading.submit) this.loading.submit = false;
            if (this.loading.verify) this.loading.verify = false;
        },
        comment() {
            this.commentSaveDisabled = !this.hasChanges();
        },
        value(newValue) {
            if (!newValue) {
                this.$store.commit('details/setLang', 'en');
            }
        }
    },
}
</script>

<style scoped>

</style>