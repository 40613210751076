<template>
    <div>
        <div class="">
            <h2 class="header"> {{ title }} </h2>
            <div>
                <transition name="fade">
                    <div v-if="role==='vendor'" class="content py-6" style="max-width: 1000px;" :key="0">
                        <p>Please answer the questions about your business model and provide us with information we need
                            for GDPR requirements. <br>
                            The Ad Alliance will check your information and approve your company for
                            cooperation. <br>
                            Please note, that a registration for IAB TCF v2.2 is mandatory for working with
                            Ad Alliance.</p>
                        <p>Please keep your information up to date and edit the information in case of chances.</p>

                        <p>If you have any questions, please do not hesitate to contact us: consent@ad-alliance.de </p>
                    </div>
                    <div v-else style="max-width: 1000px;" :key="1" class="py-6">
                        <div>
                            <p>Please provide us with all information on the tech vendors that you are working with for
                                Ad Alliance campaigns.
                                <span v-if="currentUser ? currentUser.role === 'agency_group' : false">In addition please specify which
                                    agencies belong to your company.</span><br>
                                If you have any questions, please send us an email: consent@ad-alliance.de</p>
                            <p>If a tech vendor <span v-if="currentUser ? currentUser.role === 'agency_group' : false">or an agency</span>
                                is missing in the drop-down menu, please ask them to register at:
                                https://dsgvo.ad-alliance.de. <br>
                                Please keep your agency profile and vendor relations always up to date.</p>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="loading" class="d-flex align-center justify-center" style="min-height:300px" :key="0">
                <v-progress-circular :size="50" :width="3" indeterminate :color="ada.pink"></v-progress-circular>
            </div>
            <RegistrationForm v-else :data="data" :loading="loading" :registration="registration" :key="1"
                              :triggerInit="triggerInit"></RegistrationForm>
        </transition>
        <div v-if="localhost">
            <h4>LOCALHOST ONLY: this.currentUser</h4>
            <p class="mb-6">{{ currentUser }}</p>
            <h4>this.data</h4>
            <div>
                {{ data }}
            </div>
        </div>
        <Dialog v-model="showVerifyBlocker" width="550px" title="Verify reminder" hide-buttons persistent>
            <div class="mb-4 text-body-1">Don't forget to click on verify to start the verifying process! <br>
                The verify button will be enabled after you've finished filling out all the
                required fields.
            </div>
            <img src="../../assets/verify_blocker.jpg">
            <v-checkbox v-model="donotremindme" label="Do not remind me" :color="ada.pink">
            </v-checkbox>
            <Button text @click="hideVerifyBlocker" :color="ada.pink">ok</Button>
        </Dialog>
    </div>
</template>

<script>
import RegistrationForm from "./RegistrationForm";
import ada from '@/scss/variables.scss'
import Dialog from "../../components/Dialog";
import Button from "../../components/buttons/Button";

export default {
    name: "Index",
    components: {Button, Dialog, RegistrationForm},
    data: () => ({
        loading: true,
        registration: false,
        showVerifyBlocker: false,
        triggerInit: false,
        interval: null,
        donotremindme: false,
        ada
    }),
    mounted() {
        this.init();
        this.interval = setInterval(() => {
            this.fetchCurrentUser();
        }, 60000);//every min
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    computed: {
        vuexState() {
            return this.$store.state.users;
        },
        title() {
            if (this.data && this.data[this.role] && this.data[this.role].details !== null) {
                return 'Welcome ' + this.data[this.role].name + '!';
            }
            if (this.role === "vendor") return 'Tech Vendor Registration';
            if (this.role === "agency") return 'Agency Registration';
            return 'Registration';
        },
        data() {
            return this.$store.state.details.data;
        },
        currentUser() {
            return this.vuexState.current;
        },
        role() {
            let ret = this.currentUser ? this.currentUser.role : null;
            return ret === 'agency_group' ? 'agency' : ret;
        },
        localhost() {
            return location.href.indexOf('localhost') !== -1;
        }
    },
    methods: {
        init() {
            this.fetchCurrentUser(true);
        },
        initVerifyBlocker() {
            if (this.currentUser.role === 'vendor' && this.data.vendor.pending_at === null &&
                (localStorage.getItem('ada_vendor_tool_verify_donotremindme') === null ||
                    localStorage.getItem('ada_vendor_tool_verify_donotremindme') === "false")) {
                this.showVerifyBlocker = true;
            }
        },
        fetchCurrentUser(fetchdata) {
            this.$store.dispatch('users/getCurrentUser').then(() => {
                if (fetchdata) {
                    this.fetchData();
                }
            });
        },
        async fetchData() {
            if (this.data === null && this.currentUser && (this.currentUser.vendor_id || this.currentUser.agency_id)) {
                this.loading = true;
                await this.$store.dispatch('details/fetchData').then(() => {
                    this.initVerifyBlocker();
                    this.registration = this.data[this.role].details === null;
                    this.$store.commit('details/setRegistration', this.registration);
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        },
        hideVerifyBlocker() {
            if (this.donotremindme) {
                localStorage.setItem('ada_vendor_tool_verify_donotremindme', true);
            }
            this.showVerifyBlocker = false;
        }
    },
    watch: {
        data() {
            setTimeout(() => {
                this.triggerInit = true;
            }, 1500)
        }
    },
}
</script>

<style lang="scss" scoped>

</style>