<template>
    <div>
        <h2>Documentation</h2>
        <v-card flat class="mt-8 container">
            <v-row>
                <v-col cols="3">
                    <div class="nav-contaienr">
                        <div class="nav-item" @click="scrollToAnchorPoint('general')">General</div>
                        <div class="nav-item" @click="scrollToAnchorPoint('registration')">Registration process</div>
                        <div class="nav-item" @click="scrollToAnchorPoint('verification')">Vendor verification</div>
                        <div class="nav-item" @click="scrollToAnchorPoint('jca')">JCA</div>
                        <div class="nav-item" @click="scrollToAnchorPoint('users')">Users</div>
                    </div>
                </v-col>
                <v-col cols="9">
                    <div class="nav-content-container">
                        <div ref="general" class="nav-content">
                            <h2>General</h2>
                            <p>- tbd</p>
                        </div>
                        <div ref="registration" class="nav-content">
                            <h2>Registration process</h2>
                            <div class="section">
                                <h3>Vendors</h3>
                                <p>
                                    - Register -> fill out form -> request to be verified -> done
                                </p>
                            </div>
                            <div class="section">
                                <h3>Agency & Agency groups</h3>
                                <p>
                                    - Register -> fill out form -> agency groups have the option to assign not already
                                    assigned agencies to them -> agencies & agency groups have the option to create
                                    relation
                                    to vendor -> done
                                </p>
                            </div>
                        </div>
                        <div ref="verification" class="nav-content">
                            <h2>Vendor verification</h2>
                            <div class="mb-4">Only verified vendors will be listed in the public vendor list on the
                                homepage.
                            </div>
                            <div class="section">
                                <h3>Verify</h3>
                                <p>After vendor is requesting to be verified, a mail will be sent to
                                    consent@ad-alliance.de.</p>
                                <p>The mail contains a link to the admin dashboard, where the vendor can be verified
                                    (only by admins).</p>
                                <p>To do that: Click on the to be verified vendor in the registered vendors table ->
                                    dialog with vendor details will pop up -> click the verify button on the bottom
                                    right -> vendor is now verified and will be listed in the public vendor list</p>
                            </div>
                            <div class="section">
                                <h3>Unverify</h3>
                                <p>To undo that just click the 'unverify' button that will be displayed instead of the
                                    'verify'
                                    button -> vendor will immediately be deleted from the the public vendor list</p>
                            </div>
                            <div class="section">
                                <h3>Reject</h3>
                                <p>To reject a vendor (e.g. missing data, wrong data) -> click on reject button next to
                                    verify button in the bottom right corner.</p>
                                <p>Dialog will pop up -> there you can give a reason as to why you want to reject the
                                    vendor.</p>
                                <p>Theres an email preview on the right side of the dialog to check the mail.</p>
                            </div>
                        </div>
                        <div ref="jca" class="nav-content">
                            <h2>Joint Controller Agreement - JCA</h2>
                            <p>All user types (Vendors, Agencies, Agency groups) can consent to the Ad Alliance JCA.</p>
                            <p>- Agency groups can provide a JCA for Agencies</p>
                            <p>- Agencies can provide a JCA for Vendors</p>
                            <p>- JCA isn't required on any registration</p>
                            <br>
                            <p>After a successfull JCA agreement -> JCA will be generated in the background and a copy
                                will be sent to consent@ad-alliance.de.</p>
                            <p>Consent Team will be forwarding the JCA to another Team where they will be storing it
                                in a seperate database</p>
                        </div>
                        <div ref="users" class="nav-content">
                            <h2>Users</h2>
                            <div class="mb-4">
                                Users that havent verified their emails will be automatically deleted after 6 months.
                                Only SSO users can be admins / users.
                                <p>Current Roles: admin, user(same view as admin but no editing rights), vendors,
                                    agencies,
                                    agency groups</p>
                            </div>
                            <div class="section">
                                <h3>Add/Create User</h3>
                                <p>The general rule is one user per company. But in case
                                    another account is required the admin can create another account for the the
                                    company.</p>
                                <p>navigate to the maanagement page</p>
                                <p>to add an admin/user -> only sso user can be admins and users -> click on add user ->
                                    toggle the sso user button -> enter email address -> choose role -> click on save ->
                                    done</p>
                                <p>to add an account to an existing company (vendor/agency/agency group) -> click on add
                                    user
                                    -> enter email address -> choose a password or use the already entered one -> make
                                    sure to write it down before saving -> choose role -> save -> done -> Then you only
                                    have to notify the user that an account has been created and send their
                                    password with it, so they can log in -> they'll see the same company data, and both
                                    accounts will have the right to edit the information</p>
                                <p></p>
                            </div>
                            <div class="section">
                                <h3>Other Actions</h3>
                                <p> edit -> will edit user information (e.g. email) -> click on the pencil in the action
                                    column or simply click anywhere in the row</p>
                                <p> resend verify email -> will resend the 'welcome' mail that will prompt the user to
                                    verify their email address </p>
                                <p> reset password -> will send the user an email with instructions on how to reset
                                    their password</p>
                                <p> delete -> will remove user from the database -> cannot be undone</p>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>

    </div>
</template>

<script>
export default {
    name: "Documentation",
    data: function () {
        return {
            hash: this.$route.hash,
        }
    },
    mounted() {
        this.$store.dispatch('users/getCurrentUser');

        this.$nextTick(function () {
            if (this.hash) {
                const refName = this.hash.replace('#', '')
                this.scrollToAnchorPoint(refName)
            }
        })
    },
    methods: {
        scrollToAnchorPoint(refName) {
            const el = this.$refs[refName];
            const yOffset = -80;
            const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'})
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    color: $ada_darkGrey;

    h2, h3 {
        position: relative;
        font-weight: 400;

        &:before {
            content: '#';
            position: absolute;
            color: $ada_pink;
            font-weight: 300;
        }
    }

    h2 {
        margin-bottom: 20px;

        &:before {
            font-size: 2rem;
            left: -28px;
            top: -5px;
        }
    }

    h3 {
        margin-bottom: 15px;

        &:before {
            opacity: 0.9;
            font-size: 1.6rem;
            left: -22px;
            top: -5px;
        }
    }
}

.nav-contaienr {
    padding-right: 20px;
    padding-top: 10px;

    .nav-item {
        padding: 10px 20px;
        cursor: pointer;
        font-weight: bold;

        &:hover {
            background: rgba(236, 238, 240, 0.60);
        }
    }
}

.nav-content-container {
    padding-right: 50px;

    .nav-content {
        padding-bottom: 3em;
        margin-bottom: 4em;
        border-bottom: 1px solid $ada_lightGrey;

        &:first-child {
            margin-top: 15px;
        }

        &:last-child {
            border-bottom: 1px solid transparent;
        }
    }
}


.section {
    margin-bottom: 30px;
}
</style>