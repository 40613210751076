<template>
    <div>
        <div class="mb-10">
            <h2 class="text-lg-h4 mb-4">Management</h2>
            <div class="mb-4">Manage your users, service description options, data processing options and TCF v2.0
                purposes here
            </div>

            <v-tabs vertical :color="ada.blue">
                <v-tab v-for="tab in tabs" :key="tab.id">
                    {{ tab.name }}
                </v-tab>

                <v-tab-item v-for="tab in tabs" :key="tab.id+'_content'">
                    <v-card flat>
                        <v-card-text>
                            <component :is="tab.component"></component>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </div>
    </div>
</template>

<script>
import ada from '@/scss/variables.scss'
import Settings_DataTypes from "./Settings_DataTypes";
import Settings_Users from "./Settings_Users";
import Settings_Services from "./Settings_Services";
import Settings_Purposes from "./Settings_Purposes";
import Settings_JCA from "./Settings_JCA";

export default {
    name: "Settings",
    components: {Settings_JCA, Settings_Purposes, Settings_Services, Settings_Users, Settings_DataTypes},
    data: () => ({
        ada,
        tabs: [
            {id: 'users', name: 'Users', component: 'Settings_Users'},
            {id: 'services', name: 'Service Descriptions', component: 'Settings_Services'},
            {id: 'data_types', name: 'Data Processing', component: 'Settings_DataTypes'},
            {id: 'purposes', name: 'TCF v2.2 Purposes', component: 'Settings_Purposes'},
            {id: 'jca', name: 'JCA PDFs', component: 'Settings_JCA'},
        ]
    }),
    mounted() {
        this.$store.dispatch('users/getCurrentUser');
        if (this.$store.state.users.current && this.$store.state.users.current.role !== 'admin') {
            this.$router.push({name: 'unauthorized'})
        }
    }
}
</script>

<style lang="scss" scoped>

::v-deep .v-tabs-bar__content {
    border-right: 1px solid $ada_lightGrey;
}

</style>